import React, { useEffect } from "react";

import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import * as actions from "../../actions/ArticlesActions";

import Loader from "react-loader-spinner";
import Button from "../../components/Button";

import { getUrlToImage } from "../../utils/utils";

import moment from "moment";
import ReactMarkdown from "react-markdown";

const ArticlePage = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles);

  const history = useHistory();
  const { id } = useParams();

  const data = articles?.byId[id];

  useEffect(() => {
    if (id && !data?.id && !data?.loading) {
      dispatch(actions.getArticleById(id));
    }
  }, [data, articles, id, dispatch]);

  if (data?.loading) {
    return (
      <div className="loader-wrapper">
        <Loader type="Oval" color="#f66829" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="simple-page article-subpage">
      <div className="container">
        <div className="row">
          <Button
            icon="arrow_down"
            iconColor="orange-light"
            className="transparent"
            onClick={() => history.goBack()}
          >
            Wróć
          </Button>
          <div className="article-subpage__title">
            <h2 className="title">{data?.title}</h2>
            <div className="article-subpage__title__description">
              {data?.updated_at && (
                <p className="time">{moment(data.updated_at).format("LL")}</p>
              )}
              {data?.type && <p className="type orange-text">{data.type}</p>}
              {data?.author && (
                <p className="author orange-text">{data.author}</p>
              )}
            </div>
          </div>
          {data?.cover?.url && (
            <div className="article-subpage__image">
              <picture>
                <img
                  src={getUrlToImage(data.cover.url)}
                  alt={data?.name || "produkt"}
                />
              </picture>
            </div>
          )}
          <div className="article-subpage__content">
            {data?.content && (
              <article>
                <ReactMarkdown>{data.content}</ReactMarkdown>
              </article>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
