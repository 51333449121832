export const FETCH_PLACES = "FETCH_PLACES";
export const FETCH_PLACES_SUCCESS = "FETCH_PLACES_SUCCESS";
export const FETCH_PLACES_FAILURE = "FETCH_PLACES_FAILURE";

export const FETCH_TYPES = "FETCH_TYPES";
export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS";
export const FETCH_TYPES_FAILURE = "FETCH_TYPES_FAILURE";

export const SEARCH_PLACES = "SEARCH_PLACES";
export const SEARCH_PLACES_SUCCESS = "SEARCH_PLACES_SUCCESS";
export const SEARCH_PLACES_FAILURE = "SEARCH_PLACES_FAILURE";

export const FETCH_PLACE = "FETCH_PLACE";
export const FETCH_PLACE_SUCCESS = "FETCH_PLACE_SUCCESS";
export const FETCH_PLACE_FAILURE = "FETCH_PLACE_FAILURE";

export const UPDATE_PLACE = "UPDATE_PLACE";
export const UPDATE_PLACE_SUCCESS = "UPDATE_PLACE_SUCCESS";
export const UPDATE_PLACE_FAILURE = "UPDATE_PLACE_FAILURE";

export const FETCH_ONE_PLACE_SUCCESS = "FETCH_ONE_PLACE_SUCCESS";
export const FETCH_ONE_PLACE_FAILURE = "FETCH_ONE_PLACE_FAILURE";

export const FETCH_PLACE_EMPLOYEES = "FETCH_PLACE_EMPLOYEES";
export const FETCH_PLACE_EMPLOYEES_SUCCESS = "FETCH_PLACE_EMPLOYEES_SUCCESS";
export const FETCH_PLACE_EMPLOYEES_FAILURE = "FETCH_PLACE_EMPLOYEES_FAILURE";
