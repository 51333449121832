const ACCEPTED_TERMS = "accepted_terms";

const setJWTToken = async (token) => {
  try {
    await localStorage.setItem("token", token);
  } catch (error) {
    console.log(error);
  }
};

const getJWTToken = async () => {
  try {
    const value = await localStorage.getItem("token");
    return value;
  } catch (error) {
    console.log(error);
  }
};

const setUserRole = async (id) => {
  try {
    await localStorage.setItem("role", id);
  } catch (error) {
    console.log(error);
  }
};

const getUserRole = async () => {
  try {
    const value = await localStorage.getItem("role");
    return value;
  } catch (error) {
    console.log(error);
  }
};

const setTermsAccepted = async (accepted) => {
  try {
    await localStorage.setItem(ACCEPTED_TERMS, accepted.toString());
  } catch (error) {
    console.log(error);
  }
};
const isTermsAccepted = async () => {
  try {
    const value = await localStorage.getItem(ACCEPTED_TERMS);
    return !!value;
  } catch (error) {
    console.log(error);
  }
};

export {
  getJWTToken,
  setJWTToken,
  setUserRole,
  getUserRole,
  setTermsAccepted,
  isTermsAccepted,
};
