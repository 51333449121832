export const FETCH_CLINIC_EMPLOYEE = "FETCH_CLINIC_EMPLOYEE";
export const FETCH_CLINIC_EMPLOYEE_SUCCESS = "FETCH_CLINIC_EMPLOYEE_SUCCESS";
export const FETCH_CLINIC_EMPLOYEE_FAILURE = "FETCH_CLINIC_EMPLOYEE_ERROR";

export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAILURE = "ADD_EMPLOYEE_FAILURE";

export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";
export const EDIT_EMPLOYEE_SUCCESS = "EDIT_EMPLOYEE_SUCCESS";
export const EDIT_EMPLOYEE_FAILURE = "EDIT_EMPLOYEE_FAILURE";

export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAILURE = "DELETE_EMPLOYEE_FAILURE";

export const FETCH_PLACE_EMPLOYEES = "FETCH_PLACE_EMPLOYEES";
export const FETCH_PLACE_EMPLOYEES_SUCCESS = "FETCH_PLACE_EMPLOYEES_SUCCESS";
export const FETCH_PLACE_EMPLOYEES_FAILURE = "FETCH_PLACE_EMPLOYEES_FAILURE";
