import React from "react";
import Button from "../Button";
import { func } from "prop-types";
import strings from "../../values/Strings";
import routes from "../../routes";
import { NavLink } from "react-router-dom";

const CookieAlert = ({ setCookieAlert }) => {
  const { privacyPolicy } = routes;

  const acceptCookies = () => {
    localStorage.setItem("cookies", "1");
    setCookieAlert(true);
  };

  return (
    <div className="cookie-alert-wrapper">
      <div className="cookie-alert">
        <div className="cookie-alert__left">
          <p>
            {strings.cookies_text}{" "}
            <NavLink to={privacyPolicy}>
              {strings.cookies_policy_link_text}
            </NavLink>
          </p>
        </div>
        <div className="cookie-alert__right">
          <Button onClick={() => acceptCookies()}>
            <span>{strings.save}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CookieAlert;

CookieAlert.propTypes = {
  setCookieAlert: func,
};
