import React from "react";

import Button from "../Button";
import Modal from "react-modal";

import { func, bool, string } from "prop-types";
import usePreventScroll from "../../hooks/preventScroll";

const ConfirmModal = ({
  hideModal,
  onSubmit,
  loading,
  question,
  error,
  isOpen,
}) => {
  usePreventScroll(isOpen);

  return (
    <Modal
      className="modal modal--auth confirm-modal"
      overlayClassName="modal-overlay"
      isOpen={isOpen}
      closeTimeoutMS={500}
      ariaHideApp={false}
      onRequestClose={hideModal}
    >
      <h4>{question}</h4>
      {error && <span className="modal__error">{error}</span>}
      <div>
        <Button
          loading={loading}
          onClick={() => onSubmit()}
          className="thicker"
        >
          Tak
        </Button>
        <Button onClick={hideModal} className="thicker">
          Nie
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
ConfirmModal.propTypes = {
  hideModal: func,
  onSubmit: func,
  loading: bool,
  question: string,
  error: string,
  isOpen: bool,
};
