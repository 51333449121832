import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import routes from "../../routes";
import DownloadAppPage from "../../pages/DownloadAppPage";
import Footer from "../Footer";
import { isMobile } from "react-device-detect";
import { bool } from "prop-types";

const MainMobile = () => {
  const history = useHistory();
  const { home, downloadApp } = routes;

  useEffect(() => {
    if (isMobile) {
      if (history?.location?.pathname === home) {
        history.push(downloadApp);
      }

      history.listen((location) => {
        if (location?.pathname !== downloadApp) {
          history.push(downloadApp);
        }
      });
    }
  }, [history, downloadApp, home]);

  return (
    <>
      <main className="main">
        <Route exact path={downloadApp} component={DownloadAppPage} />
      </main>
      <Footer cookieAlert isMobile={isMobile} />
    </>
  );
};

export default MainMobile;

MainMobile.propTypes = {
  isMobile: bool,
};
