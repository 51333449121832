import { applyMiddleware, createStore, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "../reducers/Auth";
import articlesReducer from "../reducers/Articles";
import articleReducer from "../reducers/Article";
import placesReducer from "../reducers/Places";
import productsReducer from "../reducers/Products";
import petsReducer from "../reducers/Pets";
import visitsReducer from "../reducers/Visits";
import doctorsReducer from "../reducers/Doctors";
import specializationsReducer from "../reducers/Specializations";
import notificationsReducer from "../reducers/Notifications";
import servicesReducer from "../reducers/Services";
import ratingsReducer from "../reducers/Ratings";
import employeeReducer from "../reducers/Employee";
import workTimesReducer from "../reducers/WorkTimes";
import regulationsReducer from "../reducers/Regulations";
import configReducer from "../reducers/Config";
import partnersReducer from "../reducers/Partners";

const reducers = combineReducers({
  auth: authReducer,
  articles: articlesReducer,
  article: articleReducer,
  places: placesReducer,
  products: productsReducer,
  pets: petsReducer,
  visits: visitsReducer,
  doctors: doctorsReducer,
  specializations: specializationsReducer,
  notifications: notificationsReducer,
  services: servicesReducer,
  ratings: ratingsReducer,
  employee: employeeReducer,
  workTimes: workTimesReducer,
  regulations: regulationsReducer,
  config: configReducer,
  partners: partnersReducer,
});

let middleware = [];
middleware = [...middleware, thunk];

const store = createStore(reducers, compose(applyMiddleware(...middleware)));

export default store;
