import React, {
  useMemo,
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import Button from "../Button";
import Icon from "../Icon";
import BookVisit from "../BookVisit";

import { getUrlToImage, getMapLnk } from "../../utils/utils";

import { LoginModalContext } from "../../contexts/modals";

import queryString from "query-string";

import { shape, func, bool } from "prop-types";

import moment from "moment";
import RequestStatusModal from "../Modals/RequestStatusModal";
import strings from "../../values/Strings";
import { useSelector } from "react-redux";
import { getVisits } from "../../store/Selectors";

const Clinic = ({ data, setVisitReserved, showDetails, setShowDetails }) => {
  const [showForm, setShowForm] = useState(false);
  const [reservationCompleteModal, setReservationCompleteModal] = useState(
    false
  );
  const visits = useSelector(getVisits);

  const history = useHistory();
  const hLocation = useLocation();

  let openHours = data?.openHours;
  openHours = Object.keys(openHours).map((key) => ({
    [key]: openHours[key] ? openHours[key].toString().slice(0, 5) : "-",
  }));

  data.openHours = Object.assign({}, ...openHours);

  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
  } = data.openHours;

  const { showLoginModal } = useContext(LoginModalContext);

  const {
    id,
    name,
    logo,
    address_street,
    address_zip,
    address_city,
    desc,
    spec,
    latitude,
    longitude,
  } = data;

  const clinicRef = useRef(null);

  const qData = useMemo(() => queryString.parse(hLocation?.search), [
    hLocation,
  ]);

  const selectedPlace = useMemo(() => qData.place, [qData]);

  const visitId = useMemo(() => qData.visit, [qData]);

  const active = useMemo(
    () => showDetails && selectedPlace && id === Number(selectedPlace),
    [showDetails, selectedPlace, id]
  );

  const imgSrc = logo?.url && getUrlToImage(logo.url);

  const onExitForm = useCallback(() => {
    setShowForm(false);
    setVisitReserved();
    if (visitId && history?.location?.pathname !== "/panel-uzytkownika") {
      history.push("/panel-uzytkownika");
    }
  }, [visitId, history, setVisitReserved]);

  useEffect(() => {
    if (clinicRef?.current && active) {
      clinicRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [clinicRef, active]);

  useEffect(() => visitId && setShowForm(true), [visitId]);

  const handleHideReservationConfirmModal = () => {
    setVisitReserved();
    history.push("/placowki");
    setReservationCompleteModal(false);
  };

  const handleShowDetails = () => {
    if (!showForm) {
      if (selectedPlace && id === Number(selectedPlace)) {
        setShowDetails(false);
        history.push("/placowki");
      } else {
        setShowDetails(true);
      }
    }
  };

  const handleShowForm = (e) => {
    e.stopPropagation();
    setShowForm(true);
  };

  return (
    <div
      className={`clinic ${active ? "active" : ""}`}
      onClick={() => handleShowDetails()}
    >
      <div
        ref={clinicRef}
        onClick={() => history.push(`?place=${id}`)}
        className="head"
      >
        <div>
          <h3>{name}</h3>
          <h5>
            Specjalizacj{spec?.length > 1 ? "e" : "a"}:{" "}
            {spec.length > 0
              ? spec.map((item, i) => (
                  <span key={i}>
                    {item.name}
                    {i !== spec.length - 1 && ", "}
                  </span>
                ))
              : "Brak danych"}
          </h5>
          <address>
            ul. {address_street || "Brak danych"} <br />
            {address_zip || "Brak kodu pocztowego"}{" "}
            {address_city || "Brak miasta"}
          </address>

          <a
            className="link-with-icon"
            rel="noopener noreferrer"
            href={getMapLnk(latitude, longitude)}
            target="_blank"
          >
            <span>Nawiguj</span> <Icon name="navigate" />
          </a>
        </div>

        {imgSrc && (
          <div className="image-wrapper">
            <img src={imgSrc} alt={logo?.alternativeText || "logo kliniki"} />
          </div>
        )}
      </div>
      <div className={`bottom-part ${active ? " active" : ""}`}>
        <h3 className="small-title">Godziny otwarcia: </h3>

        <table className="open-hours-table">
          <thead>
            <tr>
              {moment.weekdaysShort(true).map((day, index) => (
                <th key={index}>{day}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <span>{mondayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{mondayTo}</span>
                </div>
              </td>

              <td>
                <div>
                  <span>{tuesdayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{tuesdayTo}</span>
                </div>
              </td>

              <td>
                <div>
                  <span>{wednesdayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{wednesdayTo}</span>
                </div>
              </td>

              <td>
                <div>
                  <span>{thursdayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{thursdayTo}</span>
                </div>
              </td>

              <td>
                <div>
                  <span>{fridayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{fridayTo}</span>
                </div>
              </td>

              <td>
                <div>
                  <span>{saturdayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{saturdayTo}</span>
                </div>
              </td>

              <td>
                <div>
                  <span>{sundayFrom}</span>
                  <Icon name="arrow_down" color="orange" />
                  <span>{sundayTo}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        {desc && (
          <div className="description">
            <h3 className="small-title">Opis placówki: </h3>
            {desc}
          </div>
        )}

        <Button onClick={(e) => handleShowForm(e)} className="transparent">
          Szczegóły i rezerwacje
        </Button>
        {active && (
          <BookVisit
            data={data}
            selectedPlaceId={selectedPlace}
            onHideForm={() => onExitForm()}
            isOpen={showForm && !showLoginModal}
            visitModalConfirm={() => setReservationCompleteModal(true)}
            bookVisitModal={() => setShowForm(false)}
          />
        )}
        <RequestStatusModal
          close={() => handleHideReservationConfirmModal()}
          successComponent={<h2>{strings.visit_reserved_success}</h2>}
          failureComponent={<h2>{strings.visit_reserved_failure}</h2>}
          isOpen={reservationCompleteModal}
          isSuccess={!visits.error}
        />
      </div>
    </div>
  );
};

export default Clinic;

Clinic.propTypes = {
  data: shape({}),
  setVisitReserved: func,
  setShowDetails: func,
  showDetails: bool,
};
