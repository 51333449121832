import React, { useState, useMemo, useCallback, useEffect } from "react";
import Modal from "react-modal";
import Icon from "../Icon";
import Button from "../Button";
import HistoryItem from "../HistoryItem";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  addOrUpdateVisitComment,
  cancelVisit,
} from "../../actions/VisitsActions";
import strings from "../../values/Strings";
import PropTypes from "prop-types";
import Countdown from "../Countdown";
import Loader from "react-loader-spinner";
import usePreventScroll from "../../hooks/preventScroll";

const VisitModal = ({
  isOpen,
  hide,
  visits,
  modalData,
  showVisit,
  loading,
}) => {
  const dispatch = useDispatch();
  const endDate = modalData?.endDate;
  const startDate = modalData?.startDate;

  const formatedDate = useMemo(() => {
    return moment(startDate)
      .format(strings.hour__day_name__full_date)
      .split(" ");
  }, [startDate]);

  const endHour = useMemo(() => {
    return moment(endDate).format(strings.hours);
  }, [endDate]);

  const [summary, setSummary] = useState("");
  const [editSummaryMode, setSummaryEditMode] = useState(false);
  const [error, setError] = useState("");
  const petInfo = visits[0]?.pet;

  const handleCancelVisit = useCallback(() => {
    dispatch(cancelVisit(modalData?.id));
    hide();
  }, [modalData, dispatch, hide]);

  const handleAddVisitSummary = useCallback(() => {
    if (summary.length) {
      dispatch(addOrUpdateVisitComment(modalData.id, summary));
      setSummary("");
      setError("");
      setSummaryEditMode(false);
    } else {
      setError(strings.error_field_empty);
    }
  }, [dispatch, modalData, summary]);

  const handleSetEditMode = () => {
    setSummaryEditMode(!editSummaryMode);
  };

  const currentVisitSummary = useMemo(() => {
    return visits?.find((visit) => visit?.id === modalData?.id);
  }, [visits, modalData]);

  useEffect(() => {
    setSummaryEditMode(false);
  }, [isOpen]);

  usePreventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName="modal-overlay"
      className="visit-modal"
      ariaHideApp={false}
    >
      <Button className="round" onClick={hide}>
        <Icon name="close" color="white" />
      </Button>

      <div className="box-1">
        <div className="visit-modal__content">
          <p>Przewidywany czas wizyty</p>
          <h2 className="date">
            {formatedDate[0]} - {endHour} <span>{formatedDate[1]}</span>,{" "}
            {formatedDate[2]}
          </h2>
        </div>

        <div className="visit-modal__content">
          <h4 className="history__title">Opis wizyty wizyt</h4>
          <p className="description">{modalData?.title}</p>
        </div>
        <div className="visit-modal__content">
          <h4 className="history__title">Opinia lekarza weterynarii</h4>
          <div className="visit-modal__textarea-wrapper">
            <textarea
              className={`visit-modal__opinion-textarea ${
                !editSummaryMode && currentVisitSummary?.comment?.length > 0
                  ? "disabled"
                  : ""
              }`}
              placeholder="Napisz opinię"
              onChange={(e) => setSummary(e.target.value)}
              defaultValue={
                currentVisitSummary?.comment ? currentVisitSummary?.comment : ""
              }
              disabled={
                !editSummaryMode && currentVisitSummary?.comment?.length > 0
              }
            />
            {currentVisitSummary?.comment && !editSummaryMode && (
              <Button
                className="transparent visit-modal__edit-button"
                onClick={() => handleSetEditMode()}
              >
                Edytuj
              </Button>
            )}
          </div>
          {!!error && <span className="validation-error">{error}</span>}
          <div className="visit-modal__save-buttons">
            {currentVisitSummary?.comment?.length && editSummaryMode && (
              <Button
                className="transparent"
                onClick={() => handleSetEditMode()}
              >
                Anuluj
              </Button>
            )}
            {(!currentVisitSummary?.comment ||
              currentVisitSummary?.comment?.length === 0 ||
              editSummaryMode) && (
              <Button
                className="save-button"
                onClick={() => handleAddVisitSummary()}
              >
                {editSummaryMode ? "Zapisz" : "Dodaj"}
              </Button>
            )}
          </div>
        </div>
        <h4 className="history__title">Historia wizyt</h4>
        <div className="history">
          {loading ? (
            <div className="loader-wrapper">
              <Loader type="Oval" color="#f66829" height={100} width={100} />
            </div>
          ) : (
            visits
              ?.filter((item) => item?.id !== modalData?.id)
              ?.map((item, index) => (
                <HistoryItem
                  key={index}
                  date={moment(item.startDate).format("LLL")}
                  description={item?.title}
                  summary={item.comment || undefined}
                  visitDetails={item}
                />
              ))
          )}
        </div>
      </div>
      <div className="visit-modal__right-site">
        <div className="box-2">
          <h4>Zwierzak</h4>
          <p className="pet-name">
            Imię: {petInfo?.name || "Nie podano imienia"}
          </p>
          <p className="pet-specie">
            Gatunek: {petInfo?.pet_type?.name || "Nie podano gatunku"}
          </p>
          <p className="pet-specie">
            Rasa: {petInfo?.pet_race?.name || "Nie podano rasy"}
          </p>
          <p className="pet-gender">
            Płeć: {petInfo?.sex === "female" ? "Samica" : "Samiec"}
          </p>
          <p className="pet-age">
            {`Data urodzenia: ${
              moment(petInfo?.dateOfBirth).format("LL") ||
              "Nie podano daty urodzenia"
            }`}
          </p>
          <p className="pet-mikrochip">
            Mikroczip: {petInfo?.mikrochipId || "Nie podano numery mikrochipa"}
          </p>
          <p className="pet-specie">
            {`Waga: ${
              petInfo?.weight
                ? `${petInfo?.weight / 100} kg`
                : "Nie podano gatunku"
            }`}
          </p>
          <p className="pet-neutered">
            Wykastrowany: {petInfo?.neutered ? "Tak" : "Nie"}
          </p>
          <p className="pet-outgoing">
            Wychodzący: {petInfo?.outgoing ? "Tak" : "Nie"}
          </p>

          <br />

          <h4>Właściciel</h4>
          <p className="name">
            {petInfo?.user ? (
              <>
                {petInfo?.user?.firstName} {petInfo?.user?.lastName}
              </>
            ) : (
              "Brak imienia i nazwiska"
            )}
          </p>
          <a
            className="phone"
            href={`tel:${petInfo?.user?.phone ? petInfo?.user?.phone : ""}`}
          >
            Tel.{" "}
            {petInfo?.user?.phone
              ? petInfo?.user?.phone
              : "Brak numeru telefonu"}
          </a>

          <a
            className="mail"
            href={`mailto:${petInfo?.user?.email ? petInfo.user?.email : ""}`}
          >
            {petInfo?.user?.email ? petInfo?.user?.email : "Brak maila"}
          </a>
        </div>

        <div className="box-4">
          {/* todo */}
          {/*<h4>Status wizyty</h4>*/}
          {/*<div className={`status--${true ? "waiting" : "done"}`}>Oczekuje</div>*/}
          <Countdown startDate={startDate} cancelVisit={handleCancelVisit} />
        </div>
      </div>
    </Modal>
  );
};

VisitModal.propTypes = {
  isOpen: PropTypes.bool,
  hide: PropTypes.func.isRequired,
  visits: PropTypes.array,
  modalData: PropTypes.object,
};

export default VisitModal;
