import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/RegulationsActions";
import { getRegulations } from "../../store/Selectors";
import ReactMarkdown from "react-markdown";

const PolicyPrivacyPage = () => {
  const regulations = useSelector(getRegulations);
  const data = regulations.pages.find((page) => page.type === "Privacy");
  const dispatch = useDispatch();

  useEffect(() => {
    if (regulations.pages.length === 0) {
      dispatch(actions.getRegulations());
    }
  }, [dispatch, regulations]);

  return (
    <div className="static-page">
      <div className="container">
        <div className="row static-page__content">
          {data?.content && <ReactMarkdown>{data.content}</ReactMarkdown>}
        </div>
      </div>
    </div>
  );
};

export default PolicyPrivacyPage;
