import React, { useState } from "react";
import { oneOfType, object, array } from "prop-types";
import moment from "moment";
import strings from "../../values/Strings";
import SettingsInput from "../Settings/SettingsInput";
import {
  updateWorkingDayTime,
  deleteWorkingDayTime,
} from "../../actions/WorkTimesActions";
import { useDispatch } from "react-redux";

const WorkingHoursItem = ({ time }) => {
  const dispatch = useDispatch();
  const [defaultTimeData] = useState({
    date: time?.date,
    place: time?.place?.id,
    employee: time?.employee?.id,
    from: moment(time?.from, "HH:mm").format("HH:mm:ss"),
    to: moment(time?.to, "HH:mm").format("HH:mm:ss"),
  });
  const [editMode, setEditMode] = useState(false);
  const [timeData, setTimeData] = useState(defaultTimeData);

  const updateTimeData = (key, value) => {
    setTimeData({ ...timeData, [key]: value + ":00" });
  };

  const handleUpdateWorkingDayTime = () => {
    setEditMode(true);

    if (editMode) {
      setEditMode(false);
      dispatch(updateWorkingDayTime(time.id, timeData, time.employee.id));
    }
  };

  const handleCancelUpdateWorkingDayTime = () => {
    setTimeData(defaultTimeData);
    setEditMode(false);
  };

  const handleDeleteWorkingDayTime = () => {
    dispatch(deleteWorkingDayTime(time.id, time.employee.id));
    setEditMode(false);
  };

  return (
    <div className="wh-item">
      <div className="wh-item__date">{`${moment(time?.date).format(
        "LL"
      )}, ${moment(time?.date).format("dd")}`}</div>
      <div className="wh-item__separator">&bull;</div>
      <div className="wh-item__time">
        <p className="wh-item__time__label">{strings.wh_item_working_time}</p>
        <SettingsInput
          className={`wh-item__input ${editMode ? "color-orange" : ""}`}
          type="input"
          defaultValue={timeData.from}
          isDisabled={!editMode}
          onChange={(e) => {
            updateTimeData("from", e.target.value);
          }}
          inputType="time"
          isRequired
        />
        {"-"}
        <SettingsInput
          className={`wh-item__input ${editMode ? "color-orange" : ""}`}
          type="input"
          defaultValue={timeData.to}
          isDisabled={!editMode}
          onChange={(e) => {
            updateTimeData("to", e.target.value);
          }}
          inputType="time"
          isRequired
        />
      </div>

      <div className="wh-item__edit">
        <p
          className="wh-item__edit__edit-button"
          onClick={() => handleUpdateWorkingDayTime()}
        >
          {editMode ? strings.save : strings.edit}
        </p>
        {editMode && (
          <>
            <p
              className="wh-item__edit__edit-button"
              onClick={() => handleCancelUpdateWorkingDayTime()}
            >
              {strings.cancel}
            </p>
            <p
              className="wh-item__edit__delete-button"
              onClick={() => handleDeleteWorkingDayTime()}
            >
              {strings.remove}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default WorkingHoursItem;

WorkingHoursItem.propTypes = {
  time: oneOfType([object, array]),
};
