import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeSpecializations } from "../../actions/SpecializationsActions";
import EmployeeBox from "./employeeBox";
import Button from "../../components/Button";
import EmployeeModal from "./employeeModal";
import HoursModal from "./employeeHours";
import * as employeeActions from "../../actions/EmployeeActions";
import { getUser, getEmployee, getPlaceEmployee } from "../../store/Selectors";
import strings from "../../values/Strings";
import { getEmployeeByPlaceId } from "../../actions/EmployeeActions";
import Loader from "react-loader-spinner";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";

const Employees = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const employee = useSelector(getEmployee);
  const placeEmployee = useSelector(getPlaceEmployee);

  const [showDoctorModal, setShowDoctorModal] = useState(false);
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [saveDataModal, setSaveDataModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [employeeUpdate, setEmployeeUpdate] = useState(null);

  useEffect(() => {
    if (user?.employee?.id) {
      dispatch(employeeActions.getEmployee(user?.employee?.id));
    } else if (user?.employee) {
      dispatch(employeeActions.getEmployee(user?.employee));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (employee) {
      dispatch(getEmployeeByPlaceId(employee?.places[0]?.id));
    }
  }, [dispatch, employee]);

  const onEditEmployee = (employee, hours) => {
    setSelectedDoctor(employee);
    if (hours) {
      setShowHoursModal(true);
    } else {
      setShowDoctorModal(true);
    }
  };

  const onCloseModal = () => {
    setSelectedDoctor(null);
    setShowDoctorModal(false);
    setShowHoursModal(false);
  };

  useEffect(() => {
    dispatch(getEmployeeSpecializations());
  }, [dispatch]);

  useEffect(() => {
    !placeEmployee?.loading && !placeEmployee?.error && onCloseModal();
  }, [placeEmployee]);

  return (
    <div className="employees-page">
      <div className="container">
        <div className="employees-page__head">
          <h1>{strings.employees_title}</h1>
          <Button onClick={() => setShowDoctorModal(true)} className="thicker">
            {strings.add_employee}
          </Button>
          <EmployeeModal
            loading={placeEmployee.loading}
            data={selectedDoctor}
            hideModal={() => onCloseModal()}
            isOpen={showDoctorModal}
            employee={employee}
            employeeUpdate={() => setEmployeeUpdate(!employeeUpdate)}
            editMode={!!selectedDoctor}
          />
          <HoursModal
            loading={placeEmployee.loading}
            data={selectedDoctor}
            hideModal={() => onCloseModal()}
            isOpen={showHoursModal}
            saveDataModal={() => setSaveDataModal(true)}
          />
        </div>
        <div className="content">
          <div className="row">
            {placeEmployee.loading ? (
              <div className="loader-center">
                <Loader type="Oval" color="#f66829" height={20} width={20} />
              </div>
            ) : (
              placeEmployee?.employees?.length > 0 &&
              placeEmployee.employees.map((employee, index) => (
                <div key={index} className="employees-wrapper col-12">
                  <EmployeeBox
                    data={employee}
                    onEdit={(employee, hours) =>
                      onEditEmployee(employee, hours)
                    }
                    placeName={employee?.places[0]?.name}
                  />
                </div>
              ))
            )}
          </div>
        </div>
        <RequestStatusModal
          close={() => setSaveDataModal(false)}
          successComponent={<h2>{strings.save_data_success}</h2>}
          isOpen={saveDataModal}
        />
      </div>
    </div>
  );
};
export default Employees;
