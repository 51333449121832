import React, { useState, useEffect } from "react";

import { func, bool } from "prop-types";

import { useDispatch, useSelector } from "react-redux";

import { addPet, getPetsTypes, updatePet } from "../../../actions/PetsActions";

import Modal from "react-modal";
import InputComponent from "../../../components/Input";
import Radio from "../../../components/FormComponents/Radio";
import CustomSwitch from "../../../components/FormComponents/Switch";
import FileUploader from "../../../components/FormComponents/FileUpload";
import MyCalendar from "../../../components/Calendar";
import { default as ReactSelect } from "react-select";

import { originOptions, radioOptions } from "../../../values/options";
import { getAvatar } from "../../../utils/utils";

import defaultPetImg from "../../../assets/image/logo_pupile.png";
import SaveButtons from "../../../components/SaveButtons";
import moment from "moment";
import usePreventScroll from "../../../hooks/preventScroll";

const NewPetModal = ({ hideModal, showStatusModal, currentPet, isOpen }) => {
  const dispatch = useDispatch();

  const pets = useSelector((state) => state.pets);
  const petsTypes = pets?.types;

  useEffect(() => dispatch(getPetsTypes()), [dispatch]);

  const newKeys = (arr = []) => {
    return arr.flat(Infinity)?.map(({ id: value, name: label, ...rest }) => ({
      value,
      label,
      ...rest,
    }));
  };

  useEffect(() => {
    currentPet?.id &&
      petsTypes.length > 0 &&
      setFormData({
        ...currentPet,
        weight: currentPet.weight / 100,
        pet_type: currentPet?.pet_type?.id
          ? petsTypes.filter(
              (type) =>
                type.name.toLowerCase() ===
                currentPet?.pet_type?.name?.toLowerCase()
            )[0]
          : null,
        pet_race: currentPet.pet_race?.id
          ? {
              id: currentPet?.pet_race.id,
              name: currentPet?.pet_race.name,
            }
          : null,
      });
  }, [currentPet, petsTypes]);

  const defaultData = {
    name: "",
    mikrochipId: "",
    chipDate: "",
    personality: "",
    pet_type: "",
    pet_race: "",
    origin: "",
    sex: radioOptions[0].value,
    dateOfBirth: new Date(),
    weight: "",
    neutered: true,
    outgoing: true,
  };

  const [formData, setFormData] = useState(defaultData);

  const [error, setError] = useState({});
  const [image, setImage] = useState(
    currentPet?.avatar?.url ? currentPet.avatar.url : defaultPetImg
  );

  const updateFormData = (key, val) => {
    setFormData({ ...formData, [key]: val });

    if (val?.length === 0) {
      setError({ ...error, [key]: true });
    } else {
      setError({ ...error, [key]: false });
    }
  };

  const addPetFn = () => {
    let formDataItem = {};
    Object.keys(formData).forEach(
      (item, i) => (formDataItem[item] = formData[item]?.length === 0)
    );
    setError(formDataItem);

    formData.pet_race =
      (formData.pet_type?.pet_races?.length > 0 &&
        (formData.pet_race?.value || formData.pet_race?.id)) ||
      null;

    formData.pet_type =
      formData.pet_type?.value || formData.pet_type?.id || null;

    formData.weight = Number(formData.weight) * 100 || "";

    if (
      !formDataItem.name &&
      !formDataItem.mikrochipId &&
      !formDataItem.pet_type &&
      !formDataItem.chipDate &&
      !formDataItem.weight
    ) {
      dispatch(
        currentPet?.id
          ? updatePet(currentPet.id, formData, image)
          : addPet(formData, image)
      );
      setFormData(defaultData);
      showStatusModal();
      hideData();
    }
  };

  const handleDecimalChange = (value) => {
    let lastValid = currentPet.weight;
    const validNumber = new RegExp(/^\d*\.?\d*$/);

    if (validNumber.test(value)) {
      lastValid = value;
    } else {
      lastValid = "";
    }

    return lastValid;
  };

  usePreventScroll(isOpen);

  const hideData = () => {
    hideModal();
    setImage("");
    setFormData(defaultData);
    setError({});
  };

  return (
    <Modal
      isOpen={isOpen}
      className="add-new-pet modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={() => hideData()}
      closeTimeoutMS={500}
    >
      <div className="modal-header row justify-content-between align-items-center">
        <h2>
          {currentPet?.id ? "Zaktualizuj dane pupila" : "Dodaj nowego pupila"}
        </h2>
        <div>
          <SaveButtons
            visible
            onCancelClick={() => hideData()}
            onClick={addPetFn}
            saveText={currentPet?.id ? "Zaktualizuj" : "Zapisz dane"}
          />
        </div>
      </div>

      <div className="modal-content">
        <form className="row no-gutters justify-content-between" method="post">
          <div className="left col-11">
            <fieldset>
              <InputComponent
                placeholder="Imię zwierzaka"
                label="Imię zwierzaka"
                name="name"
                value={formData.name}
                onChange={(e) => {
                  updateFormData("name", e.target.value);
                }}
              />
              {error.name && <span className="error">Wpisz imię pupila.</span>}
            </fieldset>

            <fieldset>
              <InputComponent
                placeholder="Numer mikroczip"
                label="Numer mikroczip"
                name="mikrochipId"
                value={formData.mikrochipId}
                onChange={(e) => updateFormData("mikrochipId", e.target.value)}
              />
              {error.mikrochipId && (
                <span className="error">
                  Podaj numer mikroczipu zwierzęcia.
                </span>
              )}
            </fieldset>

            <fieldset>
              <InputComponent
                placeholder="Data czipowania"
                label="Data czipowania"
                name="chipDate"
                type="date"
                minDate="1900-01-01"
                maxDate={moment().format("YYYY-MM-DD")}
                value={formData.chipDate}
                onChange={(e) => updateFormData("chipDate", e.target.value)}
              />
              {error.chipDate && (
                <span className="error">
                  Wybierz datę nadania mikroczipu zwierzęcia.
                </span>
              )}
            </fieldset>

            <fieldset>
              <InputComponent
                placeholder="Usposobienie"
                label="Usposobienie"
                name="personality"
                type="text"
                value={formData.personality}
                onChange={(e) => updateFormData("personality", e.target.value)}
              />
            </fieldset>

            <fieldset>
              <span className="label">Gatunek</span>
              <ReactSelect
                options={newKeys(pets?.types)}
                value={
                  formData?.pet_type &&
                  Object.keys(formData.pet_type).length > 0
                    ? newKeys([formData.pet_type])
                    : null
                }
                onChange={(option) => updateFormData("pet_type", option)}
                className="react-select"
                placeholder="Wybierz gatunek"
                noOptionsMessage={() => "Brak gatunku"}
                isLoading={pets.loading}
                isDisabled={pets.loading}
              />
              {error.pet_type && <span className="error">Podaj gatunek.</span>}
            </fieldset>

            <fieldset>
              <span className="label">Rasa</span>
              <ReactSelect
                options={newKeys(formData.pet_type?.pet_races)}
                value={
                  formData?.pet_race &&
                  Object.keys(formData.pet_race).length > 0
                    ? newKeys([formData.pet_race])
                    : null
                }
                onChange={(option) => updateFormData("pet_race", option)}
                className="react-select"
                placeholder={
                  formData.pet_type?.pet_races?.length > 0 ||
                  formData.pet_race?.id
                    ? "Dodaj rasę"
                    : "Brak rasy"
                }
                noOptionsMessage={() => "Brak rasy"}
                isDisabled={!formData.pet_type?.pet_races?.length > 0}
              />
            </fieldset>

            <fieldset>
              <span className="label">Hodowlany czy domowy</span>
              <ReactSelect
                options={newKeys(originOptions)}
                onChange={(selected) =>
                  updateFormData("origin", selected.value)
                }
                value={
                  formData?.origin
                    ? [
                        {
                          value: formData?.origin,
                          label: formData?.origin,
                        },
                      ]
                    : null
                }
                className="react-select"
                placeholder="Wybierz rodzaj hodowli"
                noOptionsMessage={() => "Brak dostępnych opcji"}
              />
            </fieldset>
            <Radio
              label="Płeć"
              name="sex"
              options={radioOptions}
              onInputChange={(selected) => {
                setFormData({ ...formData, sex: selected });
              }}
              defaultValue={formData.sex}
            />
          </div>

          <div className="right col-11">
            <fieldset>
              <span className="label">Data urodzenia pupila</span>
              <MyCalendar
                name="dateOfBirth"
                maxDate={new Date()}
                onDateSelect={(value) => updateFormData("dateOfBirth", value)}
                selectedDate={
                  formData?.dateOfBirth
                    ? new Date(formData.dateOfBirth)
                    : new Date()
                }
              />
            </fieldset>

            <CustomSwitch
              name="neutered"
              label="Wykastrowany"
              textLeft="Nie"
              textRight="Tak"
              defaultValue={currentPet?.id && currentPet.neutered}
              onInputChange={(value) => updateFormData("neutered", value)}
            />

            <CustomSwitch
              name="outgoing"
              label="Wychodzący"
              textLeft="Nie"
              textRight="Tak"
              defaultValue={currentPet?.id && currentPet.outgoing}
              onInputChange={(value) => updateFormData("outgoing", value)}
            />
            <fieldset>
              <FileUploader
                label="Zdjęcie pupila"
                name="petImage"
                img={currentPet?.avatar?.url && getAvatar(currentPet)}
                onInputChange={(value) => setImage(value)}
                defaultImage={defaultPetImg}
              />
            </fieldset>

            <fieldset>
              <InputComponent
                placeholder="Waga pupila (w kg)"
                label="Waga pupila (w kg)"
                name="weight"
                pattern="[0-9]*"
                value={formData.weight}
                onChange={(e) =>
                  updateFormData("weight", handleDecimalChange(e.target.value))
                }
              />
              {error.weight && (
                <span className="error">Podaj wagę zwierzęcia.</span>
              )}
            </fieldset>
          </div>

          <div className="bottom col-24" />
        </form>
      </div>
    </Modal>
  );
};

export default NewPetModal;

NewPetModal.propTypes = {
  hideModal: func,
  showStatusModal: func,
  isOpen: bool,
};
