import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPets } from "../../../actions/PetsActions";

import Pet from "../../../components/Pet";
import Button from "../../../components/Button";
import NewPetModal from "./newPet";
import UserPanelLayout from "../../../layouts/userPanel";

import RequestStatusModal from "../../../components/Modals/RequestStatusModal";

import strings from "../../../values/Strings";

const PetsList = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const pets = useSelector((state) => state.pets);

  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [pet, setPet] = useState({});

  useEffect(() => {
    user.id && dispatch(getPets(user.id));
  }, [dispatch, user.id]);

  const handleClick = () => {
    setPet({});
    setShowModal(true);
  };

  const handleHideNewPetModal = () => {
    setShowModal(false);
    setPet({});
  };

  return (
    <UserPanelLayout>
      <NewPetModal
        hideModal={() => handleHideNewPetModal()}
        showStatusModal={() => setShowStatusModal(true)}
        currentPet={pet}
        isOpen={showModal}
      />
      <div className="head">
        <h2 className="title">Moi pupile</h2>
        <Button onClick={handleClick}>Dodaj nowego pupila</Button>
      </div>

      {pets?.data.map((item, index) => (
        <Pet
          key={index}
          data={item}
          userId={user.id}
          getPet={(data) => setPet(data)}
          openModal={() => setShowModal(true)}
        />
      ))}
      <RequestStatusModal
        isSuccess={!pets?.error}
        isOpen={showStatusModal}
        close={() => setShowStatusModal(false)}
        successComponent={<h2>{strings.pet_add_success}</h2>}
        failureComponent={<h2>{strings.pet_add_failure}</h2>}
      />
    </UserPanelLayout>
  );
};
export default PetsList;
