import React from "react";

import { strings } from "../../values/Strings";
import { getUrlToImage } from "../../utils/utils";
import defaultImg from "../../assets/image/icon_pupile.png";

import { Link } from "react-router-dom";

import { shape, string } from "prop-types";

const PlaceItem = ({ item, col }) => {
  const imgSrc = item?.cover?.url && getUrlToImage(item.cover.url);
  return (
    <Link
      to={`/placowki?place=${item?.id}`}
      className={`place-item-wrapper col-${col}`}
    >
      <div className="place-item-wrapper__content ">
        <img
          src={imgSrc || defaultImg}
          alt={item?.name || "brak danych"}
          className={imgSrc ? "" : "default-image"}
        />
        <div>
          {item?.type?.name && (
            <p className="place-item-wrapper__content__place-type">
              {item?.type?.name}
            </p>
          )}
          <h4>{item?.name}</h4>
          <p>{item?.address_city}</p>
          <p>
            {strings?.prefix_street} {item?.address_street}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default PlaceItem;
PlaceItem.propTypes = {
  item: shape({}),
  col: string,
};
