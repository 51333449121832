import React from "react";
import Icon from "../Icon";
import { number, string, func, bool } from "prop-types";

const StarRating = ({
  count,
  value,
  inactiveColor = "",
  activeColor = "orange",
  onChange,
  active,
  withoutRating,
}) => {
  return (
    <div className={`rating-component${active ? " active" : ""}`}>
      {Array(count)
        .fill(count)
        .map((_, index) => {
          let style = index <= value - 1 ? activeColor : inactiveColor;
          return (
            <span
              className={"star"}
              key={index}
              onClick={() => onChange && onChange(index + 1)}
            >
              <Icon name="star" color={style} />
            </span>
          );
        })}
      {!withoutRating && (
        <span className="rating">
          {value}/{count}
        </span>
      )}
    </div>
  );
};

StarRating.propTypes = {
  count: number.isRequired,
  value: number.isRequired,
  inactiveColor: string,
  activeColor: string,
  onChange: func,
  active: bool,
  withoutRating: bool,
};

export default StarRating;
