export const ADD_WORKING_TIMES = "ADD_WORKING_TIMES";
export const ADD_WORKING_TIMES_SUCCESS = "ADD_WORKING_TIMES_SUCCESS";
export const ADD_WORKING_TIMES_FAILURE = "ADD_WORKING_TIMES_FAILURE";

export const UPDATE_WORKING_DAY_TIMES = "UPDATE_WORKING_DAY_TIMES";
export const UPDATE_WORKING_DAY_TIMES_SUCCESS =
  "UPDATE_WORKING_DAY_TIMES_SUCCESS";
export const UPDATE_WORKING_DAY_TIMES_FAILURE =
  "UPDATE_WORKING_DAY_TIMES_FAILURE";

export const DELETE_WORKING_DAY_TIMES = "DELETE_WORKING_DAY_TIMES";
export const DELETE_WORKING_DAY_TIMES_SUCCESS =
  "DELETE_WORKING_DAY_TIMES_SUCCESS";
export const DELETE_WORKING_DAY_TIMES_FAILURE =
  "DELETE_WORKING_DAY_TIMES_FAILURE";

export const FETCH_WORKING_TIMES = "FETCH_WORKING_TIMES";
export const FETCH_WORKING_TIMES_SUCCESS = "FETCH_WORKING_TIMES_SUCCESS";
export const FETCH_WORKING_TIMES_FAILURE = "FETCH_WORKING_TIMES_FAILURE";
