export const getAuth = ({ auth }) => auth;
export const getPets = ({ pets }) => pets;
export const getUser = ({ auth }) => auth.user;
export const getVisits = ({ visits }) => visits;
export const getEmployee = ({ employee }) =>
  employee.employeeResponse.employee[0];
export const getEmployeeStatus = ({ employee }) => employee.employeeResponse;
export const getPlaceEmployee = ({ employee }) => employee.placeEmployees;
export const getTimeSlotsVisits = ({ visits }) => visits.timeSlotsVisits;
export const getEmployeeWorkTimes = ({ workTimes }) => workTimes;
export const getRegulations = ({ regulations }) => regulations;
export const getConfig = ({ config }) => config;
export const getPartners = ({ partners }) => partners;
