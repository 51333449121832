import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SettingsInput from "../Settings/SettingsInput";
import Icon from "../Icon";
import { addEmployeeWorkingTimes } from "../../actions/WorkTimesActions";
import strings from "../../values/Strings";
import { getEmployeeWorkingTime } from "../../actions/WorkTimesActions";
import { getEmployeeWorkTimes } from "../../store/Selectors";
import WorkingHoursItem from "../../components/WorkingHoursItem";
import Loader from "react-loader-spinner";
import { object, string, bool, func } from "prop-types";

const EmployeeTimeslotsSettings = ({
  dateTitleText,
  timeTitleText,
  formEdited,
  saveSuccess,
  employee,
  receptionistDataChange,
  setSaveSettings,
}) => {
  const dispatch = useDispatch();
  const employeeWorkTimes = useSelector(getEmployeeWorkTimes);
  const today = moment().format("YYYY-MM-DD");
  const tomorrow = moment().add(1, "day").format("YYYY-MM-DD");
  const [timeData, setTimeData] = useState({
    dateStart: today,
    dateEnd: tomorrow,
    from: null,
    to: null,
  });

  useEffect(() => {
    if (employee?.id) {
      dispatch(getEmployeeWorkingTime(employee?.id));
    }
  }, [dispatch, employee]);

  const generateTimeslots = useCallback(() => {
    const generatedWorkTime = [];
    const dateStart = moment(timeData.dateStart, "YYYY-MM-DD");
    const dateEnd = moment(timeData.dateEnd, "YYYY-MM-DD");
    const daysDifference = dateEnd.diff(dateStart, "days");
    const timeFrom = moment(timeData.from, "HH:mm").format("HH:mm:ss.SSS");
    const timeTo = moment(timeData.to, "HH:mm").format("HH:mm:ss.SSS");

    for (let i = 0; i <= daysDifference; i++) {
      generatedWorkTime.push({
        employee: receptionistDataChange
          ? employee?.user?.employee
          : employee?.id,
        place: employee?.places[0]?.id,
        date: dateStart.format("YYYY-MM-DD"),
        from: timeFrom,
        to: timeTo,
      });
      dateStart.add(1, "days");
    }

    dispatch(addEmployeeWorkingTimes(generatedWorkTime)).then(() => {
      dispatch(getEmployeeWorkingTime(employee?.id));
    });

    return generatedWorkTime;
  }, [dispatch, employee, timeData, receptionistDataChange]);

  const updateTimeData = (key, value) => {
    formEdited();
    setTimeData({ ...timeData, [key]: value });
  };

  useEffect(() => {
    if (saveSuccess && (timeData.from !== null || timeData.to !== null)) {
      generateTimeslots();
      setSaveSettings && setSaveSettings();
    }
  }, [saveSuccess, generateTimeslots, setSaveSettings, timeData]);

  return (
    <div className="doctor-time-generator">
      <div className="working-hours">
        <h3 className="working-hours__title">{strings.working_hours_label}</h3>
        <div className="working-hours__wrapper">
          {employeeWorkTimes.loading ? (
            <div className="working-hours__loader">
              <Loader type="Oval" color="#f66829" height={20} width={20} />
            </div>
          ) : (
            employeeWorkTimes?.data?.length > 0 &&
            employeeWorkTimes.data.map(
              (time, index) =>
                moment(time.date).isSameOrAfter(today, "day") && (
                  <WorkingHoursItem time={time} key={index} />
                )
            )
          )}
        </div>
      </div>
      <div className="info">
        <Icon name="infoCircle" color="info__icon" />
        <span className="info__text">{strings.opening_hours_info}</span>
      </div>
      {dateTitleText && (
        <h4 className="doctor-time-generator__title">{dateTitleText}</h4>
      )}
      <div className="input-content">
        <div className="input-content__item">
          <label>Data od:</label>
          <SettingsInput
            className="input-time"
            type="input"
            onChange={(e) => {
              updateTimeData("dateStart", e.target.value);
            }}
            defaultValue={today}
            inputType="date"
            minDate={moment().format("YYYY-MM-DD")}
            maxDate={moment().add(3, "month").format("YYYY-MM-DD")}
            isRequired
          />
        </div>
        <div className="input-content__item">
          <label> Data do:</label>
          <SettingsInput
            className="input-time"
            type="input"
            onChange={(e) => {
              updateTimeData("dateEnd", e.target.value);
            }}
            inputType="date"
            defaultValue={tomorrow}
            minDate={moment().format("YYYY-MM-DD")}
            maxDate={moment().add(3, "month").format("YYYY-MM-DD")}
            isRequired
          />
        </div>
      </div>
      {timeTitleText && (
        <h4 className="doctor-time-generator__title">{timeTitleText}</h4>
      )}
      <div className="input-content">
        <div className="input-content__item">
          <label>{strings.open_place_label}</label>
          <SettingsInput
            className="input-time"
            type="input"
            onChange={(e) => {
              updateTimeData("from", e.target.value);
            }}
            inputType="time"
            isRequired
          />
        </div>
        <div className="input-content__item">
          <label>{strings.close_place_label}</label>
          <SettingsInput
            className="input-time"
            type="input"
            onChange={(e) => {
              updateTimeData("to", e.target.value);
            }}
            inputType="time"
            isRequired
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeeTimeslotsSettings;

EmployeeTimeslotsSettings.propTypes = {
  dateTitleText: string,
  timeTitleText: string,
  formEdited: func,
  saveSuccess: bool,
  employee: object,
  receptionistDataChange: bool,
  setSaveSettings: func,
};
