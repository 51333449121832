const routes = {
  home: "/",
  places: "/placowki",
  facility: "/placowki/:city?/:type?",
  articles: "/artykuly",
  article: "/article/:id",
  products: "/produkty",
  product: "/produkt/:id",
  profile: "/panel-uzytkownika",
  vet: "/panel-weterynarza",
  providerAuth: "/connect/:providerName/redirect",
  confirmEmail: "/confirm-email",
  resetPassword: "/reset-password",
  privacyPolicy: "/polityka-prywatnosci",
  regulations: "/regulamin",
  contact: "/kontakt",
  downloadApp: "/aplikacja-pupile",
};

export default routes;
