export const FETCH_EMPLOYEE_NOTIFICATIONS = "FETCH_EMPLOYEE_NOTIFICATIONS";
export const FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS =
  "FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS";
export const FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE =
  "FETCH_EMPLOYEE_NOTIFICATIONS_ERROR";

export const UPDATE_EMPLOYEE_NOTIFICATION = "UPDATE_EMPLOYEE_NOTIFICATION";
export const UPDATE_EMPLOYEE_NOTIFICATION_SUCCESS =
  "UPDATE_EMPLOYEE_NOTIFICATION_SUCCESS";
export const UPDATE_EMPLOYEE_NOTIFICATION_FAILURE =
  "UPDATE_EMPLOYEE_NOTIFICATION_ERROR";
