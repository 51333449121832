import API from "./ApiConfig";

export const workTimesApi = {
  addWorkingTimes,
  updateWorkingDayTime,
  deleteWorkingDayTime,
  getWorkingTimes,
};

function addWorkingTimes(data) {
  return API.post("/employee-working-times/bulk", data);
}

function updateWorkingDayTime(id, data) {
  return API.put(`/employee-working-times/${id}`, data);
}

function deleteWorkingDayTime(id) {
  return API.delete(`/employee-working-times/${id}`);
}

function getWorkingTimes(employee) {
  return API.get("/employee-working-times", {
    params: {
      _sort: "date:ASC",
      employee,
    },
  });
}
