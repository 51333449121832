import React, { useEffect, useState } from "react";
import UserPanelLayout from "../../layouts/userPanel";
import Button from "../../components/Button";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import { deleteUser, updateUser } from "../../actions/UserActions";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/Icon";
import CustomSwitch from "../../components/FormComponents/Switch";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";
import SaveButtons from "../../components/SaveButtons";

const Settings = () => {
  const dispatch = useDispatch();
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [formEdited, setFormEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const {
    id,
    emailNotifications,
    pushNotifications,
    displayPhone,
    publicProfile,
    provider,
  } = auth.user;

  const defaultState = {
    enabled: true,
    visits: true,
    prevention: false,
    messages: false,
  };

  const falseState = {
    enabled: false,
    visits: false,
    prevention: false,
    messages: false,
  };

  const [formData, setFormData] = useState({});
  // const [image, setImage] = useState(avatar?.url ? avatar.url : defaultImg);

  useEffect(() => {
    if (auth) {
      setFormData({
        id,
        emailNotifications: emailNotifications
          ? emailNotifications
          : defaultState,
        pushNotifications: pushNotifications ? pushNotifications : defaultState,
        displayPhone,
        publicProfile,
      });
    }
  }, [
    id,
    emailNotifications,
    pushNotifications,
    displayPhone,
    publicProfile,
    auth,
  ]);

  const updateFormData = (key, keyTwo, val) => {
    setFormEdited(true);
    switch (key) {
      case "pushNotifications":
        if (keyTwo === "enabled") {
          setFormData({
            ...formData,
            pushNotifications: val
              ? {
                  ...formData.pushNotifications,
                  enabled: val,
                }
              : falseState,
          });
        } else {
          if (formData.pushNotifications.enabled) {
            setFormData({
              ...formData,
              [key]: {
                ...formData.pushNotifications,
                [keyTwo]: val,
              },
            });
          }
        }
        break;
      case "emailNotifications":
        if (keyTwo === "enabled") {
          setFormData({
            ...formData,
            emailNotifications: val
              ? {
                  ...formData.emailNotifications,
                  enabled: val,
                }
              : falseState,
          });
        } else {
          if (formData.emailNotifications.enabled) {
            setFormData({
              ...formData,
              [key]: {
                ...formData.emailNotifications,
                [keyTwo]: val,
              },
            });
          }
        }
        break;
      default: {
        setFormData({ ...formData, [key]: val });
      }
    }
  };

  const updateUserAccount = () => {
    if (formData.id !== null) {
      setFormEdited(false);
      setShowModal(true);
      dispatch(updateUser(formData.id, formData));
    }
  };

  const removeAccount = () => {
    dispatch(deleteUser(auth.user.id));
    history.push("/");
  };

  return (
    <UserPanelLayout>
      <div className="head">
        <h2 className="title">Ustawienia</h2>
        <SaveButtons
          visible={formEdited}
          onCancelClick={() => setFormEdited(false)}
          onClick={() => updateUserAccount()}
        />
      </div>
      <section className="settings-section">
        {/*<div className="settings-section__content">*/}
        {/*<h3 className="user-panel-page__small-title">Profil</h3>*/}
        {/*TO DO profil użytkownika*/}
        {/*<CustomSwitch*/}
        {/*className="profile-switch"*/}
        {/*name="profile-type"*/}
        {/*label="Typ profilu"*/}
        {/*textLeft="Prywatny"*/}
        {/*textRight="Publiczny"*/}
        {/*defaultValue={publicProfile}*/}
        {/*onInputChange={(value) =>*/}
        {/*updateFormData("publicProfile", "", value)*/}
        {/*}*/}
        {/*/>*/}
        {/*<FileUploader*/}
        {/*label="Zdjęcie profilowe"*/}
        {/*name="petImage"*/}
        {/*img={""}*/}
        {/*onInputChange={(value) => setImage(value)}*/}
        {/*/>*/}
        {/*</div>*/}
        {provider === "local" && (
          <div className="settings-section__content">
            <h3 className="user-panel-page__small-title">
              Wyświetlenie numeru telefonu
            </h3>
            <CustomSwitch
              className="phone-switch"
              name="show-phone-number"
              label="Wyświetl nr. telefonu w wizytówce"
              textLeft="Ukryj"
              textRight="Pokaż"
              defaultValue={displayPhone}
              onInputChange={(value) =>
                updateFormData("displayPhone", "", value)
              }
            />
          </div>
        )}
        <div className="settings-section__content">
          <h3 className="user-panel-page__small-title">Powiadomienia push</h3>
          <CustomSwitch
            name="push-notifications"
            label="Powiadomienia push"
            textLeft="Nie"
            textRight="Tak"
            key={
              !formData?.pushNotifications?.enabled
                ? `push-disabled`
                : `${formData?.pushNotifications?.enabled}-enabled`
            }
            defaultValue={
              !formData?.pushNotifications?.enabled
                ? false
                : formData.pushNotifications?.enabled
            }
            onInputChange={(value) =>
              updateFormData("pushNotifications", "enabled", value)
            }
          />
          <CustomSwitch
            name="push-visits"
            label="Powiadomienia o wizytach"
            textLeft="Nie"
            textRight="Tak"
            disabled={!formData?.pushNotifications?.enabled}
            key={
              !formData?.pushNotifications?.enabled
                ? `visits-disabled`
                : formData.pushNotifications?.visits
            }
            defaultValue={
              !formData?.pushNotifications?.enabled
                ? false
                : formData.pushNotifications?.visits
            }
            onInputChange={(value) =>
              updateFormData("pushNotifications", "visits", value)
            }
          />
          <CustomSwitch
            name="push-prevention"
            label="Powiadomienia o profilaktyce"
            textLeft="Nie"
            textRight="Tak"
            disabled
            lowOpacity
            defaultValue={false}
            onInputChange={(value) =>
              updateFormData("pushNotifications", "prevention", value)
            }
          />
          <CustomSwitch
            name="push-messages"
            label="Powiadomienia o wiadomościach"
            textLeft="Nie"
            textRight="Tak"
            disabled
            lowOpacity
            defaultValue={false}
            onInputChange={(value) =>
              updateFormData("pushNotifications", "messages", value)
            }
          />
        </div>
        <div className="settings-section__content">
          <h3 className="user-panel-page__small-title">Powiadomienia email</h3>
          <CustomSwitch
            name="email-notifications"
            label="Powiadomienia email"
            textLeft="Nie"
            textRight="Tak"
            key={
              !formData?.emailNotifications?.enabled
                ? `push-disabled`
                : `${formData?.emailNotifications?.enabled}-enabled`
            }
            defaultValue={
              !formData?.emailNotifications?.enabled
                ? false
                : formData.emailNotifications?.enabled
            }
            onInputChange={(value) =>
              updateFormData("emailNotifications", "enabled", value)
            }
          />
          <CustomSwitch
            name="email-visits"
            label="Powiadomienia email o wizytach"
            textLeft="Nie"
            textRight="Tak"
            disabled={!formData?.emailNotifications?.enabled}
            key={
              !formData?.emailNotifications?.enabled
                ? `visits-disabled`
                : formData.emailNotifications?.visits
            }
            defaultValue={
              !formData?.emailNotifications?.enabled
                ? false
                : formData.emailNotifications?.visits
            }
            onInputChange={(value) =>
              updateFormData("emailNotifications", "visits", value)
            }
          />
          <CustomSwitch
            name="email-prevention"
            label="Powiadomienia email o profilaktyce"
            textLeft="Nie"
            textRight="Tak"
            disabled
            lowOpacity
            defaultValue={false}
            onInputChange={(value) =>
              updateFormData("emailNotifications", "prevention", value)
            }
          />
          <CustomSwitch
            name="email-messages"
            label="Powiadomienia email o wiadomościach"
            textLeft="Nie"
            textRight="Tak"
            disabled
            lowOpacity
            defaultValue={false}
            onInputChange={(value) =>
              updateFormData("emailNotifications", "messages", value)
            }
          />
        </div>
        <div className="remove-account-wrapper">
          <h3 className="user-panel-page__small-title">Usunięcie konta</h3>
          <div className="user-panel-page__description">
            Uwaga! Usunięcie konta jest nieodwracalne i spowoduje utratę
            wszystkich Twoich danych.
          </div>
          <Button
            className="transparent remove-account"
            onClick={() => setShowDeleteAccountModal(true)}
          >
            <Icon name="close" color="white" />
            Usuń konto
          </Button>
        </div>
        <ConfirmModal
          isOpen={showDeleteAccountModal}
          question="Czy na pewno chcesz usunąć konto?"
          hideModal={() => setShowDeleteAccountModal(false)}
          onSubmit={removeAccount}
        />
      </section>
      <RequestStatusModal
        close={() => setShowModal(false)}
        successComponent={<h2>Dane zapisane poprawnie!</h2>}
        isOpen={showModal}
      />
    </UserPanelLayout>
  );
};
export default Settings;
