import React from "react";
import strings from "../../values/Strings";
import ReactMarkdown from "react-markdown";
import { shape } from "prop-types";

const ContactSection = ({ config }) => {
  return (
    <div className="section section--contact">
      <h1 className="title">Kontakt</h1>
      <div className="section--contact">
        <ReactMarkdown>{config?.data?.contactDescription}</ReactMarkdown>
        {config?.data?.contactEmail && (
          <div className="section--contact__email">
            <span>{`${strings.e_mail_adress_label}: `}</span>
            <a
              className="contact__link"
              href={`mailto:${config?.data?.contactEmail}`}
            >
              {config?.data?.contactEmail}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactSection;

ContactSection.propTypes = {
  config: shape({}),
};
