import React, { useState, useEffect } from "react";

import PropTypes, { oneOfType, arrayOf, node } from "prop-types";

import { NavLink, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import { logout } from "../actions/UserActions";
import { getPets } from "../actions/PetsActions";
import ConfirmModal from "../components/Modals/ConfirmModal";

import Button from "../components/Button";

const Menu = () => {
  const dispatch = useDispatch();
  const [showLogOutModal, setShowLogOutModal] = useState(false);

  const history = useHistory();

  const onLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  return (
    <>
      <aside className="aside">
        <ul className="user-panel">
          <li className="user-panel__link">
            <NavLink exact to="/panel-uzytkownika">
              Wizyty
            </NavLink>
          </li>
          <li className="user-panel__link">
            <NavLink to="/panel-uzytkownika/moi-pupile">Moi pupile</NavLink>
          </li>
          <li className="user-panel__link">
            <NavLink to="/panel-uzytkownika/moje-dane">Moje dane</NavLink>
          </li>
          <li className="user-panel__link">
            <NavLink to="/panel-uzytkownika/ustawienia">Ustawienia</NavLink>
          </li>
          <li className="user-panel__link">
            <Button
              className="transparent"
              onClick={() => setShowLogOutModal(true)}
            >
              Wyloguj się
            </Button>
          </li>
        </ul>
      </aside>
      <ConfirmModal
        question="Czy na pewno chcesz się wylogować?"
        hideModal={() => setShowLogOutModal(false)}
        onSubmit={onLogout}
        isOpen={showLogOutModal}
      />
    </>
  );
};

const UserPanelLayout = ({ children }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    user?.id && dispatch(getPets(user.id));
  }, [dispatch, user]);

  return (
    <div className="user-panel-page">
      <div className="container">
        <h1 className="user-panel-page__title">Panel użytkownika</h1>
        <div className="content">
          <div className="row">
            <div className="col-6">
              <Menu userId={user.id} />
            </div>
            <div className="col-18">
              <div className="user-panel-page__content">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPanelLayout;

UserPanelLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

Menu.propTypes = {
  userId: PropTypes.number.isRequired,
};
