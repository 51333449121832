import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import UserPanelLayout from "../../layouts/userPanel";
import Button from "../../components/Button";
import EditMyDataModal from "../../components/Modals/EditMyDataModal";

const MyData = () => {
  const [showModal, setShowModal] = useState(false);
  const auth = useSelector((state) => state.auth);

  const {
    id,
    email,
    firstName,
    lastName,
    phone,
    username,
    provider,
  } = auth.user;

  const [userData, setUserData] = useState({
    id,
    email,
    firstName,
    lastName,
    phone,
    username,
  });

  useEffect(() => {
    setUserData({
      id,
      email,
      firstName,
      lastName,
      phone,
      username,
    });
  }, [id, email, firstName, lastName, phone, username]);

  return (
    <UserPanelLayout>
      <div className="head">
        <h2 className="title">Moje dane</h2>
        {provider === "local" && (
          <Button onClick={() => setShowModal(true)}>Edytuj dane</Button>
        )}
      </div>
      <section className="my-data-section">
        {provider === "local" && (
          <div className="name">
            <strong>Imię i Nazwisko: </strong>
            {firstName} {lastName}
          </div>
        )}
        <div className="mail">
          <strong>E-mail: </strong>
          {email}
        </div>
        {provider === "local" && (
          <div className="phone">
            <strong>Numer telefonu: </strong>
            {phone ? (
              <a href={`tel:${phone}`}>{phone}</a>
            ) : (
              <>Brak numeru telefonu</>
            )}
          </div>
        )}
      </section>
      <EditMyDataModal
        userData={userData}
        close={() => setShowModal(false)}
        isOpen={showModal}
      />
    </UserPanelLayout>
  );
};
export default MyData;
