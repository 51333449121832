import React, { useState, useCallback, useRef, useEffect } from "react";
import { NavLink, useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/UserActions";
import {
  getEmployeeNotifications,
  updateNotification,
} from "../../actions/NotificationsActions";
import { io } from "socket.io-client";

import Icon from "../Icon";
import Button from "../Button";
import routes from "../../routes";
import logo from "../../assets/image/logo_pupile.png";
import HeaderLayout from "../../layouts/header";
import Notifications from "./Notifications";

import useOutsideClick from "../../hooks/clickOutside";

import { shape, bool, string, number, object, oneOfType } from "prop-types";
import { getEmployee } from "../../store/Selectors";
import strings from "../../values/Strings";
import { getUrlToImage } from "../../utils/utils";

const SchedulerHeader = ({ user, isReceptionist, isDoctor }) => {
  const dispatch = useDispatch();
  const employee = useSelector(getEmployee);

  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreaded, setUnreaded] = useState([]);
  const stateNotifications = useSelector((state) => state.notifications);

  useEffect(() => {
    employee?.id && dispatch(getEmployeeNotifications(employee.id, "id:DESC"));
  }, [dispatch, employee]);

  useEffect(() => {
    stateNotifications && setNotifications(stateNotifications.data);
    setUnreaded(
      stateNotifications.data.filter((notification) => notification.opened)
    );
  }, [stateNotifications]);

  const { vet } = routes;

  const ref = useRef();
  const history = useHistory();

  useOutsideClick(ref, () => {
    if (showMenu) setShowMenu(false);
  });

  const onLogout = () => {
    dispatch(logout());
    history.push("/");
  };

  const socket = io.connect(
    process.env.REACT_APP_API_URL || "https://pupile-api.stage.etd24.pl"
  );
  socket.on("askForUserId", async () => {
    employee?.user?.id && socket.emit("userIdReceived", employee?.user?.id);
  });
  socket.on("notification", (notification) => {
    setNotifications([notification, ...notifications]);
    setUnreaded([
      ...unreaded,
      [notification].filter((notification) => notification.opened),
    ]);
  });

  const handleDisableNotification = (id) => {
    dispatch(updateNotification(employee?.id, id, { opened: false }));
  };

  const handleUserMenuOpen = useCallback(() => {
    setShowNotification(false);
    setShowMenu(!showMenu);
  }, [showMenu]);

  return (
    <HeaderLayout className="scheduler-header">
      <div className="col-8 scheduler-header__content--left">
        <Link className="" to="/">
          <img src={logo} alt="pupile-logo" />
        </Link>
        <h4>
          {`${strings.panel_heading} ${
            isReceptionist
              ? strings.panel_receptionist
              : isDoctor && strings.panel_doctor
          }`}
        </h4>
      </div>
      <nav className="col-8 scheduler-header__content--middle">
        <ul>
          <li>
            <NavLink to={`${vet}/wizyty`}>{strings.panel_visits}</NavLink>
          </li>
          <li>
            <NavLink to={`${vet}/planowanie`}>{strings.panel_plan}</NavLink>
          </li>
          {isReceptionist && (
            <li>
              <NavLink to={`${vet}/employees`}>
                {strings.panel_employess}
              </NavLink>
            </li>
          )}
        </ul>
      </nav>

      <div className="col-8 scheduler-header__content--right">
        <Button
          className="transparent bell"
          icon="bell"
          iconColor={showNotification ? "orange" : "disabled-icons"}
          onClick={() => setShowNotification(!showNotification)}
        >
          {unreaded.length > 0 && (
            <span className="number">{unreaded.length}</span>
          )}
        </Button>

        <Notifications
          notifications={notifications}
          showNotification={showNotification}
          getId={(notificationId) => handleDisableNotification(notificationId)}
          close={() => setShowNotification(false)}
        />

        <ul>
          <li onClick={() => setShowNotification(false)}>
            <NavLink to={`${vet}/ustawienia`} className="settings-link">
              <Icon name="settings" color="disabled-icons" />
            </NavLink>
          </li>
        </ul>

        <div
          ref={ref}
          className={`user ${showMenu ? "active" : ""}`}
          onClick={() => handleUserMenuOpen()}
        >
          {user?.avatar?.url ? (
            <div className="img-wrapper">
              <img
                className="img"
                src={getUrlToImage(user.avatar.url)}
                alt="pupil"
              />
            </div>
          ) : (
            <Icon name="person" />
          )}
          <div>
            <div className="name">
              <div className="name__content">
                {" "}
                {user?.firstName} {user?.lastName}
              </div>
              <Icon name="arrow_down" />
            </div>
          </div>
        </div>

        <div className={`account-menu ${showMenu ? "active" : ""}`}>
          <ul>
            <li>
              <Button className="logout-button" onClick={() => onLogout()}>
                {strings.logout_label}
              </Button>
            </li>
          </ul>
        </div>
      </div>
    </HeaderLayout>
  );
};

export default SchedulerHeader;

SchedulerHeader.propTypes = {
  user: shape({
    blocked: bool,
    confirmed: bool.isRequired,
    created_at: string.isRequired,
    email: string.isRequired,
    id: number.isRequired,
    provider: string,
    role: oneOfType([object, number]),
    updated_at: string.isRequired,
    username: string,
  }).isRequired,
  isReceptionist: bool.isRequired,
  isDoctor: bool.isRequired,
};
