import React from "react";
import { strings } from "../../values/Strings";
import Icon from "../Icon";

const SocialMediaButtons = () => {
  return (
    <div className="social-media-btns">
      <a href={strings.FB_URL} className="button social fb">
        <Icon name="fb" />
        Zaloguj przez <span className="thicker">Facebook</span>
      </a>
      <a href={`${strings.GOOGLE_URL}`} className="button social google">
        <Icon name="google" />
        Zaloguj przez <span className="thicker">Google</span>
      </a>
    </div>
  );
};

export default SocialMediaButtons;
