import React from "react";
import StarRating from "../StarRating";
import PropTypes from "prop-types";
import moment from "moment";

const UserRating = ({ rating }) => {
  return (
    <div className="user-rating">
      <div className="user-rating__author">
        <div className="user">
          <div className="user__name">{rating.author}</div>
          <StarRating count={5} withoutRating value={rating.rating} />
        </div>
        <div className="date">
          {moment(rating.created_at, "YYYY-MM-DD HH:mm Z").format(
            "YYYY-MM-DD HH:mm"
          )}
        </div>
      </div>
      <div className="user-rating__description">{rating.opinion}</div>
    </div>
  );
};

UserRating.propTypes = {
  rating: PropTypes.object.isRequired,
};

export default UserRating;
