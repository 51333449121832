import React from "react";
import ArticlesSection from "../../components/ArticlesSection/ArticlesSection";
import RegisterSection from "../../components/RegisterSection";
import { useSelector } from "react-redux";
import { getAuth } from "../../store/Selectors";
import ContactSection from "../../components/ContactSection/ContactSection";
import { shape } from "prop-types";

const ContactPage = ({ config }) => {
  const auth = useSelector(getAuth);

  return (
    <section className="contact-page">
      <div className="container">
        <ContactSection config={config} />
        <ArticlesSection />
        {!auth?.user?.id && <RegisterSection />}
      </div>
    </section>
  );
};

export default ContactPage;

ContactPage.propTypes = {
  config: shape({}),
};
