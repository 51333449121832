import API from "./ApiConfig";

export const petsApi = {
  getAllUserPets,
  getPetsTypes,
  addPet,
  updatePet,
  deletePet,
  getPetHistory,
};

function getAllUserPets(data) {
  return API.get("/pets", data);
}

function getPetsTypes() {
  return API.get("pet-types");
}

function addPet(pet, avatar) {
  const data = new FormData();
  if (avatar) {
    data.append("files.avatar", avatar);
  }

  data.append("data", JSON.stringify(pet));

  return API.post("pets", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function updatePet(petId, pet, avatar) {
  const data = new FormData();

  if (avatar && avatar !== null) {
    data.append("files.avatar", avatar);
  } else {
    data.append("files.avatar", {});
  }

  data.append("data", JSON.stringify(pet));

  return API.put(`pets/${petId}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

function deletePet(id) {
  return API.delete(`pets/${id}`);
}

function getPetHistory(id) {
  return API.get(`pets/${id}/history`);
}
