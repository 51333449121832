import { servicesApi } from "../api/ServicesApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./ServicesConstatns";

export const fetchServices = () => ({
  type: types.FETCH_SERVICES,
});

export const saveServices = (data) => ({
  type: types.FETCH_SERVICES_SUCCESS,
  payload: data,
});

export const errorServices = (errorMessage) => ({
  type: types.FETCH_SERVICES_FAILURE,
  payload: errorMessage,
});

export const getServices = () => (dispatch) => {
  dispatch(fetchServices());
  servicesApi
    .getServices()
    .then((res) => {
      dispatch(saveServices(res.data));
    })
    .catch((err) => {
      dispatch(errorServices(errorParser.parseError(err).message));
    });
};
