import * as actions from "../actions/WorkTimesConstants";

const initialState = {
  loading: false,
  error: "",
  data: [],
};

const workTimesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_WORKING_TIMES:
    case actions.UPDATE_WORKING_DAY_TIMES:
    case actions.DELETE_WORKING_DAY_TIMES:
    case actions.FETCH_WORKING_TIMES:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case actions.ADD_WORKING_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: [...state.data, action.payload],
      };

    case actions.FETCH_WORKING_TIMES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: action.payload,
      };

    case actions.ADD_WORKING_TIMES_FAILURE:
    case actions.UPDATE_WORKING_DAY_TIMES_FAILURE:
    case actions.DELETE_WORKING_DAY_TIMES_FAILURE:
    case actions.FETCH_WORKING_TIMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default workTimesReducer;
