import React, { useEffect, useState } from "react";
import Button from "../Button";

const calculateTimeLeft = (startDate) => {
  const difference = +new Date(startDate) - +new Date();
  const dayDiff = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hoursDiff = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const minDiff = Math.floor((difference / 1000 / 60) % 60);
  let timeLeft = {};
  if (difference > 0) {
    timeLeft = {
      [dayDiff > 1 ? "dni" : "dzień"]: dayDiff,
      godz: hoursDiff,
      min: minDiff,
    };
  }
  return timeLeft;
};

const Countdown = ({ startDate, cancelVisit }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(startDate));

  useEffect(() => {
    setTimeLeft(calculateTimeLeft(startDate));
  }, [startDate]);

  useEffect(() => {
    const timer = setTimeout(
      () => setTimeLeft(calculateTimeLeft(startDate)),
      1000
    );
    return () => clearTimeout(timer);
  });

  let timerComponents = Object.keys(timeLeft)
    .filter((interval) => timeLeft[interval])
    .map((interval) => (
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    ));

  return (
    <div className="time-left">
      <strong className="time-left__counter">
        <span>Do wizyty pozostało: </span>
        <br />
        <span>
          {timerComponents.length
            ? timerComponents
            : "Czas rozpoczęcia wizyty minął."}
        </span>
      </strong>
      {timerComponents.length > 0 ? (
        <div className="time-left__cancel-visit">
          <Button className="cancel" onClick={cancelVisit}>
            Anuluj wizytę
          </Button>
        </div>
      ) : null}
    </div>
  );
};

export default Countdown;
