import React from "react";
import {
  string,
  number,
  oneOfType,
  func,
  array,
  bool,
  object,
} from "prop-types";
import InputComponent from "../Input";
import FileUploader from "../FormComponents/FileUpload";
import Select from "react-select";
import Icon from "../Icon";

const SettingsInput = ({
  className,
  type,
  placeholder,
  label,
  name,
  value,
  options,
  isMulti,
  onChange,
  img,
  isDisabled,
  isLoading,
  selectNoOptionsMessage,
  inputType,
  maxLength,
  defaultValue,
  minDate,
  maxDate,
  isRequired,
  resetLabel,
  onResetClick,
}) => {
  return (
    <div className={className ? className : ""}>
      {type === "input" && (
        <fieldset className="input-component-wrapper">
          <InputComponent
            placeholder={placeholder}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            disabled={isDisabled}
            type={inputType}
            maxLength={maxLength}
            defaultValue={defaultValue}
            minDate={minDate}
            maxDate={maxDate}
            required={isRequired}
          />
          {resetLabel && (
            <div
              className="input-component-wrapper__close-icon"
              onClick={() => onResetClick()}
            >
              <Icon name="close" />
            </div>
          )}
        </fieldset>
      )}
      {type === "file" && (
        <FileUploader
          label={label}
          name={name}
          onInputChange={onChange}
          img={img}
        />
      )}
      {type === "select" && (
        <Select
          value={value}
          isMulti={isMulti}
          onChange={onChange}
          options={options}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isLoading={isLoading}
          noOptionsMessage={selectNoOptionsMessage}
        />
      )}
    </div>
  );
};

export default SettingsInput;

SettingsInput.propTypes = {
  className: string,
  type: string,
  placeholder: oneOfType([number, string]),
  label: string,
  options: array,
  name: string,
  value: oneOfType([number, string, array]),
  onChange: func.isRequired,
  selectNoOptionsMessage: func,
  img: string,
  isDisabled: bool,
  isLoading: bool,
  isMulti: bool,
  isRequired: bool,
  inputType: string,
  maxLength: number,
  minDate: oneOfType([string, object]),
  maxDate: oneOfType([string, object]),
};
