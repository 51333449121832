import * as actions from "../actions/UserConstants";
import { setJWTToken } from "../store/SecureStorage";

const initialState = {
  token: "",
  loading: false,
  loggedIn: false,
  registered: false,
  errorMessage: "",
  user: {},
  showSendMailModal: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGOUT: {
      setJWTToken("");
      return {
        ...initialState,
      };
    }
    case actions.CLEAR_ERROR_MESSAGE: {
      return {
        ...state,
        errorMessage: "",
      };
    }
    case actions.LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case actions.LOGGED_IN:
      return {
        ...state,
        token: action.payload.jwt,
        loading: false,
        loggedIn: true,
        errorMessage: "",
      };
    case actions.REGISTER:
      return {
        ...state,
        loading: false,
        registered: true,
        errorMessage: "",
      };

    case actions.ERROR_RESET_PASSWORD:
    case actions.ERROR_REGISTER:
    case actions.ERROR_LOG_IN:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case actions.GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: "",
      };
    case actions.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        errorMessage: "",
      };
    case actions.UPDATE_EMIAL_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        errorMessage: "",
      };

    case actions.EMAIL_SENT_SUCCESS:
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        showSendMailModal: action.payload,
        errorMessage: "",
      };

    case actions.EMAIL_SENT_FAILURE:
      return {
        ...state,
        loading: false,
        showSendMailModal: action.payload,
      };

    case actions.UPDATE_AUTH_STATE:
      return {
        ...state,
        token: action.payload,
        loading: false,
        loggedIn: true,
        errorMessage: "",
      };
    case actions.UPDATE_USER_AVATAR_SUCCESS:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          avatar: action.payload,
        },
        errorMessage: "",
      };
    default:
      return state;
  }
};

export default authReducer;
