import React from "react";
import { string, func, array } from "prop-types";
import SettingsInput from "../Settings/SettingsInput";
import { useSelector } from "react-redux";
import { getEmployeeStatus } from "../../store/Selectors";

const SelectWithOptions = ({
  title,
  value,
  onChange,
  options,
  noOptionText,
}) => {
  const employeeStatus = useSelector(getEmployeeStatus);

  return (
    <div className="settings-doctor__input">
      <fieldset>
        <span className="label">{title}</span>
        <SettingsInput
          type="select"
          placeholder="Wybierz"
          isMulti
          value={value}
          onChange={onChange}
          options={options}
          selectNoOptionsMessage={() => noOptionText}
          isLoading={employeeStatus.loading}
          isDisabled={employeeStatus.loading}
        />
      </fieldset>
    </div>
  );
};

export default SelectWithOptions;
SelectWithOptions.propTypes = {
  title: string,
  value: array,
  onChange: func,
  options: array,
  noOptionText: string,
};
