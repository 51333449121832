import * as actions from "../actions/RegulationsConstants";
import { setTermsAccepted } from "../store/SecureStorage";

const initialState = {
  pages: [],
  loading: false,
  error: "",
};

const regulationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_REGULATIONS_PAGES:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_REGULATIONS_PAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        pages: action.payload,
      };
    case actions.FETCH_REGULATIONS_PAGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case actions.TERMS_ACCEPTED:
      setTermsAccepted(true);
      return state;
    default:
      return state;
  }
};

export default regulationsReducer;
