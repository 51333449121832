import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  Appointments,
  AppointmentForm,
  WeekView,
  EditRecurrenceMenu,
  AllDayPanel,
  DragDropProvider,
  DateNavigator,
  MonthView,
  TodayButton,
  ViewSwitcher,
  Toolbar,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { useDispatch, useSelector } from "react-redux";
import {
  reserveVisit,
  cancelVisit,
  updateReservedVisit,
} from "../../actions/VisitsActions";
import { getPets } from "../../actions/PetsActions";
import {
  getReceptionistVisits,
  getVisits,
  getDoctorVisits,
} from "../../actions/VisitsActions";
import Select from "../../components/FormComponents/Select";
import Autocomplete from "../../components/Autocomplete";
import { getAuth, getEmployee, getPlaceEmployee } from "../../store/Selectors";
import strings from "../../values/Strings";
import moment from "moment";
import { getEmployeeByPlaceId } from "../../actions/EmployeeActions";
import * as employeeActions from "../../actions/EmployeeActions";
import { IntegratedAppointments } from "../../components/IntegratedAppoitments";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";

const VetPanelPlanned = ({ isReceptionist }) => {
  const dispatch = useDispatch();

  const auth = useSelector(getAuth);
  const employee = useSelector(getEmployee);
  const placeEmployee = useSelector(getPlaceEmployee);
  const pets = useSelector((state) => state.pets);
  const visits = useSelector((state) => state.visits);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [pet, setPet] = useState(pets?.data[0]?.id);
  const [doctor, setDoctor] = useState(employee?.id);
  const [appointment, setAppointment] = useState(null);
  const [addVisitCompleteModal, setAddVisitCompleteModal] = useState(false);
  const [completeModalMessage, setCompleteModalMessage] = useState("");

  const places = useMemo(() => {
    return (
      employee?.places &&
      employee.places.map(
        (employee) => (employee.place && employee.place) || []
      )
    );
  }, [employee]);

  useEffect(() => {
    if (auth?.user?.employee?.id) {
      dispatch(employeeActions.getEmployee(auth?.user?.employee?.id));
    } else if (auth?.user?.employee) {
      dispatch(employeeActions.getEmployee(auth?.user?.employee));
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (employee !== undefined && employee?.places[0]?.id) {
      dispatch(getEmployeeByPlaceId(employee?.places[0]?.id));
    }
  }, [dispatch, employee]);

  useEffect(() => {
    pets?.data.length > 0 && setPet(pets?.data[0].id);
  }, [pets, placeEmployee]);

  useEffect(() => {
    places?.length > 0 && dispatch(getPets(places));
  }, [places, dispatch]);

  useEffect(() => {
    if (employee?.id && employee?.places) {
      if (employee?.places[0]?.id && isReceptionist) {
        dispatch(getReceptionistVisits(employee?.places[0]?.id));
      } else {
        dispatch(getDoctorVisits(employee.id));
        dispatch(getVisits());
      }
    }
  }, [dispatch, isReceptionist, employee]);

  const commitChanges = useCallback(
    (data, appointment) => {
      const { added, changed, deleted } = data;

      if (added) {
        const data = {
          start: added.startDate,
          end: added.endDate,
          pet: Number(pet),
          employee: Number(doctor),
          desc: added.title,
          comment: added.notes,
          place: employee?.places[0].id,
        };
        setCompleteModalMessage("added");
        dispatch(reserveVisit(data)).then(() => {
          setAddVisitCompleteModal(true);
        });
      }

      if (changed) {
        const key = Object.keys(changed)[0];

        const data = {
          start: changed[key]?.startDate || appointment.startDate,
          end: changed[key].endDate || appointment.endDate,
          pet: Number(pet) || appointment.pet.id,
          employee: isReceptionist ? appointment?.employee.id : Number(doctor),
          desc: changed[key].title || appointment?.desc,
          comment: changed[key].notes || appointment?.comment,
          place: employee?.places[0].id,
        };
        setCompleteModalMessage("changed");
        dispatch(updateReservedVisit(key, data)).then(() => {
          setAddVisitCompleteModal(true);
        });
      }

      if (deleted !== undefined) {
        setCompleteModalMessage("deleted");
        dispatch(cancelVisit(deleted)).then(() => {
          setAddVisitCompleteModal(true);
        });
      }
    },
    [dispatch, pet, doctor, employee, isReceptionist]
  );

  const UpdateTimeScaleLabel = ({ ...rest }) => {
    return <WeekView.TimeScaleLabel className="timeScaleLabel" {...rest} />;
  };

  const TableCell = ({ data, ...rest }) => {
    return (
      <Appointments.Appointment
        className="appointment"
        draggable={true}
        style={{ background: data?.employee?.color }}
        {...rest}
      />
    );
  };

  const DateTimeEdit = ({ ...rest }) => {
    return (
      <AppointmentForm.DateEditor
        {...rest}
        format={strings.full_date_dots__hours}
        ampm={false}
      />
    );
  };

  const SwitcherComponent = ({ ...rest }) => {
    return (
      <ViewSwitcher.Switcher
        {...rest}
        availableViews={[
          { name: "Week", displayName: strings.week },
          { name: "Month", displayName: strings.month },
        ]}
      />
    );
  };

  const LayoutComponent = ({ ...rest }) => {
    return (
      <AppointmentForm.Layout className="appointmentForm-layout" {...rest}>
        <div className="custom-fields">
          <fieldset>
            <Select
              disabled={!placeEmployee?.employees?.length > 0}
              name="doctor"
              label={strings.employee}
              defaultValue={doctor}
              options={placeEmployee?.employees
                ?.filter((doctor) =>
                  isReceptionist
                    ? doctor.id !== null
                    : doctor.id === employee.id
                )
                .map((doctor) => {
                  return {
                    ...doctor,
                    name: [
                      doctor?.title,
                      doctor?.user?.firstName,
                      doctor?.user?.lastName,
                    ].join(" "),
                    value: doctor.id,
                  };
                })}
              onInputChange={(selected) => setDoctor(selected)}
            />
          </fieldset>
          <fieldset>
            <Select
              disabled={!pets?.data?.length > 0}
              name="pet"
              label={strings.pet_two}
              defaultValue={pet}
              options={pets?.data?.map((pet) => {
                return {
                  ...pet,
                  value: pet.id,
                  name: `${pet?.name} ${
                    pet?.user?.firstName && pet?.user?.lastName
                      ? `(wł. ${pet?.user?.firstName} ${pet?.user?.lastName})`
                      : ""
                  }`,
                };
              })}
              onInputChange={(selected) => setPet(selected)}
            />
          </fieldset>
        </div>
      </AppointmentForm.Layout>
    );
  };

  const DraftAppointmentComponent = ({ ...rest }) => {
    useEffect(() => {
      appointment?.changed && setAppointment(rest.data);
    }, [rest.data]);

    return <DragDropProvider.DraftAppointment {...rest} />;
  };

  const ResizeDragAndDrop = ({ ...rest }) => {
    return <DragDropProvider.Resize className="ResizeDragAndDrop" {...rest} />;
  };

  const DayScaleCell = ({ ...rest }) => {
    const { startDate } = rest;
    const today = moment().isoWeekday();
    const calendarDays = moment(startDate).isoWeekday();

    return (
      <WeekView.DayScaleCell
        {...rest}
        className={`${today === calendarDays ? "today" : ""}
                             ${
                               calendarDays === 6 || calendarDays === 7
                                 ? "weekend"
                                 : ""
                             }`}
      />
    );
  };

  const dataArray = visits?.data?.map((data) => {
    return {
      ...data,
      startDate: data.start,
      endDate: data.end,
      title: data.desc,
      notes: data.comment,
    };
  });

  const appointmentMessages = {
    commitCommand: strings.save,
    detailsLabel: strings.appointment_title,
    titleLabel: strings.appointment_title,
    allDayLabel: strings.all_day,
    repeatLabel: strings.repeat_label,
    moreInformationLabel: strings.more_info_label,
    notesLabel: "",
    daily: strings.daily,
    weekly: strings.weekly,
    monthly: strings.monthly,
    yearly: strings.yearly,
    repeatEveryLabel: strings.repeat_every_label,
    daysLabel: strings.days_label,
    endRepeatLabel: strings.end_repeat_label,
    never: strings.never,
    onLabel: strings.appointment_on_label,
    occurrencesLabel: strings.appointment_occurrences_label,
    afterLabel: strings.appointment_after_label,
  };

  const handleHideAddVisitCompleteModal = () => {
    setAddVisitCompleteModal(false);
    setCompleteModalMessage("");
    setTimeout(() => {
      if (isReceptionist) {
        dispatch(getReceptionistVisits(employee?.places[0]?.id));
      } else {
        dispatch(getVisits());
      }
    }, 100);
  };

  const requestStatusModalText = (status, failure = false) => {
    switch (status) {
      case "added":
        if (failure) {
          return strings.visit_added_failure;
        } else {
          return strings.visit_added_success;
        }
      case "changed":
        if (failure) {
          return strings.visit_changed_failure;
        } else {
          return strings.visit_changed_success;
        }
      case "deleted":
        if (failure) {
          return strings.visit_deleted_failure;
        } else {
          return strings.visit_deleted_success;
        }
      default:
        return "";
    }
  };

  return (
    <div className="vet-page-planned">
      <div className="container">
        <div className="row justify-content-between align-items-end">
          <div>
            <h1 className="title">{strings.plan_heading}</h1>
            <p>{strings.plan_description}</p>
          </div>

          {isReceptionist && (
            <Autocomplete
              suggestions={
                placeEmployee?.employees &&
                placeEmployee?.employees?.filter(
                  (employee) => employee.user !== null
                )
              }
              getDoctorId={(id) => dispatch(getDoctorVisits(id))}
              reset={() => {
                if (employee?.places[0]?.id && isReceptionist) {
                  dispatch(getReceptionistVisits(employee?.places[0]?.id));
                }
              }}
              label={strings.search_vet_visits}
            />
          )}
        </div>

        <div className="content">
          <Scheduler locale="pl-PL" data={dataArray} height={800}>
            <ViewState
              currentDate={currentDate}
              defaultCurrentViewName="Week"
              onCurrentDateChange={(date) => setCurrentDate(date)}
            />
            <Toolbar />

            <WeekView
              startDayHour={6}
              cellDuration={15}
              timeScaleLabelComponent={UpdateTimeScaleLabel}
              dayScaleCellComponent={DayScaleCell}
            />
            <MonthView />

            <IntegratedAppointments />

            <EditingState
              onEditingAppointmentChange={(data) => setAppointment(data)}
              onCommitChanges={(data) => {
                setAppointment(data);
                commitChanges(data, appointment);
              }}
            />

            <EditRecurrenceMenu />
            <DateNavigator />
            <TodayButton messages={{ today: strings.today_two }} />

            <Appointments appointmentComponent={TableCell} />
            <AllDayPanel messages={{ allDay: strings.all_day }} />
            <AppointmentForm
              messages={appointmentMessages}
              dateEditorComponent={DateTimeEdit}
              layoutComponent={LayoutComponent}
              onAppointmentDataChange={(data) => setAppointment(data)}
            />
            <ViewSwitcher switcherComponent={SwitcherComponent} />

            <DragDropProvider
              resizeComponent={ResizeDragAndDrop}
              draftAppointmentComponent={DraftAppointmentComponent}
              allowResize={() => true}
            />

            <CurrentTimeIndicator
              shadePreviousCells={true}
              shadePreviousAppointments={true}
              updateInterval={1000}
            />
          </Scheduler>
        </div>
        <RequestStatusModal
          close={() => handleHideAddVisitCompleteModal()}
          successComponent={
            <h2>{requestStatusModalText(completeModalMessage)}</h2>
          }
          failureComponent={
            <h2>{requestStatusModalText(completeModalMessage, true)}</h2>
          }
          isOpen={addVisitCompleteModal}
          isSuccess={!visits.error}
        />
      </div>
    </div>
  );
};

export default VetPanelPlanned;
