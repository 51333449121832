import * as type from "./DoctorsConstants";
import { doctorsApi } from "../api/DoctorsApi";
import { errorParser } from "../api/ErrorParser";

export const fetchDoctors = () => ({
  type: type.FETCH_DOCTORS,
});

export const fetchDoctorsSuccess = (data) => ({
  type: type.FETCH_DOCTORS_SUCCESS,
  payload: data,
});

export const fetchDoctorsFailure = (data) => ({
  type: type.FETCH_DOCTORS_FAILURE,
  payload: data,
});

export const getDoctors = (place, start, end, docId) => (dispatch) => {
  dispatch(fetchDoctors());
  doctorsApi
    .getDoctors(place, start, end, docId)
    .then((res) => {
      dispatch(fetchDoctorsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(fetchDoctorsFailure(errorParser.parseError(err).message));
    });
};

export const createDoctor = () => ({
  type: type.ADD_DOCTOR,
});

export const createDoctorSuccess = (data) => ({
  type: type.ADD_DOCTOR_SUCCESS,
  payload: data,
});

export const createDoctorFailure = (data) => ({
  type: type.ADD_DOCTOR_FAILURE,
  payload: data,
});

export const addDoctor = (data) => (dispatch) => {
  dispatch(createDoctor());
  doctorsApi
    .addDoctor(data)
    .then((res) => {
      dispatch(createDoctorSuccess(res.data));
    })
    .catch((err) => {
      dispatch(createDoctorFailure(errorParser.parseError(err).message));
    });
};

export const updateDoctor = () => ({
  type: type.EDIT_DOCTOR,
});

export const updateDoctorSuccess = (data) => ({
  type: type.EDIT_DOCTOR_SUCCESS,
  payload: data,
});

export const updateDoctorFailure = (data) => ({
  type: type.EDIT_DOCTOR_FAILURE,
  payload: data,
});

export const editDoctor = (id, data) => (dispatch) => {
  dispatch(updateDoctor());
  doctorsApi
    .editDoctor(id, data)
    .then((res) => {
      dispatch(updateDoctorSuccess(res.data));
    })
    .catch((err) => {
      dispatch(updateDoctorFailure(errorParser.parseError(err).message));
    });
};

export const deleteDoctor = () => ({
  type: type.DELETE_DOCTOR,
});

export const deleteDoctorSuccess = (data) => ({
  type: type.DELETE_DOCTOR_SUCCESS,
  payload: data,
});

export const deleteDoctorFailure = (data) => ({
  type: type.DELETE_DOCTOR_FAILURE,
  payload: data,
});

export const removeDoctor = (id) => (dispatch) => {
  dispatch(deleteDoctor());
  doctorsApi
    .deleteDoctor(id)
    .then((res) => {
      dispatch(deleteDoctorSuccess(res.data));
    })
    .catch((err) => {
      dispatch(deleteDoctorFailure(errorParser.parseError(err).message));
    });
};
