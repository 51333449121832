import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { LoginModalContext } from "../../contexts/modals";

import { getAvatar, getUrlToImage } from "../../utils/utils";
import { getPets } from "../../actions/PetsActions";
import {
  reserveVisit,
  postponeVisit,
  getVisit,
} from "../../actions/VisitsActions";
import MyCalendar from "../Calendar";
import Button from "../Button";
import RadioInput from "../FormComponents/Radio";
import Loader from "react-loader-spinner";

import queryString from "query-string";
import { strings } from "../../values/Strings";

import moment from "moment";
import { getPlaceEmployee, getTimeSlotsVisits } from "../../store/Selectors";
import { getEmployeeByPlaceId } from "../../actions/EmployeeActions";
import * as visitsActions from "../../actions/VisitsActions";

const BookVisitForm = ({ visitModalConfirm, bookVisitModal }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const qData = useMemo(() => queryString.parse(location?.search), [location]);
  const { visit: visitId, place: placeId } = qData;
  const placeEmployee = useSelector(getPlaceEmployee);

  const [state, setState] = useState({
    date: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
    employee: "",
    pet: "",
    desc: "",
    place: parseInt(placeId),
    service: 1,
  });

  const visits = useSelector((state) => state.visits);
  const visit = visits?.byId[visitId];
  const petsList = useSelector((state) => state.pets?.data);
  const user = useSelector((state) => state.auth.user);
  const selectedDate = new Date(state.date);
  const timeslots = useSelector(getTimeSlotsVisits);
  const { setShowLoginModal } = useContext(LoginModalContext);
  const [error, setError] = useState("");
  const isLogged = useMemo(() => !!user?.id, [user]);

  const loading = visit?.loading || visits?.loading;

  useEffect(() => {
    placeId && dispatch(getEmployeeByPlaceId(placeId));
  }, [dispatch, placeId]);

  const handleReservation = useCallback(() => {
    const fullDate = `${moment(state.date).format(strings.full_date_2)} ${
      state.time
    }`;

    const start = moment(fullDate).toISOString();
    const end = moment(fullDate).add(15, "minutes").toISOString();

    const data = {
      ...state,
      start,
      end,
    };

    if (data.start === null || data.end === null) {
      setError("Godzina");
      return;
    }

    if (data.employee === "") {
      setError("Nie wybrano lekarza");
      return;
    }

    if (data.pet === "") {
      setError("Nie wybrano zwierzęcia");
      return;
    }

    if (visit?.data?.id) {
      dispatch(postponeVisit(visit.data.id, data));
      visitModalConfirm();
      bookVisitModal();
    } else {
      dispatch(reserveVisit(data));
      visitModalConfirm();
      bookVisitModal();
    }
  }, [state, visit, dispatch, visitModalConfirm, bookVisitModal]);

  const allTimeSlots = useMemo(() => {
    return timeslots?.visits?.map((t) => {
      const val = moment(t).format("HH:mm");
      return {
        name: val,
        value: val,
      };
    });
  }, [timeslots]);

  useEffect(() => visitId && dispatch(getVisit(visitId)), [visitId, dispatch]);

  useEffect(() => {
    if (placeEmployee?.employees?.length > 0 && !visit?.data?.employee) {
      setState((prevState) => ({
        ...prevState,
        employee: placeEmployee?.employees[0].id,
      }));
    }
  }, [placeEmployee, visit]);

  useEffect(() => {
    if (petsList?.length > 0 && !visit?.data?.pet) {
      setState((prevState) => ({
        ...prevState,
        pet: petsList[0].id,
      }));
    }
  }, [petsList, visit]);

  useEffect(() => isLogged && dispatch(getPets(user.id)), [
    isLogged,
    dispatch,
    user,
  ]);

  useEffect(() => {
    if (placeId && state.employee && state.date) {
      dispatch(
        visitsActions.getVisitsTimeSlots(state.employee, placeId, state.date)
      );
    }
  }, [dispatch, state.employee, state.date, placeId, qData]);

  useEffect(() => {
    if (allTimeSlots?.length > 0) {
      setState((prevState) => ({
        ...prevState,
        time: allTimeSlots[0].value,
      }));
    }
  }, [allTimeSlots, placeEmployee]);

  useEffect(() => {
    if (visit?.data?.id) {
      setState({
        ...visit.data,
        date: moment().format("YYYY-MM-DDTHH:mm:ssZ"),
        employee: visit?.data?.employee?.id,
        place: visit.data.place.id,
        pet: visit.data.pet.id,
      });
    }
  }, [visit]);

  const handleSelectCalendarDay = (day) => {
    const today = moment().toISOString();
    const nextDay = moment(day).toISOString();
    const isNextDay = moment(nextDay).isAfter(today, "day");

    setState({
      ...state,
      date: isNextDay ? nextDay : today,
    });
  };

  return (
    <>
      <h2>
        {visit?.data?.id
          ? `Przełóż wizytę (Aktualna data wizyty: ${moment(
              visit.data.start
            ).format("LLL")})`
          : "Rezerwuj wizytę"}
      </h2>
      <section className="book-visit__right__section--calendar">
        <MyCalendar
          selectedDate={selectedDate}
          onDateSelect={(date) => handleSelectCalendarDay(date)}
        />
      </section>
      <section>
        <h3>Wybierz pracownika</h3>
        <div>
          {placeEmployee.employees?.length > 0 ? (
            <RadioInput
              name="doctor"
              withImages
              defaultValue={state.employee}
              onInputChange={(option) =>
                setState({ ...state, employee: option })
              }
              options={placeEmployee.employees.map((employee) => {
                return {
                  value: employee.id,
                  name: `${employee?.user?.firstName || "Brak"} ${
                    employee?.user?.lastName || ""
                  }`,
                  img: employee?.user?.avatar?.url
                    ? getUrlToImage(employee.user.avatar.url)
                    : null,
                };
              })}
            />
          ) : (
            <span className="no-data">Brak dostępnych pracowników.</span>
          )}
        </div>
      </section>

      <section>
        <h3>Dostępne godziny wizyt</h3>
        {timeslots?.loading ? (
          <Loader type="Oval" color="#f66829" height={40} width={40} />
        ) : !state.date !== null && allTimeSlots?.length > 0 ? (
          <RadioInput
            name="time"
            className="small"
            defaultValue={state.time}
            onInputChange={(option) => setState({ ...state, time: option })}
            options={allTimeSlots}
          />
        ) : (
          <span className="no-data">
            Brak dostępnych godzin, wybierz inną datę lub weterynarza.
          </span>
        )}
      </section>
      {isLogged && (
        <section>
          <h3>Który pupil będzie pacjentem</h3>
          {petsList?.length > 0 ? (
            <RadioInput
              name="pet"
              withImages
              defaultValue={state.pet.id}
              onInputChange={(option) => setState({ ...state, pet: option })}
              iconPlaceholder="pet"
              options={petsList?.map((pet) => {
                return {
                  value: pet.id,
                  name: pet.name,
                  img: getAvatar(pet),
                };
              })}
            />
          ) : (
            <span className="no-data">
              Nie znaleźliśmy żadnego Twojego pupila. Dodaj go w{" "}
              <Link to="/panel-uzytkownika/moi-pupile">panelu użytkownika</Link>{" "}
              aby móc umówić go na wizytę.
            </span>
          )}
        </section>
      )}
      {isLogged && (
        <section>
          <h3>Czego dotyczy wizyta</h3>
          <textarea
            value={state.desc ? state.desc : ""}
            rows="4"
            onChange={(e) => setState({ ...state, desc: e.target.value })}
            placeholder="(Opcjonalnie napisz kilka słów)"
          />
        </section>
      )}
      <section className="book-visit__right__section__button-wrapper">
        {!!error && <span className="book-visit__error">{error}</span>}
        {!isLogged && (
          <p>Zaloguj się aby móc zarezerwować wizytę dla swojego pupila.</p>
        )}
        {isLogged ? (
          <Button loading={loading} onClick={() => handleReservation()}>
            {visit?.data?.id ? "Zatwierdź" : "Rezerwuj"}
          </Button>
        ) : (
          <Button onClick={() => setShowLoginModal(true)}>Zaloguj</Button>
        )}
      </section>
    </>
  );
};

export default BookVisitForm;
