import React, { useState } from "react";
import Modal from "react-modal";
import { shape, bool, string, number, func } from "prop-types";
import strings from "../../values/Strings";
import EmployeeTimeslotsSettings from "../../components/EmployeeTimeslotsSettings";
import AdminTitle from "../../components/AdminTitle";
import usePreventScroll from "../../hooks/preventScroll";

const EmployeeHours = ({ hideModal, data, loading, isOpen, saveDataModal }) => {
  const [formEdited, setFormEdited] = useState(false);
  const [saveSettings, setSaveSettings] = useState(false);

  const handleUpdateAccount = () => {
    setFormEdited(false);
    saveDataModal();
    setSaveSettings(true);
  };

  const handleCancelUpdateAccount = () => {
    setFormEdited(false);
    hideModal();
  };

  usePreventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      className="add-new-employee--hours modal"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={hideModal}
      closeTimeoutMS={500}
    >
      <AdminTitle
        title={`Ustawienia godzin pracownika ${data?.user?.firstName} ${data?.user?.lastName}`}
        withButtons
        buttonVisible={formEdited}
        onCancelClick={() => {
          handleCancelUpdateAccount();
        }}
        onClick={() => handleUpdateAccount()}
      />
      <EmployeeTimeslotsSettings
        dateTitleText={strings.employee_opening_hours_date_title}
        timeTitleText={strings.employee_opening_hours_time_title}
        formEdited={() => setFormEdited(true)}
        saveSuccess={saveSettings}
        employee={data}
        receptionistDataChange
        setSaveSettings={() => setSaveSettings(false)}
      />
    </Modal>
  );
};

export default EmployeeHours;

EmployeeHours.propTypes = {
  hideModal: func,
  showStatusModal: func,
  loading: bool,
  isOpen: bool,
  saveDataModal: func,
  data: shape({
    created_at: string.isRequired,
    displayPhone: string,
    hidden: bool,
    id: number.isRequired,
    place: shape({}),
    title: string,
    updated_at: string.isRequired,
    user: shape({}),
    workingHours: shape({}),
  }),
};
