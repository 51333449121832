import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getEmployeeSpecializations } from "../../actions/SpecializationsActions";
import { getPetsTypes } from "../../actions/PetsActions";
import { editEmployee } from "../../actions/EmployeeActions";
import CustomSwitch from "../../components/FormComponents/Switch";
import SettingsInput from "../../components/Settings/SettingsInput";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";
import AdminTitle from "../../components/AdminTitle";
import SelectWithOptions from "../../components/SelectWithOptions";
import EmployeeTimeslotsSettings from "../../components/EmployeeTimeslotsSettings";
import { getEmployee } from "../../store/Selectors";
import strings from "../../values/Strings";
import * as employeeActions from "../../actions/EmployeeActions";
import { updateUser, updateUserImage } from "../../actions/UserActions";
import { getAvatar } from "../../utils/utils";

const SettingsPageVet = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const specializations = useSelector((state) => state.specializations);
  const petsTypes = useSelector((state) => state.pets.types);
  const employee = useSelector(getEmployee);
  const employeeId = employee?.id;
  const [formEdited, setFormEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const {
    id,
    firstName,
    lastName,
    email,
    phone,
    pushNotifications,
    doctors,
  } = auth.user;
  const [defaultUserData] = useState({
    id,
    doctors,
    firstName,
    lastName,
    email,
    phone,
    pushNotifications,
  });

  const [defaultDoctorData] = useState({
    title: employee?.title,
    duties: employee?.duties,
  });

  const [userData, setUserData] = useState(defaultUserData);
  const [doctorData, setDoctorData] = useState(defaultDoctorData);
  const [image, setImage] = useState(null);

  useEffect(() => {
    employee?.id && dispatch(getEmployeeSpecializations(employee.id));
    employee?.id && dispatch(getPetsTypes(employee.id));
  }, [dispatch, employee]);

  const newKeys = useCallback((arr) => {
    return arr.flat(Infinity)?.map(({ id: value, name: label, ...rest }) => ({
      value,
      label,
      ...rest,
    }));
  }, []);

  const handleChange = useCallback(
    (options, type) => {
      const optionsIds = options?.map((option) => option.value) || [];

      switch (type) {
        case "specializations":
          dispatch(
            editEmployee(employeeId, { specializations: optionsIds })
          ).then(() => {
            dispatch(employeeActions.getEmployee(employeeId));
          });
          return;
        case "petTypes":
          dispatch(editEmployee(employeeId, { petTypes: optionsIds })).then(
            () => {
              dispatch(employeeActions.getEmployee(employeeId));
            }
          );
          return;
        default:
          dispatch(
            editEmployee(employeeId, { specializations: optionsIds })
          ).then(() => {
            dispatch(employeeActions.getEmployee(employeeId));
          });
      }
    },
    [dispatch, employeeId]
  );

  const updateFormData = (key, value, keyTwo) => {
    setFormEdited(true);

    switch (key) {
      case "pushNotifications":
        setUserData({
          ...userData,
          [key]: { ...userData.pushNotifications, [keyTwo]: value },
        });
        break;
      case "doctor":
        setDoctorData({ ...doctorData, [keyTwo]: value });
        break;
      case "workingHours":
        setDoctorData({
          ...doctorData,
          [key]: { ...doctorData.workingHours, [keyTwo]: value + ":00.000" },
        });
        break;
      default: {
        setUserData({ ...userData, [key]: value });
      }
    }
  };

  const doctorDataInputs = Object.entries(defaultUserData)
    .slice(2, 6)
    .map(([value, label], index) => {
      const labelAddresses = ["Imię", "Nazwisko", "Email", "Numer telefonu"];
      return (
        <SettingsInput
          className="settings-doctor__input"
          key={index}
          type="input"
          label={labelAddresses[index]}
          name={value}
          defaultValue={label}
          isDisabled={index === 2}
          onChange={(e) => updateFormData(`${value}`, e.target.value)}
        />
      );
    });

  const doctorSwitchesNotification =
    defaultUserData?.pushNotifications &&
    Object.entries(defaultUserData.pushNotifications)
      .slice(1, 7)
      .map(([key], index) => {
        const switchLabel = [
          "Powiadomienia push",
          "Powiadomienia o wizytach",
          "Powiadomienia o zapobieganiu",
          "Powiadomienia o lekarzach",
          "Powiadomienia wewnętrzne",
          "Powiadomienia o wiadomościach",
        ];
        return (
          <CustomSwitch
            key={
              index !== 0 && userData?.pushNotifications?.enabled
                ? `push-notifications-${key}-${index}`
                : index
            }
            className="phone-switch"
            name={`push-notifications-${key}`}
            label={switchLabel[index]}
            textLeft="Wyłącz"
            textRight="Włącz"
            defaultValue={
              index > 1
                ? false
                : index !== 0 && !userData?.pushNotifications?.enabled
                ? false
                : userData.pushNotifications[key]
            }
            disabled={
              index > 1 ||
              (index !== 0 && !userData?.pushNotifications?.enabled)
            }
            lowOpacity={index > 1}
            onInputChange={(value) =>
              updateFormData("pushNotifications", value, `${key}`)
            }
          />
        );
      });

  const handleUpdateAccount = () => {
    setFormEdited(false);
    setShowModal(true);
    delete userData.email;

    dispatch(editEmployee(employeeId, doctorData)).then(() => {
      if (image) {
        dispatch(updateUserImage(image, employee.user.id)).then(() => {
          dispatch(updateUser(employee.user.id, userData));
        });
      } else {
        dispatch(updateUser(auth.user.id, userData));
      }
    });
  };

  const handleAddEmployeeImage = (image) => {
    setFormEdited(true);
    setImage(image);
  };

  // const placesOptions = useMemo(() => {
  //   if (employee?.places?.length > 0) {
  //     return employee.places.map((place) => {
  //       return {
  //         value: place.id,
  //         name: place.name,
  //       };
  //     });
  //   }
  //   return [];
  // }, [employee]);

  return (
    <div className="settings-doctor-page">
      <div className="container">
        <AdminTitle
          title="Ustawienia panelu pracownika"
          description="Dane panelu weterynarza do zmian"
          withButtons
          buttonVisible={formEdited}
          onCancelClick={() => setFormEdited(false)}
          onClick={() => handleUpdateAccount()}
        />
        <section className="settings-doctor">
          <div className="settings-doctor__row">
            <h3>Dane osobowe</h3>
            <SettingsInput
              className="settings-doctor__input"
              type="input"
              label="Numer licencji"
              name="licence"
              defaultValue={employee?.licence}
              isDisabled
              onChange={(e) => updateFormData("licence", e.target.value)}
            />
            <SettingsInput
              className="settings-doctor__input"
              type="input"
              label="Tytuł naukowy"
              name="title"
              defaultValue={employee?.title}
              onChange={(e) =>
                updateFormData("doctor", e.target.value, "title")
              }
            />
            {doctorDataInputs}
            <SettingsInput
              className="settings-doctor__input"
              type="file"
              label="Zdjęcie profilowe"
              name="petImage"
              onChange={(value) => handleAddEmployeeImage(value)}
              img={getAvatar(auth?.user)}
              defaultValue={employee?.user}
            />
          </div>
          <div className="settings-doctor__row">
            <h3>Wykonywane specjalizacje</h3>
            <SelectWithOptions
              title="Specjalizacje"
              value={
                employee?.specializations?.length > 0
                  ? newKeys(employee.specializations)
                  : []
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption, "specializations")
              }
              options={
                specializations.employeeSpecializations?.length > 0
                  ? newKeys(specializations.employeeSpecializations)
                  : []
              }
              noOptionText="Brak takiej specjalizacji"
            />
            <SelectWithOptions
              title="Przyjmowane zwierzęta"
              value={
                employee?.petTypes?.length > 0 ? newKeys(employee.petTypes) : []
              }
              onChange={(selectedOption) =>
                handleChange(selectedOption, "petTypes")
              }
              options={petsTypes?.length > 0 ? newKeys(petsTypes) : []}
              noOptionText="Brak opcji"
            />
          </div>
          <div className="settings-doctor__row">
            <EmployeeTimeslotsSettings
              dateTitleText={strings.employee_opening_hours_date_title}
              timeTitleText={strings.employee_opening_hours_time_title}
              formEdited={() => setFormEdited(true)}
              saveSuccess={showModal}
              employee={employee}
            />
          </div>
          <div className="settings-doctor__row">
            <h3>Powiadomienia</h3>
            <div className="settings-doctor__input settings-doctor__input--flex big-input">
              {doctorSwitchesNotification}
            </div>
          </div>
        </section>
        <RequestStatusModal
          close={() => setShowModal(false)}
          successComponent={<h2>Dane zapisane poprawnie!</h2>}
          isOpen={showModal}
        />
      </div>
    </div>
  );
};

export default SettingsPageVet;
