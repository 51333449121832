export const schedulerOptions = [
  { value: "Today", name: "Dziś", date: new Date() },
  {
    value: "Tomorrow",
    name: "Jutro",
    date: new Date().setDate(new Date().getDate() + 1),
  },
  {
    value: "DayAfterTomorrow",
    name: "Pojutrze",
    date: new Date().setDate(new Date().getDate() + 2),
  },
  { value: "Week", name: "Najbliższe 7 dni" },
  { value: "Month", name: "Miesiąc" },
];

export const viewOrientation = [
  { name: "horyzontalny", value: "Horizontal" },
  { name: "wertykalny", value: "Vertical" },
  { name: "Dzień", value: "Day" },
];

export const speciesOptions = [
  { value: "kot", name: "Kot" },
  { value: "pies", name: "Pies" },
  { value: "gryzoń", name: "Gryzoń" },
  { value: "inne", name: "Inne" },
];

export const originOptions = [
  { value: "hodowla", name: "Hodowla" },
  { value: "prywatnie", name: "Prywatnie" },
];

export const radioOptions = [
  { value: "male", name: "Samiec" },
  { value: "female", name: "Samica" },
];
