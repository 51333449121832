import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import InputComponent from "../Input";
import Select from "../FormComponents/Select";
import MyCalendar from "../Calendar";
import Button from "../Button";

import { getServices } from "../../actions/ServicesActions";
import { reserveVisit } from "../../actions/VisitsActions";
import usePreventScroll from "../../hooks/preventScroll";

const AddVisitModal = ({ petId, hide, isOpen }) => {
  const dispatch = useDispatch();
  const services = useSelector((state) => state.services);

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const servicesNewKeys = [
    ...services.data?.map(({ id: value, title: name, ...rest }) => ({
      value,
      name,
      ...rest,
    })),
  ];

  const defaultData = {
    start: new Date(),
    pet: petId,
    desc: "",
    service: null,
    external: true,
    externalVisitData: {
      doctorName: "",
      placeStreet: "",
      doctorPhone: "",
      placeZip: "",
      placeCity: "",
      placeName: "",
    },
  };

  const [data, setData] = useState(defaultData);

  const [error, setError] = useState({});

  const updaeFormData = (key, val) => {
    const objKey = Object.keys(val)[0];

    setData({
      ...data,
      [key]:
        key === "externalVisitData"
          ? {
              ...data.externalVisitData,
              [objKey]: val[objKey],
            }
          : val,
    });

    if (
      key === "externalVisitData" ? val[objKey].length === 0 : val?.length === 0
    ) {
      setError({
        ...error,
        [key === "externalVisitData" ? data.externalVisitData : [key]]: true,
      });
    } else {
      setError({ ...error, [key]: false });
    }
  };

  const addPet = () => {
    let dataItem = {};
    Object.keys(data).forEach((item, i) => {
      if (dataItem[item]?.length === 0) {
        dataItem[item] = true;
      } else {
        dataItem[item] = false;
      }
    });
    setError(dataItem);

    if (dataItem.desc === false) {
      dispatch(reserveVisit(data));
      setData(defaultData);
      hide();
    }
  };

  usePreventScroll(isOpen);

  return (
    <Modal
      className="modal add-visit"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={hide}
      isOpen={isOpen}
      closeTimeoutMS={500}
    >
      <div className="modal-head row justify-content-between align-items-center">
        <h2>Dodaj wizytę do historii wizyt pupila</h2>
        <Button onClick={addPet} className=" add-btn">
          Dodaj
        </Button>
        <Button onClick={hide} className="transparent padding-0 cancel-btn">
          Anuluj
        </Button>
      </div>

      <div className="modal-content">
        <form className="row no-gutters justify-content-between" method="post">
          <div className="left col-11">
            <fieldset>
              <InputComponent
                name="placeStreet"
                label="Adres placówki"
                placeholder="Adres placówki"
                value={data.externalVisitData.placeStreet}
                onChange={(e) => {
                  updaeFormData("externalVisitData", {
                    placeStreet: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset>
              <InputComponent
                name="placeZip"
                label="Kod pocztowy placówki"
                placeholder="Kod pocztowy placówki"
                value={data.externalVisitData.placeZip}
                onChange={(e) => {
                  updaeFormData("externalVisitData", {
                    placeZip: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset>
              <InputComponent
                name="placeCity"
                label="Miasto placówki"
                placeholder="Miasto placówki"
                value={data.externalVisitData.placeCity}
                onChange={(e) => {
                  updaeFormData("externalVisitData", {
                    placeCity: e.target.value,
                  });
                }}
              />
            </fieldset>
            <fieldset>
              <InputComponent
                name="placeName"
                label="Nazwa placówki"
                placeholder="Nazwa placówki"
                value={data.externalVisitData.placeName}
                onChange={(e) => {
                  updaeFormData("externalVisitData", {
                    placeName: e.target.value,
                  });
                }}
              />
            </fieldset>

            <fieldset>
              <InputComponent
                name="doctorName"
                label="Imię weterynarza"
                placeholder="Imię weterynarza"
                value={data.externalVisitData.doctorName}
                onChange={(e) => {
                  updaeFormData("externalVisitData", {
                    doctorName: e.target.value,
                  });
                }}
              />
            </fieldset>

            <fieldset>
              <InputComponent
                name="doctorPhone"
                label="Numer telefonu weterynarza"
                placeholder="Numer telefonu weterynarza"
                value={data.externalVisitData.doctorPhone}
                onChange={(e) => {
                  updaeFormData("externalVisitData", {
                    doctorPhone: e.target.value,
                  });
                }}
              />
            </fieldset>
          </div>

          <div className="right col-11">
            <fieldset>
              <MyCalendar
                name="start"
                maxDate={new Date()}
                onDateSelect={(selected) => updaeFormData("start", selected)}
              />
            </fieldset>

            <fieldset>
              <span className="label">Opis wizyty</span>
              <textarea
                name="desc"
                placeholder="Opis wizyty"
                rows="3"
                value={data.desc}
                onChange={(e) => {
                  updaeFormData("desc", e.target.value);
                }}
              />
              {error.desc && (
                <span className="error">Proszę wypełnij to pole.</span>
              )}
            </fieldset>
          </div>

          <div className="bottom col-24">
            <fieldset>
              <Select
                name="service"
                label="Badanie"
                loading={services.loading}
                options={servicesNewKeys}
                onInputChange={(selected) =>
                  updaeFormData("service", Number(selected))
                }
              />
            </fieldset>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddVisitModal;

AddVisitModal.propTypes = {
  petId: PropTypes.number,
  isOpen: PropTypes.bool,
};
