import { specializationsApi } from "../api/SpecializationsApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./SpecializationsConstatns";

export const fetchPlaceSpecializations = () => ({
  type: types.FETCH_PLACE_SPECIALIZATIONS,
});

export const savePlaceSepcializations = (data) => ({
  type: types.FETCH_PLACE_SPECIALIZATIONS_SUCCESS,
  payload: data,
});

export const errorPlaceSepcializations = (errorMessage) => ({
  type: types.FETCH_PLACE_SPECIALIZATIONS_FAILURE,
  payload: errorMessage,
});

export const getPlaceSpecializations = () => (dispatch) => {
  dispatch(fetchPlaceSpecializations());
  specializationsApi
    .getPlaceSpecializations()
    .then((res) => {
      dispatch(savePlaceSepcializations(res.data));
    })
    .catch((err) => {
      dispatch(errorPlaceSepcializations(errorParser.parseError(err).message));
    });
};

export const fetchEmployeeSpecializations = () => ({
  type: types.FETCH_EMPLOYEE_SPECIALIZATIONS,
});

export const saveEmployeeSepcializations = (data) => ({
  type: types.FETCH_EMPLOYEE_SPECIALIZATIONS_SUCCESS,
  payload: data,
});

export const errorEmployeeSepcializations = (errorMessage) => ({
  type: types.FETCH_EMPLOYEE_SPECIALIZATIONS_FAILURE,
  payload: errorMessage,
});

export const getEmployeeSpecializations = () => (dispatch) => {
  dispatch(fetchEmployeeSpecializations());
  specializationsApi
    .getEmployeeSpecializations()
    .then((res) => {
      dispatch(saveEmployeeSepcializations(res.data));
    })
    .catch((err) => {
      dispatch(
        errorEmployeeSepcializations(errorParser.parseError(err).message)
      );
    });
};
