import * as type from "./UserConstants";
import { userApi } from "../api/UserApi";
import { errorParser } from "../api/ErrorParser";
import { setJWTToken, setUserRole } from "../store/SecureStorage";

export const loggedIn = (data) => ({
  type: type.LOGGED_IN,
  payload: data,
});

export const registerIn = (data) => ({
  type: type.REGISTER,
  payload: data,
});

export const clearErrorMessage = () => ({
  type: type.CLEAR_ERROR_MESSAGE,
});

export const errorAuth = (errorMessage) => ({
  type: type.ERROR_LOG_IN,
  payload: errorMessage,
});

export const loading = () => ({
  type: type.LOADING,
});

export const loggedOut = () => ({
  type: type.LOGOUT,
});

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  localStorage.removeItem("role");
  dispatch(loggedOut());
};

export const updateAuthState = (token) => ({
  type: type.UPDATE_AUTH_STATE,
  payload: token,
});

export const saveUserProfile = (data) => ({
  type: type.GET_USER_PROFILE_SUCCESS,
  payload: data,
});

export const updateUserSuccess = (data) => ({
  type: type.UPDATE_EMIAL_SUCCESS,
  payload: data,
});

export const updateUserAvatarSuccess = (data) => ({
  type: type.UPDATE_USER_AVATAR_SUCCESS,
  payload: data,
});

export const login = (username, password) => (dispatch) => {
  dispatch(loading());
  userApi
    .login(username, password)
    .then((res) => {
      if (res) {
        setJWTToken(res.data.jwt);
        dispatch(loggedIn(res.data));
        setUserRole(res.data.user.role.id);
      }
    })
    .catch((err) => {
      dispatch(errorAuth(err.response.data.data[0].messages[0]));
    });
};

export const loginWithProvider = (provider, callback) => (dispatch) => {
  dispatch(loading());
  userApi
    .loginWithProvider(provider, callback)
    .then((res) => {
      dispatch(loggedIn(res.data));
    })
    .catch((err) => {
      dispatch(errorAuth(errorParser.parseError(err).message));
    });
};

export const register = (
  firstName,
  lastName,
  email,
  phone,
  password,
  repeatPassword
) => (dispatch) => {
  dispatch(loading());
  userApi
    .register(firstName, lastName, email, phone, password, repeatPassword)
    .then((res) => {
      dispatch(registerIn(res.data));
    })
    .catch((err) => {
      dispatch(errorAuth(err.response.data.message[0].messages[0]));
    });
};

export const getUserProfile = () => (dispatch) => {
  dispatch(loading());
  return userApi
    .getProfile()
    .then((res) => {
      dispatch(saveUserProfile(res.data));
      return res.data;
    })
    .catch((err) => {
      if (
        err.message &&
        err.message === "Request failed with status code 401"
      ) {
        dispatch(loggedOut());
      }
      dispatch(errorAuth(errorParser.parseError(err).message));
    });
};

export const updateUser = (id, user, isReceptionist) => (dispatch) => {
  dispatch(loading());
  userApi
    .updateUser(id, user)
    .then((res) => {
      if (!isReceptionist) {
        dispatch(updateUserSuccess(res.data));
      }
    })
    .catch((err) => {
      dispatch(errorAuth(errorParser.parseError(err).message));
    });
};

export const deleteUser = (id) => (dispatch) => {
  dispatch(loading());
  userApi
    .deleteUser(id)
    .then((res) => {
      dispatch(logout());
    })
    .catch((err) => {
      dispatch(errorAuth(errorParser.parseError(err).message));
    });
};

export const emailSendSuccess = (data) => ({
  type: type.EMAIL_SENT_SUCCESS,
  payload: data,
});

export const emailSendError = (data) => ({
  type: type.EMAIL_SENT_FAILURE,
  payload: data,
});

export const forgotPassword = (email) => (dispatch) => {
  dispatch(loading());
  return userApi
    .forgotPassword(email)
    .then((res) => {
      dispatch(emailSendSuccess(res.data.ok));
      return true;
    })
    .catch((err) => {
      dispatch(errorAuth(errorParser.parseError(err).message));
      return false;
    });
};

export const resetPasswordSuccess = () => ({
  type: type.RESET_PASSWORD_SUCCESS,
});

export const resetPasswordErr = (err) => ({
  type: type.ERROR_RESET_PASSWORD,
  payload: err,
});

export const resetPassword = (code, password, passwordConfirmation) => (
  dispatch
) => {
  return userApi
    .resetPassword(code, password, passwordConfirmation)
    .then((res) => {
      dispatch(resetPasswordSuccess());
      return true;
    })
    .catch((err) => {
      dispatch(resetPasswordErr(errorParser.parseError(err).message));
    });
};

export const updateUserImage = (image, userId) => (dispatch) => {
  dispatch(loading());
  return userApi
    .uploadUserImage(image, userId)
    .then(() => {
      dispatch(updateUserAvatarSuccess());
      return true;
    })
    .catch((err) => {
      dispatch(errorAuth(errorParser.parseError(err).message));
      return false;
    });
};
