export const FETCH_PLACE_SPECIALIZATIONS = "FETCH_PLACE_SPECIALIZATIONS";
export const FETCH_PLACE_SPECIALIZATIONS_SUCCESS =
  "FETCH_PLACE_SPECIALIZATIONS_SUCCESS";
export const FETCH_PLACE_SPECIALIZATIONS_FAILURE =
  "FETCH_PLACE_SPECIALIZATIONS_ERROR";

export const FETCH_EMPLOYEE_SPECIALIZATIONS = "FETCH_EMPLOYEE_SPECIALIZATIONS";
export const FETCH_EMPLOYEE_SPECIALIZATIONS_SUCCESS =
  "FETCH_EMPLOYEE_SPECIALIZATIONS_SUCCESS";
export const FETCH_EMPLOYEE_SPECIALIZATIONS_FAILURE =
  "FETCH_EMPLOYEE_SPECIALIZATIONS_ERROR";
