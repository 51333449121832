import API from "./ApiConfig";

export const placesApi = {
  getTypesOfPalces,
  getAllPlaces,
  search,
  updatePlace,
  getPlaceById,
  getEmployeesByPlaceId,
};

function getTypesOfPalces(id) {
  return API.get("place-types", {
    params: { id },
  });
}

function updatePlace(id, data) {
  return API.put(`/places/${id}`, data);
}

function getAllPlaces(
  sort,
  limit,
  city,
  typeId,
  employees,
  placeSpecId,
  employeeSpecId
) {
  return API.get(`places/`, {
    params: {
      _sort: sort,
      _limit: limit,
      _q: city || undefined,
      "type.id": typeId || undefined,
      employees,
      "spec.id": placeSpecId,
      "employees.specializations.id": employeeSpecId || undefined,
    },
  });
}

function search(query, type) {
  let params = {};
  if (query && query.length !== 0) {
    params = { ...params, _q: query };
  }
  if (type && type.length !== 0) {
    params = { ...params, "type.id": type };
  }
  return API.get("places", { params: params });
}

function getPlaceById(id) {
  return API.get(`/places/${id}`);
}

function getEmployeesByPlaceId(id) {
  return API.get(`/places/${id}/employees`);
}
