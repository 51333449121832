import React, { useEffect } from "react";

import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import * as articlesActions from "../../actions/ArticlesActions";

import ArticleItem from "../ArticleItem/ArticleItem";
import Slider from "react-slick";

import { settings } from "../../values/SlidersSettings";

const ArticlesSection = () => {
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.articles);

  useEffect(() => {
    dispatch(articlesActions.getArticles());
  }, [dispatch]);

  const sSettings = {
    ...settings,
    slidesToShow: 4,
    infinite: articles.list.length > 4,
  };

  return (
    <div className="section section--articles">
      <header>
        <h1 className="title">Dobrze wiedzieć</h1>
        <Link className="link-more" to="/artykuly">
          Więcej
        </Link>
      </header>
      <Slider {...sSettings}>
        {articles?.list?.map((item, i) => (
          <ArticleItem key={i} size="small" article={item} />
        ))}
      </Slider>
    </div>
  );
};

export default ArticlesSection;
