import React from "react";
import moment from "moment";
import strings from "../../values/Strings";
import PropTypes from "prop-types";

const Notifications = ({ notifications, showNotification, getId, close }) => {
  return (
    <>
      <div className={`notifications ${showNotification ? "active" : ""}`}>
        <table>
          <thead>
            <tr>
              {/*<th>{strings.notification_internal_communication_title}</th>*/}
              <th>{strings.notification_visits_title}</th>
              <th>{strings.notification_canceled_visits_title}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {/*<td>{strings.no_referrals}</td>*/}
              <td>
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <div
                      key={notification.id}
                      className={`notification${
                        notification.opened ? " click" : ""
                      }`}
                      onClick={
                        notification.opened
                          ? () => getId(notification.id)
                          : null
                      }
                    >
                      <div className="notification__text">
                        <div className="title">{notification.title}</div>
                        {notification?.visit?.pet?.user?.firstName &&
                          notification?.visit?.pet?.user?.lastName && (
                            <div className="text">
                              {`${strings.pet_owner} ${notification?.visit?.pet?.user?.firstName} ${notification?.visit?.pet?.user?.lastName}`}
                            </div>
                          )}
                        {notification?.visit?.pet?.user?.phone && (
                          <div className="text">
                            {strings.contact_phone}
                            <a
                              href={`tel:${notification?.visit?.pet?.user?.phone}`}
                            >
                              {notification?.visit?.pet?.user?.phone}
                            </a>
                          </div>
                        )}
                        {notification?.visit?.pet?.name && (
                          <div className="text">
                            {`${strings.pet_name} ${notification?.visit?.pet?.name}`}
                          </div>
                        )}
                        <div className="text">
                          <div>
                            {moment(notification?.visit?.start).calendar()}
                          </div>
                        </div>
                      </div>
                      {notification.opened && <div className="readed" />}
                    </div>
                  ))
                ) : (
                  <div>{strings.notification_list_empty}</div>
                )}
              </td>
              <td>
                <div>{strings.canceled_visits_empty}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showNotification && <div onClick={close} className="background-blur" />}
    </>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.array,
  getId: PropTypes.func,
  close: PropTypes.func,
};

export default Notifications;
