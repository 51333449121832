import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTypes, updatePlace, getPlaces } from "../../actions/PlacesActions";
import CustomSwitch from "../../components/FormComponents/Switch";
import SettingsInput from "../../components/Settings/SettingsInput";
import { updateUser } from "../../actions/UserActions";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";
import moment from "moment";
import { firstUpperCase } from "../../utils/utils";
import AdminTitle from "../../components/AdminTitle";
import strings from "../../values/Strings";
import { getEmployee, getAuth } from "../../store/Selectors";
import * as employeeActions from "../../actions/EmployeeActions";
import * as specializationsActions from "../../actions/SpecializationsActions";

const SettingsPageReceptionist = () => {
  const dispatch = useDispatch();

  const auth = useSelector(getAuth);
  const employee = useSelector(getEmployee);
  const places = useSelector((state) => state.places);
  const [formEdited, setFormEdited] = useState(false);
  const [userFormEdited, setUserFormEdited] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const receptionistPlace = useMemo(() => places.placesList[0], [places]);
  const placeTypeId = useMemo(() => places.placesList?.type?.id, [places]);
  const possible_place_specializations = useSelector(
    (state) => state.specializations.placeSpecializations
  );

  useEffect(() => {
    if (auth?.user?.employee?.id) {
      dispatch(employeeActions.getEmployee(auth?.user?.employee?.id));
    } else if (auth?.user?.employee) {
      dispatch(employeeActions.getEmployee(auth?.user?.employee));
    }
    dispatch(specializationsActions.getPlaceSpecializations());
  }, [dispatch, auth]);

  useEffect(() => {
    employee?.places &&
      dispatch(
        getPlaces(
          "created_at:DESC",
          999,
          null,
          null,
          null,
          null,
          null,
          employee?.places[0]?.id
        )
      );
  }, [dispatch, employee]);

  const [defaultPlaceData, setDefaultPlaceData] = useState({
    name: receptionistPlace?.name,
    address_street: receptionistPlace?.address_street,
    address_zip: receptionistPlace?.address_zip,
    address_city: receptionistPlace?.address_city,
    desc: receptionistPlace?.desc,
    contact_phone: receptionistPlace?.contact_phone,
    contact_email: receptionistPlace?.contact_email,
  });

  const [defaultReceptniostData, setDetaultReceptionistData] = useState({
    pushNotifications: auth.user.pushNotifications,
  });

  const [openingHoursData, setOpeningHoursData] = useState({
    fridayFrom: receptionistPlace?.openHours?.fridayFrom
      ? moment(receptionistPlace?.openHours?.fridayFrom, "HH:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    fridayTo: receptionistPlace?.openHours?.fridayTo
      ? moment(receptionistPlace?.openHours?.fridayTo, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    mondayFrom: receptionistPlace?.openHours?.mondayFrom
      ? moment(receptionistPlace?.openHours?.mondayFrom, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    mondayTo: receptionistPlace?.openHours?.mondayTo
      ? moment(receptionistPlace?.openHours?.mondayTo, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    saturdayFrom: receptionistPlace?.openHours?.saturdayFrom
      ? moment(
          receptionistPlace?.openHours?.saturdayFrom,
          "hh:mm:ss.sss"
        ).format("HH:mm:ss.sss")
      : "00:00:00.000",
    saturdayTo: receptionistPlace?.openHours?.saturdayTo
      ? moment(receptionistPlace?.openHours?.saturdayTo, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    sundayFrom: receptionistPlace?.openHours?.sundayFrom
      ? moment(receptionistPlace?.openHours?.sundayFrom, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    sundayTo: receptionistPlace?.openHours?.sundayTo
      ? moment(receptionistPlace?.openHours?.sundayTo, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    thursdayFrom: receptionistPlace?.openHours?.thursdayFrom
      ? moment(
          receptionistPlace?.openHours?.thursdayFrom,
          "hh:mm:ss.sss"
        ).format("HH:mm:ss.sss")
      : "00:00:00.000",
    thursdayTo: receptionistPlace?.openHours?.thursdayTo
      ? moment(receptionistPlace?.openHours?.thursdayTo, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    tuesdayFrom: receptionistPlace?.openHours?.tuesdayFrom
      ? moment(
          receptionistPlace?.openHours?.tuesdayFrom,
          "hh:mm:ss.sss"
        ).format("HH:mm:ss.sss")
      : "00:00:00.000",
    tuesdayTo: receptionistPlace?.openHours?.tuesdayTo
      ? moment(receptionistPlace?.openHours?.tuesdayTo, "hh:mm:ss.sss").format(
          "HH:mm:ss.sss"
        )
      : "00:00:00.000",
    wednesdayFrom: receptionistPlace?.openHours?.wednesdayFrom
      ? moment(
          receptionistPlace?.openHours?.wednesdayFrom,
          "hh:mm:ss.sss"
        ).format("HH:mm:ss.sss")
      : "00:00:00.000",
    wednesdayTo: receptionistPlace?.openHours?.wednesdayTo
      ? moment(
          receptionistPlace?.openHours?.wednesdayTo,
          "hh:mm:ss.sss"
        ).format("HH:mm:ss.sss")
      : "00:00:00.000",
  });

  useEffect(() => {
    receptionistPlace?.id &&
      receptionistPlace?.openHours &&
      setDefaultPlaceData({
        name: receptionistPlace?.name,
        address_street: receptionistPlace?.address_street,
        address_zip: receptionistPlace?.address_zip,
        address_city: receptionistPlace?.address_city,
        desc: receptionistPlace?.desc,
        contact_phone: receptionistPlace?.contact_phone,
        contact_email: receptionistPlace?.contact_email,
        openingHours: openingHoursData,
      });
  }, [receptionistPlace, openingHoursData]);

  // calendar days
  const weekDays = moment().locale("en").localeData().weekdays();
  const plWeekDays = moment.weekdays();

  const [placeFormData, setFormData] = useState(defaultPlaceData);
  const [userFormData, setUserFormData] = useState(defaultReceptniostData);

  useEffect(() => placeTypeId && dispatch(getTypes(placeTypeId)), [
    dispatch,
    placeTypeId,
  ]);

  useEffect(() => {
    auth?.user &&
      setDetaultReceptionistData({
        pushNotifications: auth.user.pushNotifications,
      });
  }, [auth]);

  const specializationsOptions = useMemo(() => {
    if (possible_place_specializations?.length > 0) {
      return possible_place_specializations.map((specialization) => {
        return {
          value: specialization.id,
          label: specialization.name,
        };
      });
    }
    return [];
  }, [possible_place_specializations]);

  const specializationsValues = useMemo(() => {
    if (receptionistPlace?.spec?.length > 0) {
      return receptionistPlace.spec.map((specialization) => {
        return {
          value: specialization.id,
          label: specialization.name,
        };
      });
    }
    return [];
  }, [receptionistPlace]);

  const handleChange = useCallback(
    (options) => {
      const optionsIds = options?.map((option) => option.value) || [];
      dispatch(updatePlace(receptionistPlace.id, { spec: optionsIds }));
    },
    [dispatch, receptionistPlace]
  );

  const updatePlaceFormData = (key, value, keyTwo) => {
    setFormEdited(true);

    if (key === "openHours") {
      setFormData({
        ...placeFormData,
        [key]: {
          ...openingHoursData,
          ...placeFormData.openHours,
          [keyTwo]: value !== null ? value + ":00.000" : null,
        },
      });
    } else {
      setFormData({ ...placeFormData, [key]: value });
    }
  };

  const updateUserFormData = (key, keyTwo, value) => {
    setUserFormEdited(true);
    setUserFormData({
      ...userFormData,
      [key]: { ...userFormData.pushNotifications, [keyTwo]: value },
    });
  };

  const handleUpdateAccount = () => {
    setShowModal(true);
    setFormEdited(false);
    dispatch(updatePlace(receptionistPlace.id, placeFormData));
  };

  const handleUpdateUser = () => {
    setUserFormEdited(false);
    dispatch(updateUser(auth.user.id, userFormData));
  };

  const addressDataInputs = Object.entries(defaultPlaceData)
    .slice(0, 4)
    .map(([value, label], index) => {
      const labelAddresses = [
        strings.place_name,
        strings.place_street,
        strings.place_zip_code,
        strings.place_city,
      ];
      return (
        <SettingsInput
          className="settings-section__input"
          key={label !== undefined ? index : `${index}-loading`}
          type="input"
          label={labelAddresses[index]}
          name={value}
          defaultValue={label}
          onChange={(e) => updatePlaceFormData(`${value}`, e.target.value)}
        />
      );
    });

  const contactDataInputs = Object.entries(defaultPlaceData)
    .slice(5, 7)
    .map(([value, label], index) => {
      const labelContact = [strings.place_phone, strings.place_email];
      return (
        <SettingsInput
          className="settings-section__input"
          key={label !== undefined ? index : `${index}-loading`}
          type="input"
          label={labelContact[index]}
          name={value}
          defaultValue={label}
          onChange={(e) => updatePlaceFormData(`${value}`, e.target.value)}
        />
      );
    });

  const switchesNotification =
    defaultReceptniostData.pushNotifications &&
    Object.entries(defaultReceptniostData.pushNotifications)
      .slice(1, 7)
      .map(([key, label], index) => {
        const switchLabel = [
          `${strings.notifications} ${strings.push_notifications}`,
          `${strings.notifications} ${strings.vitis_notifications}`,
          `${strings.notifications} ${strings.prevent_notifications}`,
          `${strings.notifications} ${strings.doctors_notifications}`,
          `${strings.notifications} ${strings.inside_notifications}`,
          `${strings.notifications} ${strings.message_notifications}`,
        ];
        return (
          <CustomSwitch
            key={
              index !== 0 && userFormData?.pushNotifications?.enabled
                ? `push-notifications-${key}-${index}`
                : index
            }
            className="phone-switch"
            name={`push-notifications-${key}`}
            label={switchLabel[index]}
            textLeft={strings.switch_off}
            textRight={strings.switch_on}
            defaultValue={
              index > 1
                ? false
                : index !== 0 && !userFormData?.pushNotifications?.enabled
                ? false
                : userFormData.pushNotifications[key]
            }
            disabled={
              index > 1 ||
              (index !== 0 && !userFormData?.pushNotifications?.enabled)
            }
            lowOpacity={index > 1}
            onInputChange={(value) =>
              updateUserFormData("pushNotifications", `${key}`, value)
            }
          />
        );
      });

  const isDescription = (element) => {
    return element === "desc";
  };

  const placeDescriptionData = Object.entries(defaultPlaceData) &&
    Object.entries(defaultPlaceData).findIndex(isDescription) && (
      <div className="settings-section__input settings-section__input--textarea">
        <fieldset>
          <span className="label">
            <label className="input-component">
              {strings.place_description}
            </label>
          </span>
          <textarea
            className="settings-section__textarea"
            defaultValue={defaultPlaceData?.desc || receptionistPlace?.desc}
            key={`placeDescription:${
              defaultPlaceData?.desc || receptionistPlace?.desc
            }`}
            rows="4"
            name="desc"
            onChange={(e) => updatePlaceFormData("desc", e.target.value)}
            placeholder="(Opcjonalnie napisz kilka słów)"
          />
        </fieldset>
      </div>
    );

  return (
    <div className="settings-page">
      <div className="container">
        <AdminTitle
          title={strings.settings_heading}
          description={strings.settings_description}
          withButtons
          buttonVisible={formEdited}
          onCancelClick={() => setFormEdited(false)}
          onClick={() => handleUpdateAccount()}
        />
        <section className="settings-section">
          <div className="settings-section__row">
            <h3>{strings.address_label}</h3>
            {addressDataInputs}
          </div>
          <div className="settings-section__row">
            <h3>{strings.place_label}</h3>
            {placeDescriptionData}
            {contactDataInputs}
            {/*<SettingsInput*/}
            {/*className="settings-section__input big-input"*/}
            {/*type="file"*/}
            {/*label={strings.place_image}*/}
            {/*name="buildingImage"*/}
            {/*onChange={(value) => updateFormData(value)}*/}
            {/*img={""}*/}
            {/*/>*/}
            <div className="settings-section__row">
              <h3>{strings.working_hours_label}</h3>
              {weekDays.map((day, index) => (
                <div className="settings-section__input" key={index}>
                  <fieldset className="settings-section__input--row">
                    <span className="label">
                      {firstUpperCase(plWeekDays[index])}
                    </span>
                    <SettingsInput
                      className="input-time"
                      key={`${day.toLowerCase()}From:${
                        openingHoursData[`${day.toLowerCase()}From`]
                      }`}
                      type="input"
                      defaultValue={
                        openingHoursData[`${day.toLowerCase()}From`]
                      }
                      onChange={(e) => {
                        updatePlaceFormData(
                          "openHours",
                          e.target.value,
                          `${day.toLowerCase()}From`
                        );
                      }}
                      inputType="time"
                      isRequired
                      resetLabel
                      onResetClick={() => {
                        updatePlaceFormData(
                          "openHours",
                          null,
                          `${day.toLowerCase()}From`
                        );
                        setOpeningHoursData({
                          ...openingHoursData,
                          [`${day.toLowerCase()}From`]: null,
                        });
                      }}
                    />
                    <SettingsInput
                      key={`${day.toLowerCase()}To:${
                        openingHoursData[`${day.toLowerCase()}To`]
                      }`}
                      type="input"
                      defaultValue={openingHoursData[`${day.toLowerCase()}To`]}
                      onChange={(e) => {
                        updatePlaceFormData(
                          "openHours",
                          e.target.value,
                          `${day.toLowerCase()}To`
                        );
                      }}
                      inputType="time"
                      isRequired
                      resetLabel
                      onResetClick={() => {
                        updatePlaceFormData(
                          "openHours",
                          null,
                          `${day.toLowerCase()}To`
                        );
                        setOpeningHoursData({
                          ...openingHoursData,
                          [`${day.toLowerCase()}To`]: null,
                        });
                      }}
                    />
                  </fieldset>
                </div>
              ))}
            </div>
          </div>
          <div className="settings-section__row">
            <h3>{strings.place_services_label}</h3>
            <div className="settings-section__input">
              <fieldset>
                <span className="label">
                  {strings.select_place_specialization}
                </span>
                <SettingsInput
                  type="select"
                  placeholder={strings.select_label}
                  isMulti
                  value={specializationsValues}
                  onChange={(selectedOption) => handleChange(selectedOption)}
                  options={specializationsOptions}
                  selectNoOptionsMessage={() => strings.no_specialization}
                  isLoading={places.placesLoading || places.typesLoading}
                  isDisabled={places.placesLoading || places.typesLoading}
                />
              </fieldset>
            </div>
            {/*<div className="settings-section__input">*/}
            {/*<fieldset>*/}
            {/*<span className="label">{strings.services_label}</span>*/}
            {/*<SettingsInput*/}
            {/*type="select"*/}
            {/*value={[]}*/}
            {/*onChange={(selectedOption) => handleChange(selectedOption)}*/}
            {/*options={[]}*/}
            {/*isMulti*/}
            {/*placeholder={strings.select_label}"*/}
            {/*/>*/}
            {/*</fieldset>*/}
            {/*</div>*/}
            {/*<div className="settings-section__input">*/}
            {/*<fieldset>*/}
            {/*<span className="label">{strings.available_equipment}</span>*/}
            {/*<SettingsInput*/}
            {/*type="select"*/}
            {/*value={[]}*/}
            {/*onChange={(selectedOption) => handleChange(selectedOption)}*/}
            {/*options={[]}*/}
            {/*isMulti*/}
            {/*placeholder={strings.select_label}"*/}
            {/*/>*/}
            {/*</fieldset>*/}
            {/*</div>*/}
          </div>
        </section>

        <AdminTitle
          title={strings.settings_user_heading}
          description={strings.settings_user_description}
          withButtons
          buttonVisible={userFormEdited}
          onCancelClick={() => setUserFormEdited(false)}
          onClick={() => handleUpdateUser()}
        />
        <section className="settings-section">
          <div className="settings-section__row">
            <h3>{strings.notifications}</h3>
            <div className="settings-section__input settings-section__input--flex big-input">
              {switchesNotification}
            </div>
          </div>
        </section>
      </div>
      <RequestStatusModal
        close={() => setShowModal(false)}
        successComponent={<h2>{strings.save_data_success}</h2>}
        isOpen={showModal}
      />
    </div>
  );
};

export default SettingsPageReceptionist;
