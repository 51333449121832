import React, { useState, useMemo, useEffect, useCallback } from "react";
import {
  ViewState,
  IntegratedGrouping,
  GroupingState,
} from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  WeekView,
  MonthView,
  Appointments,
  Resources,
  Toolbar,
  DateNavigator,
  TodayButton,
  GroupingPanel,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";

import RadioInput from "../../components/FormComponents/Radio";
import Icon from "../../components/Icon";

import {
  getReceptionistVisits,
  getPetVisits,
  getDoctorVisits,
  getVisits,
} from "../../actions/VisitsActions";

import { getDoctors } from "../../actions/DoctorsActions";
import VisitModal from "../../components/Modals/VisitModal";

import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { typeReceptionist } from "../../values/UserTypes";
import { schedulerOptions, viewOrientation } from "../../values/options";
import { formatLongText } from "../../utils/utils";
import { getAuth, getEmployee, getPlaceEmployee } from "../../store/Selectors";
import * as employeeActions from "../../actions/EmployeeActions";
import strings from "../../values/Strings";
import moment from "moment";
import { getEmployeeByPlaceId } from "../../actions/EmployeeActions";
import { IntegratedAppointments } from "../../components/IntegratedAppoitments";

const VetPanelVisits = () => {
  const dispatch = useDispatch();

  const auth = useSelector(getAuth);
  const employee = useSelector(getEmployee);
  const visitsState = useSelector((state) => state.visits);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentViewName, setCurrentViewName] = useState("Today");
  const [currentGroupName, setCurrentGroupName] = useState("Horizontal");
  const [doctors, setDoctors] = useState([]);

  const employeeId = auth?.user?.employee;
  const [visits, setVisits] = useState([]);
  const [selected, setSelected] = useState([]);
  const isReceptionist = auth.user.role === typeReceptionist;
  const placeEmployee = useSelector(getPlaceEmployee);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    employeeId && dispatch(employeeActions.getEmployee(employeeId));
  }, [dispatch, employeeId]);

  useEffect(() => {
    if (auth?.user?.employee?.id) {
      dispatch(employeeActions.getEmployee(auth?.user?.employee?.id));
    } else if (auth?.user?.employee) {
      dispatch(employeeActions.getEmployee(auth?.user?.employee));
    }
  }, [dispatch, auth]);

  useEffect(() => {
    if (employee !== undefined && employee?.places[0]?.id) {
      dispatch(getEmployeeByPlaceId(employee?.places[0]?.id));
    }
  }, [dispatch, employee]);

  const newKeys = (arr = []) => {
    return arr
      .flat(Infinity)
      ?.map(
        ({ start: startDate, end: endDate, desc: title, pet, ...rest }) => ({
          startDate,
          endDate,
          title,
          pet,
          vetId: rest.employee?.id || rest.employee,
          ...rest,
        })
      );
  };

  const reactSelectNewKeys = (data) => {
    if (data?.length > 0) {
      return data.map((doctor) => {
        return {
          value: doctor.id,
          label: `${doctor?.title ? `${doctor.title} ` : ""}${
            doctor?.user?.firstName
          } ${doctor?.user?.lastName}`,
          ...doctor,
        };
      });
    }
    return [];
  };

  const places = useMemo(() => {
    return (
      employee?.places?.length &&
      employee.places.map(
        (employee) => (employee.place && employee.place) || []
      )
    );
  }, [employee]);

  useEffect(() => {
    if (employee?.id && employee?.places[0]?.id) {
      if (isReceptionist) {
        dispatch(getReceptionistVisits(employee?.places[0]?.id));
      } else {
        dispatch(getDoctorVisits(employee.id));
        dispatch(getVisits());
      }
    }
  }, [dispatch, isReceptionist, employee]);

  useEffect(() => places?.length > 0 && dispatch(getDoctors(places)), [
    places,
    dispatch,
  ]);

  useEffect(() => {
    visitsState.data.length > 0 && setVisits(visitsState);
  }, [visitsState, dispatch]);

  useEffect(() => {
    placeEmployee?.employees?.length > 0 && setDoctors(placeEmployee.employees);
  }, [placeEmployee, dispatch]);

  const handleShowModal = useCallback(
    (appointmentData) => {
      setModalData(appointmentData);
      appointmentData?.pet?.id &&
        dispatch(getPetVisits(appointmentData.pet.id));
    },
    [setModalData, dispatch]
  );

  const onShowDay = (data) => {
    setCurrentViewName("Today");
    setCurrentDate(data.startDate);

    data.groupingInfo?.length > 0 &&
      setDoctors([
        doctors.find((doctor) => doctor.id === data.groupingInfo[0].id),
      ]);

    data.groupingInfo?.length > 0 &&
      setSelected([
        doctors.find((doctor) => doctor.id === data.groupingInfo[0].id),
      ]);
  };

  const TableCell = ({ data, ...rest }) => {
    return (
      <Appointments.Appointment
        className={`appointment ${
          currentViewName === "Month" ? "appointment--month" : ""
        }`}
        style={{ background: data?.employee?.color }}
        onClick={() => handleShowModal(data)}
        {...rest}
      >
        <h2 className="title">{formatLongText(data?.title, 30)}</h2>
        {data?.comment && (
          <div className="description">{formatLongText(data.comment, 45)}</div>
        )}

        <div className="row no-gutters justify-content-start align-items-center">
          {data?.pet?.name && <div className="pet-name">{data?.pet?.name}</div>}

          {data?.pet?.sex && (
            <Icon
              name={
                data?.pet?.sex === "female"
                  ? "female_icon"
                  : data?.pet?.sex === "male" && "male_icon"
              }
              color="white"
            />
          )}
          {/*{data?.pet?.pet_race && (*/}
          {/*<div className="race">&bull; {`Rasa: ${data?.pet?.pet_race}`}</div>*/}
          {/*)}*/}
          {data?.pet?.dateOfBirth && (
            <div className="time">
              &bull;{" "}
              {`Data urodzenia: ${moment(data?.pet?.dateOfBirth).format(
                "DD.MM.YYYY"
              )}`}
            </div>
          )}
        </div>

        <div className="owner">
          {(data?.pet?.user?.firstName || data?.pet?.user?.firstName) && (
            <div className="name">
              Właściciel pupila:
              <strong>{` ${data?.pet?.user?.firstName} ${data?.pet?.user?.lastName}`}</strong>
            </div>
          )}
        </div>
      </Appointments.Appointment>
    );
  };

  const UpdateTimeScaleLabelDayView = ({ ...rest }) => (
    <DayView.TimeScaleLabel className="timeScaleLabel" {...rest} />
  );

  const UpdateTimeScaleLabelWeekView = ({ ...rest }) => (
    <WeekView.TimeScaleLabel className="timeScaleLabel" {...rest} />
  );

  const TimeTableCellMonth = ({ ...rest }) => {
    const { startDate, today } = rest;
    const calendarDays = moment(startDate).isoWeekday();

    return (
      <MonthView.TimeTableCell
        onClick={() => onShowDay(rest)}
        {...rest}
        className={`month-days-wrapper__day ${
          today ? "month-days-wrapper__day--today" : ""
        } ${calendarDays === 6 || calendarDays === 7 ? "weekend" : ""}`}
      />
    );
  };

  const TimeTableCellWeek = ({ ...rest }) => (
    <WeekView.TimeTableCell onClick={() => onShowDay(rest)} {...rest} />
  );

  const DayScaleCellMonth = ({ ...rest }) => {
    const { startDate } = rest;
    const today = moment().isoWeekday();
    const calendarDays = moment(startDate).isoWeekday();

    return (
      <MonthView.DayScaleCell
        {...rest}
        className={`${today === calendarDays ? "today" : ""} ${
          calendarDays === 6 || calendarDays === 7 ? "weekend" : ""
        }`}
      />
    );
  };

  const MonthAppointmentLayoutComponent = ({ ...rest }) => (
    <MonthView.AppointmentLayer className="dayScaleLayout" {...rest} />
  );

  const MonthAppointmentDay = ({ ...rest }) => (
    <MonthView.TimeTableLayout className="month-days-wrapper" {...rest} />
  );

  const TimeTableCellDay = ({ ...rest }) => (
    <DayView.TimeTableCell className="timeline-day" {...rest} />
  );

  const resources = [
    {
      fieldName: "vetId",
      title: strings.employee,
      instances:
        doctors?.length > 0
          ? doctors.map((doctor) => {
              return {
                text: `${doctor?.title ? `${doctor.title} ` : ""}${
                  doctor?.user?.firstName
                } ${doctor?.user?.lastName}`,
                id: doctor?.id,
                color: doctor?.color,
              };
            })
          : [{ text: " ", id: 1 }],
    },
  ];

  const grouping = [
    {
      resourceName: "vetId",
    },
  ];

  const handleDoctorChooseOptions = (option) => {
    setSelected(option === null ? [] : option);

    setCurrentGroupName("Horizontal");

    if (option === null) {
      if (isReceptionist) {
        dispatch(getReceptionistVisits(employee?.places[0]?.id));
      } else {
        dispatch(getDoctorVisits(employee.id));
        dispatch(getVisits());
      }
      setDoctors(placeEmployee.employees);
    } else {
      setDoctors(option);
      setVisits({
        ...visits,
        data: visitsState?.data.filter((visit) =>
          option.map((option) => option.value).includes(visit.employee.id)
        ),
      });
    }
  };

  const DayScaleCell = ({ ...rest }) => {
    const { startDate } = rest;
    const today = moment().isoWeekday();
    const calendarDays = moment(startDate).isoWeekday();

    return (
      <WeekView.DayScaleCell
        {...rest}
        className={`${today === calendarDays ? "today" : ""}
                             ${
                               calendarDays === 6 || calendarDays === 7
                                 ? "weekend"
                                 : ""
                             }`}
      />
    );
  };

  return (
    <div className="vet-page-visits">
      <div className="container">
        <h1 className="title">{strings.visits_heading}</h1>
        <p>{strings.visits_description}</p>

        {isReceptionist && (
          <fieldset className="select-vet">
            <span className="label">{strings.choose_vet}</span>
            <Select
              onChange={(selectedOption) =>
                handleDoctorChooseOptions(selectedOption)
              }
              options={reactSelectNewKeys(placeEmployee?.employees)}
              value={
                selected?.length > 0 ? reactSelectNewKeys(selected) : undefined
              }
              isMulti
              className="react-select"
              placeholder={strings.choose_vet}
              noOptionsMessage={() => strings.no_vet_results}
              isLoading={placeEmployee.loading}
              isDisabled={placeEmployee.loading}
            />
          </fieldset>
        )}

        <div className="content">
          <Scheduler locale="pl-PL" data={newKeys(visits?.data)} height={800}>
            <ViewState
              currentDate={currentDate}
              currentViewName={currentViewName}
              onCurrentDateChange={(date) => setCurrentDate(date)}
            />

            {currentGroupName !== "Day" ? (
              <GroupingState
                groupOrientation={() => currentGroupName}
                grouping={grouping}
              />
            ) : null}

            <div className="custom-fields row justify-content-between no-gutters">
              <div>
                <span className="label">{strings.choose_data}</span>
                <RadioInput
                  name="scheduler-view"
                  defaultValue={currentViewName}
                  onInputChange={(name) => {
                    setCurrentViewName(name);
                    setCurrentDate(
                      schedulerOptions.find((item) => item.value === name)?.date
                    );
                  }}
                  options={schedulerOptions}
                />
              </div>

              {isReceptionist && (
                <div>
                  <span className="label">{strings.choose_screen}</span>
                  <RadioInput
                    name="scheduler-view-orientation-change"
                    defaultValue={currentGroupName}
                    onInputChange={(name) => setCurrentGroupName(name)}
                    options={viewOrientation.filter(
                      (view) => view.value !== "Day"
                    )}
                  />
                </div>
              )}
            </div>

            <Toolbar />

            <DayView
              name="Today"
              displayName={strings.today}
              startDayHour={6}
              timeScaleLabelComponent={UpdateTimeScaleLabelDayView}
              dayScaleCellComponent={DayScaleCell}
              cellDuration={15}
              timeTableCellComponent={TimeTableCellDay}
            />

            <DayView
              name="Tomorrow"
              displayName={strings.tomorrow}
              startDayHour={6}
              timeScaleLabelComponent={UpdateTimeScaleLabelDayView}
              dayScaleCellComponent={DayScaleCell}
              timeTableCellComponent={TimeTableCellDay}
              cellDuration={15}
            />

            <DayView
              name="DayAfterTomorrow"
              displayName={strings.in_two_days}
              startDayHour={6}
              cellDuration={15}
              timeScaleLabelComponent={UpdateTimeScaleLabelDayView}
              dayScaleCellComponent={DayScaleCell}
              timeTableCellComponent={TimeTableCellDay}
            />

            <WeekView
              name="Week"
              startDayHour={6}
              cellDuration={15}
              timeScaleLabelComponent={UpdateTimeScaleLabelWeekView}
              timeTableCellComponent={TimeTableCellWeek}
              dayScaleCellComponent={DayScaleCell}
            />
            <MonthView
              name="Month"
              timeTableCellComponent={TimeTableCellMonth}
              dayScaleCellComponent={DayScaleCellMonth}
              appointmentLayerComponent={MonthAppointmentLayoutComponent}
              timeTableLayoutComponent={MonthAppointmentDay}
            />

            <DateNavigator />

            <TodayButton messages={{ today: strings.today }} />

            <Appointments appointmentComponent={TableCell} />

            <Resources data={resources} mainResourceName="vetId" />
            {isReceptionist && currentGroupName !== "Day" ? (
              <IntegratedGrouping />
            ) : null}
            {isReceptionist && currentGroupName !== "Day" ? (
              <GroupingPanel />
            ) : null}

            <CurrentTimeIndicator
              shadePreviousCells={true}
              shadePreviousAppointments={true}
              updateInterval={1000}
            />
            {currentViewName === "Month" && <IntegratedAppointments />}
            <VisitModal
              isOpen={!!Object.keys(modalData).length}
              visits={newKeys(visits?.data)}
              hide={() => {
                if (isReceptionist) {
                  dispatch(getReceptionistVisits(employee?.places[0]?.id));
                } else {
                  dispatch(getDoctorVisits(employee.id));
                  dispatch(getVisits());
                }
                setModalData({});
                setCurrentGroupName("Horizontal");
              }}
              showVisit={(petVisit) => {
                setModalData(petVisit);
                dispatch(getPetVisits(petVisit.pet.id));
              }}
              modalData={modalData}
              loading={visits.loading}
            />
          </Scheduler>
        </div>
      </div>
    </div>
  );
};

export default VetPanelVisits;
