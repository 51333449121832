import { configApi } from "../api/ConfigApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./ConfigConstants";

export const fetchConfig = () => ({
  type: types.FETCH_CONFIG,
});

export const saveConfig = (data) => ({
  type: types.FETCH_CONFIG_SUCCESS,
  payload: data,
});

export const errorConfig = (errorMessage) => ({
  type: types.FETCH_CONFIG_FAILURE,
  payload: errorMessage,
});

export const getConfigOptions = () => (dispatch) => {
  dispatch(fetchConfig());
  configApi
    .getConfig()
    .then(async (res) => {
      dispatch(saveConfig(res.data));
    })
    .catch((err) => {
      dispatch(errorConfig(errorParser.parseError(err).message));
    });
};
