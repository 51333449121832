import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import SearchWidget from "../../components/SearchWidget";
import * as actions from "../../actions/PlacesActions";

const Banner = () => {
  const dispatch = useDispatch();
  const places = useSelector((state) => state.places);

  useEffect(() => {
    dispatch(actions.getPlaces("created_at:DESC", 999));
    dispatch(actions.getTypes());
  }, [dispatch]);

  const loading = places?.placesLoading;
  return (
    <div className="banner">
      <h1 className="banner__title">Zadbaj o zdrowie pupila</h1>
      <p className="banner__description">
        Znajdź klinikę weterynaryjną, fryzjera dla zwierzaka lub miejsce
        przyjazne pupilom
      </p>
      <SearchWidget
        bannerSearch
        data={places?.typesList || []}
        loading={loading}
      />
    </div>
  );
};

export default Banner;
