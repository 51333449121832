import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";

import {
  Redirect,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";

import Header from "../Header";
import SchedulerHeader from "../SchedulerHeader";
import Footer from "../Footer";

import HomePage from "../../pages/HomePage";
import ArticlesPage from "../../pages/ArticlesPage/ArticlesPage";
import ProductsPage from "../../pages/ProductsPage";
import InstitutionPage from "../../pages/InstitutionPage";
import Employees from "../../pages/VetPanelPage/employees";
import VetPanelVisits from "../../pages/VetPanelVisits";
import VetPanelPlanned from "../../pages/VetPanelPlanned";
import ProductPage from "../../pages/ProductPage/";
import ArticlePage from "../../pages/ArticlePage/";
import PolicyPrivacyPage from "../../pages/PolicyPrivacyPage/";
import RegulationsPage from "../../pages/RegulationsPage/";
import ContactPage from "../../pages/ContactPage/";

import MyData from "../../pages/UserPanelPage/MyData";
import Settings from "../../pages/UserPanelPage/Settings";
import PetsList from "../../pages/UserPanelPage/PetsList/index";
import Visits from "../../pages/UserPanelPage/Visits";

import SettingsPageReceptionist from "../../pages/SettingsPages/SettingsPageReceptionist";
import SettingsPageVet from "../../pages/SettingsPages/SettingsPageVet";

import routes from "../../routes";
import LoginModal from "../LoginModal";
import RegisterModal from "../RegisterModal/RegisterModal";
import RemindPassword from "../RemindPassword";
import SetNewPasswordModal from "../Modals/SetNewPasswordModal";
import RequestStatusModal from "../Modals/RequestStatusModal";

import {
  LoginModalContext,
  RegisterModalContext,
  RemindPasswordContext,
} from "../../contexts/modals";

import {
  getUserProfile,
  emailSendError,
  loginWithProvider,
} from "../../actions/UserActions";

import moment from "moment";
import "moment/locale/pl";

import { typeDoctor, typeReceptionist } from "../../values/UserTypes";
import { getAuth, getConfig } from "../../store/Selectors";
import * as configActions from "../../actions/ConfigActions";

import { FACEBOOK_PROVIDER, GOOGLE_PROVIDER } from "../../utils/auth";
import DownloadAppPage from "../../pages/DownloadAppPage";
import { isMobile } from "react-device-detect";

const Main = () => {
  const {
    facility,
    articles,
    products,
    profile,
    vet,
    product,
    article,
    providerAuth,
    home,
    confirmEmail,
    resetPassword,
    privacyPolicy,
    regulations,
    contact,
    downloadApp,
  } = routes;

  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const userRole = parseInt(localStorage.getItem("role"));

  const history = useHistory();

  const { showLoginModal } = useContext(LoginModalContext);
  const { showRegisterModal } = useContext(RegisterModalContext);
  const { showRemindPasswordModal } = useContext(RemindPasswordContext);
  const token = auth?.token || localStorage?.getItem("token");
  const [cookieAlert, setCookieAlert] = useState(
    !!localStorage?.getItem("cookies")
  );
  const isLogged = !!token;
  const [registeredModal, setRegisteredModal] = useState(auth.registered);
  const config = useSelector(getConfig);
  const displayProducts = config?.data?.displayShop;

  useEffect(() => {
    token && dispatch(getUserProfile());
  }, [token, dispatch]);

  const isDoctor = isLogged && userRole === typeDoctor;
  const isReceptionist = isLogged && userRole === typeReceptionist;

  useEffect(() => {
    if (isDoctor && history?.location?.pathname !== `${vet}/wizyty`) {
      history.push(`${vet}/wizyty`);
    }
  }, [isDoctor, isReceptionist, history, vet]);

  const location = useLocation();
  const qData = useMemo(() => queryString?.parse(location?.search), [location]);

  useEffect(() => {
    moment.locale("pl");
  }, []);

  useEffect(() => {
    auth.registered && setRegisteredModal(true);
  }, [auth, setRegisteredModal]);

  useEffect(() => {
    dispatch(configActions.getConfigOptions());
  }, [dispatch]);

  useEffect(() => {
    if (
      history?.location?.pathname === `/connect/${GOOGLE_PROVIDER}/redirect`
    ) {
      dispatch(loginWithProvider(GOOGLE_PROVIDER, location.search));
      history.push("/");
    }

    if (
      history?.location?.pathname === `/connect/${FACEBOOK_PROVIDER}/redirect`
    ) {
      dispatch(loginWithProvider(FACEBOOK_PROVIDER, location.search));
      history.push("/");
    }
  }, [dispatch, history, location]);

  useEffect(() => {
    if (isMobile) {
      if (history?.location?.pathname === home) {
        history.push(downloadApp);
      }

      history.listen((location) => {
        if (location?.pathname !== downloadApp) {
          history.push(downloadApp);
        }
      });
    }
  }, [history, downloadApp, home]);

  return (
    <>
      {isDoctor || isReceptionist ? (
        <SchedulerHeader
          user={auth.user}
          isDoctor={isDoctor}
          isReceptionist={isReceptionist}
        />
      ) : (
        <Header loggedIn={isLogged} displayProducts={displayProducts} />
      )}

      <main className="main">
        <Switch>
          <Route
            exact
            path={home}
            component={() => (
              <HomePage
                displayProducts={displayProducts}
                registeredModal={registeredModal}
              />
            )}
          >
            {(isDoctor || isReceptionist) && (
              <Redirect to={`${vet}/wizyty`} component={VetPanelVisits} />
            )}
          </Route>

          {isMobile && (
            <Route exact to={downloadApp} component={DownloadAppPage} />
          )}

          <Route path={facility} component={InstitutionPage} />
          <Route path={articles} component={ArticlesPage} />
          {displayProducts ? (
            <Route path={products} component={ProductsPage} />
          ) : null}
          <Route path={product} component={ProductPage} />
          <Route path={article} component={ArticlePage} />
          <Route path={providerAuth} component={ArticlesPage} />
          <Route path={confirmEmail} component={HomePage} />
          <Route
            path={contact}
            component={() => <ContactPage config={config} />}
          />

          <Route path={privacyPolicy} component={PolicyPrivacyPage} />
          <Route path={regulations} component={RegulationsPage} />
        </Switch>

        {isLogged && (
          <Switch>
            <Route exact path={`${profile}`} component={Visits} />
            <Route path={`${profile}/moi-pupile`} component={PetsList} />
            <Route path={`${profile}/moje-dane`} component={MyData} />
            <Route path={`${profile}/ustawienia`} component={Settings} />
          </Switch>
        )}

        {(isDoctor || isReceptionist) && (
          <Switch>
            <Route path={`${vet}/wizyty/:id?`} component={VetPanelVisits} />
            <Route
              path={`${vet}/planowanie/:id?`}
              component={() => (
                <VetPanelPlanned isReceptionist={isReceptionist} />
              )}
            />
            <Route
              path={`${vet}/ustawienia`}
              component={() =>
                isReceptionist ? (
                  <SettingsPageReceptionist />
                ) : (
                  <SettingsPageVet />
                )
              }
            />
          </Switch>
        )}

        {isReceptionist && (
          <Switch>
            <Route path={`${vet}/employees`} component={Employees} />
          </Switch>
        )}

        {Object.keys(qData).length > 0 && (
          <Switch>
            <Route
              path={resetPassword}
              component={() => <SetNewPasswordModal qData={qData} />}
            />
          </Switch>
        )}

        <LoginModal isOpen={showLoginModal} />
        <RegisterModal isOpen={showRegisterModal} />
        <RemindPassword isOpen={showRemindPasswordModal} />
        <RequestStatusModal
          isOpen={auth?.showSendMailModal}
          close={() => dispatch(emailSendError(false))}
          successComponent={
            <>
              <h2>E-mail resetujący hasło został wysłany!</h2>
              <p>
                Sprawdź skrzynkę pocztową i zresetuj hasło poprzez link
                aktywacyjny.
              </p>
            </>
          }
        />
        <RequestStatusModal
          isOpen={registeredModal}
          showModal={true}
          close={() => setRegisteredModal(false)}
          successComponent={
            <>
              <h2>Zarejestrowano pomyślnie!</h2>
              <p>Na Twój adres e-mail wysłaliśmy potwierdzenie rejestracji.</p>
            </>
          }
        />
      </main>
      <Footer
        loggedIn={isLogged}
        isDoctor={isDoctor}
        isReceptionist={isReceptionist}
        cookieAlert={cookieAlert}
        setCookieAlert={setCookieAlert}
        displayProducts={displayProducts}
      />
    </>
  );
};

export default Main;
