import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import ProductItem from "../ProductItem";
import { Tab, Tabs } from "../Tabs";

import * as productsActions from "../../actions/ProductsActions";

import { settings } from "../../values/SlidersSettings";

const ProductsSection = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const { featuredProducts } = products;

  const sSettings = { ...settings, slidesToShow: 4 };

  useEffect(() => {
    dispatch(productsActions.getFeaturedProducts());
  }, [dispatch]);

  return (
    <>
      {featuredProducts.length > 0 && (
        <div className="section section--products">
          <header>
            <h1>Polecane dla pupila</h1>
            <Link className="link-more" to="/produkty">
              Więcej
            </Link>
          </header>
          <Tabs>
            {featuredProducts?.map((tab, index) => {
              return tab?.products?.length > 0 ? (
                <Tab title={tab.title} name={tab.title} key={index}>
                  <Slider
                    className={
                      tab?.products?.length < sSettings.slidesToShow
                        ? "no-clone"
                        : ""
                    }
                    {...sSettings}
                  >
                    {tab?.products?.map((product, i) => (
                      <ProductItem col="24" key={i} data={product} />
                    ))}
                  </Slider>
                </Tab>
              ) : (
                <Fragment key={index} />
              );
            })}
          </Tabs>
        </div>
      )}
    </>
  );
};

export default ProductsSection;
