import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addEmployee,
  editEmployee,
  getEmployeeByPlaceId,
} from "../../actions/EmployeeActions";
import Modal from "react-modal";
import InputComponent from "../../components/Input";
import Select from "../../components/FormComponents/Select";
import { default as ReactSelect } from "react-select";
import { shape, bool, string, number, func } from "prop-types";
import SaveButtons from "../../components/SaveButtons";
import strings from "../../values/Strings";
import { updateUser } from "../../actions/UserActions";
import { typeReceptionist } from "../../values/UserTypes";
import usePreventScroll from "../../hooks/preventScroll";

const EmployeeModal = ({
  hideModal,
  data,
  loading,
  isOpen,
  employee,
  editMode,
  employeeUpdate,
}) => {
  const dispatch = useDispatch();
  const specializations = useSelector((state) => state.specializations);
  const userSpecializations = useMemo(() => data?.specializations, [data]);
  const isReceptionist = employee?.user.role === typeReceptionist;

  const placesOptions = useMemo(() => {
    if (employee && employee?.places?.length > 0) {
      return employee.places.map((place) => {
        return {
          value: place.id,
          name: place.name,
        };
      });
    }
    return [];
  }, [employee]);

  const defaultData = {
    firstName: "",
    title: "",
    lastName: "",
    licence: "",
    phone: "",
    email: "",
    specializations: [],
    places: [data?.places[0]?.id],
  };

  const defaultDoctorData = {
    title: "",
    licence: "",
    specializations: [],
  };

  const [formData, setFormData] = useState(defaultData);
  const [doctorData, setDoctorData] = useState(defaultDoctorData);

  const updateFormData = (key, val) => setFormData({ ...formData, [key]: val });
  const updateDoctorData = (key, val) =>
    setDoctorData({ ...doctorData, [key]: val });

  const ResetModalData = useCallback(() => {
    hideModal();
    setFormData(defaultData);
    setDoctorData(defaultDoctorData);
  }, [hideModal, setFormData, setDoctorData, defaultData, defaultDoctorData]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (data?.user.id && doctorData?.specializations) {
        doctorData.specializations =
          doctorData?.specializations.map(
            (specialization) => specialization.value
          ) || [];
      } else {
        formData.specializations =
          formData?.specializations.map(
            (specialization) => specialization.value
          ) || [];
      }

      if (data?.user.id) {
        dispatch(editEmployee(data?.user.employee, doctorData)).then(() => {
          dispatch(updateUser(data?.user?.id, formData, isReceptionist));
        });
      } else {
        dispatch(addEmployee(formData));
      }

      setTimeout(() => {
        dispatch(getEmployeeByPlaceId(employee?.places[0]?.id));
      }, 300);

      employeeUpdate();
      ResetModalData();
    },
    [
      formData,
      doctorData,
      employee,
      data,
      dispatch,
      isReceptionist,
      employeeUpdate,
      ResetModalData,
    ]
  );

  const specializationsOptions = useMemo(() => {
    if (specializations?.employeeSpecializations?.length > 0) {
      return specializations.employeeSpecializations.map((specialization) => {
        return {
          value: specialization.id,
          label: specialization.name,
        };
      });
    }
    return [];
  }, [specializations]);

  const userSpecs = useMemo(() => {
    if (userSpecializations?.length > 0) {
      return userSpecializations.map((specialization) => {
        return {
          value: specialization.id,
          label: specialization.name,
        };
      });
    }
    return [];
  }, [userSpecializations]);

  useEffect(() => {
    if (isOpen && data?.id) {
      setFormData({
        title: data?.title,
        firstName: data.user?.firstName,
        lastName: data.user?.lastName,
        licence: data?.licence,
        phone: data?.user?.phone,
        email: data?.user?.email,
        specializations: userSpecs,
        places: [data?.places[0]?.id],
        employee: data?.user?.employee,
      });
    }
  }, [data, isOpen, userSpecs]);

  useEffect(() => {
    if (isOpen && data?.id) {
      setDoctorData({
        id: data?.user?.employee,
        title: data?.title,
        licence: data?.licence,
        user: data?.user?.id,
        places: [data?.places[0].id],
        specializations: userSpecs,
      });
    }
  }, [data, isOpen, placesOptions, userSpecs]);

  usePreventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      className="add-new-employee modal"
      overlayClassName="modal-overlay modal-overlay--top"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <form onSubmit={!loading ? onSubmit : undefined}>
        <div className="modal-header">
          <h2>{data?.id ? strings.edit_employee : strings.add_employee_two}</h2>
        </div>
        <div className="modal-content">
          <div className="row">
            <div className="col-4">
              <InputComponent
                placeholder={strings.title}
                label={strings.title}
                name="title"
                value={editMode ? doctorData.title : formData.title}
                onChange={(e) =>
                  editMode
                    ? updateDoctorData("title", e.target.value)
                    : updateFormData("title", e.target.value)
                }
              />
            </div>
            <div className="col-10">
              <InputComponent
                placeholder={strings.firstName}
                label={strings.firstName}
                minLength={2}
                required
                name="name"
                value={formData.firstName}
                onChange={(e) => updateFormData("firstName", e.target.value)}
              />
            </div>
            <div className="col-10">
              <InputComponent
                placeholder={strings.lastName}
                label={strings.lastName}
                name="lastName"
                required
                minLength={2}
                value={formData.lastName}
                onChange={(e) => updateFormData("lastName", e.target.value)}
              />
            </div>
            <div className="col-24">
              <InputComponent
                placeholder={strings.licence_label}
                label={strings.licence_label}
                name="licence"
                required
                value={editMode ? doctorData.licence : formData.licence}
                onChange={(e) =>
                  editMode
                    ? updateDoctorData("licence", e.target.value)
                    : updateFormData("licence", e.target.value)
                }
              />
              <InputComponent
                placeholder={strings.phone_number}
                label={strings.phone_number}
                name="phone"
                minLength={9}
                type="tel"
                required
                value={formData.phone}
                onChange={(e) => updateFormData("phone", e.target.value)}
              />
              <InputComponent
                placeholder={strings.email}
                label={strings.email}
                name="email"
                type="email"
                required
                value={formData.email}
                minLength={4}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                onChange={(e) => updateFormData("email", e.target.value)}
              />

              <fieldset>
                <span className="label">
                  {strings.vet_specialization_label}
                </span>
                <ReactSelect
                  value={
                    editMode
                      ? doctorData.specializations
                      : formData.specializations
                  }
                  onChange={(selectedOption) => {
                    editMode
                      ? updateDoctorData("specializations", selectedOption)
                      : updateFormData("specializations", selectedOption);
                  }}
                  options={specializationsOptions}
                  isMulti
                  className="react-select"
                  placeholder={strings.vet_specialization}
                  noOptionsMessage={() => strings.no_specialization}
                />
              </fieldset>
              <Select
                defaultValue={formData.places[0]}
                name="place"
                placeholder={strings.place_name_label}
                label={strings.place_name_label}
                options={placesOptions}
                onInputChange={(selected) =>
                  updateFormData("places", [parseInt(selected)])
                }
              />
            </div>
            <div className="modal-content__submit">
              <SaveButtons
                visible
                onCancelClick={() => ResetModalData()}
                htmlType="submit"
                loading={loading}
                saveText={
                  data?.id
                    ? strings.update_button_label
                    : strings.add_button_label
                }
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default EmployeeModal;

EmployeeModal.propTypes = {
  hideModal: func,
  showStatusModal: func,
  loading: bool,
  isOpen: bool,
  editMode: bool,
  data: shape({
    created_at: string.isRequired,
    displayPhone: string,
    hidden: bool,
    id: number.isRequired,
    place: shape({}),
    title: string,
    updated_at: string.isRequired,
    user: shape({}),
    workingHours: shape({}),
  }),
};
