import React from "react";
import Loader from "react-loader-spinner";
import { bool, string, func } from "prop-types";
import Button from "../../components/Button";

const SaveButtons = ({
  className,
  visible = true,
  onClick,
  onCancelClick,
  loading = false,
  saveText,
  htmlType = "button",
}) => {
  return (
    <div
      className={`save-buttons ${visible ? "active" : ""} ${
        className ? className : ""
      }`}
    >
      <Button
        className="save-buttons__cancel transparent"
        onClick={onCancelClick}
      >
        Anuluj
      </Button>
      <Button
        className="save-buttons__save"
        onClick={onClick}
        htmlType={htmlType}
      >
        {loading ? (
          <Loader type="Oval" color={"#fff"} height={25} width={25} />
        ) : (
          <span>{saveText ? saveText : "Zapisz"}</span>
        )}
      </Button>
    </div>
  );
};

export default SaveButtons;

SaveButtons.propTypes = {
  className: string,
  visible: bool,
  loading: bool,
  onClick: func,
  onCancelClick: func,
  saveText: string,
  htmlType: string,
};
