import { placesApi } from "../api/PlacesApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./PlacesConstants";

export const fetchPlaces = () => ({
  type: types.FETCH_PLACES,
});

export const fetchTypes = () => ({
  type: types.FETCH_TYPES,
});

export const savePlaces = (data) => ({
  type: types.FETCH_PLACES_SUCCESS,
  payload: data,
});

export const saveTypes = (data) => ({
  type: types.FETCH_TYPES_SUCCESS,
  payload: data,
});

export const errorPlaces = (errorMessage) => ({
  type: types.FETCH_PLACES_FAILURE,
  payload: errorMessage,
});

export const errorTypes = (errorMessage) => ({
  type: types.FETCH_PLACES_FAILURE,
  payload: errorMessage,
});

export const saveOnePlace = (data) => ({
  type: types.FETCH_ONE_PLACE_SUCCESS,
  payload: data,
});

export const errorOnePlace = (errorMessage) => ({
  type: types.FETCH_ONE_PLACE_FAILURE,
  payload: errorMessage,
});

export const fetchPlaceEmployees = (data) => ({
  type: types.FETCH_PLACE_EMPLOYEES,
  payload: data,
});

export const savePlaceEmployees = (data) => ({
  type: types.FETCH_PLACE_EMPLOYEES_SUCCESS,
  payload: data,
});

export const errorPlaceEmployees = (errorMessage) => ({
  type: types.FETCH_PLACE_EMPLOYEES_FAILURE,
  payload: errorMessage,
});

export const getPlaces = (
  sort = "created_at:DESC",
  limit = 10,
  city,
  typeId,
  doctorsIds,
  placeSpecId,
  docSpecId,
  id
) => (dispatch) => {
  dispatch(fetchPlaces());
  placesApi
    .getAllPlaces(
      sort,
      limit,
      city,
      typeId,
      doctorsIds,
      placeSpecId,
      docSpecId,
      id
    )
    .then(async (res) => {
      dispatch(savePlaces(res.data));
    })
    .catch((err) => {
      dispatch(errorPlaces(errorParser.parseError(err).message));
    });
};

export const getTypes = (id) => (dispatch) => {
  dispatch(fetchTypes());
  placesApi
    .getTypesOfPalces(id)
    .then(async (res) => {
      dispatch(saveTypes(res.data));
    })
    .catch((err) => {
      dispatch(errorTypes(errorParser.parseError(err).message));
    });
};

export const searchPlaces = () => ({
  type: types.FETCH_PLACES,
});

export const saveSearchResults = (data) => ({
  type: types.FETCH_PLACES_SUCCESS,
  payload: data,
});

export const errorSearchPlaces = (msg) => ({
  type: types.FETCH_PLACES_FAILURE,
  payload: msg,
});

export const search = (city, typeId) => (dispatch) => {
  dispatch(searchPlaces());
  placesApi
    .search(city, typeId)
    .then((res) => {
      dispatch(saveSearchResults(res.data));
    })
    .catch((err) => {
      dispatch(errorSearchPlaces(errorParser.parseError(err).message));
    });
};

export const updatePlaceStart = () => ({
  type: types.UPDATE_PLACE,
});

export const updatePlaceErr = (data) => ({
  type: types.UPDATE_PLACE_FAILURE,
  payload: data,
});

export const updatePlace = (placeId, data) => (dispatch) => {
  dispatch(updatePlaceStart());
  return placesApi
    .updatePlace(placeId, data)
    .then(() => {
      dispatch(
        getPlaces("created_at:DESC", 999, null, null, null, null, null, placeId)
      );
      return true;
    })
    .catch((err) => {
      dispatch(updatePlaceErr(errorParser.parseError(err).message));
      return false;
    });
};

export const getOnePlaceById = (id) => (dispatch) => {
  dispatch(saveOnePlace());
  placesApi
    .getPlaceById(id)
    .then((res) => {
      dispatch(saveOnePlace(res.data));
    })
    .catch((err) => {
      dispatch(errorOnePlace(errorParser.parseError(err).message));
    });
};

export const getPlaceEmployees = (id) => (dispatch) => {
  dispatch(fetchPlaceEmployees());
  placesApi
    .getEmployeesByPlaceId(id)
    .then((res) => {
      dispatch(savePlaceEmployees(res.data));
    })
    .catch((err) => {
      dispatch(errorPlaceEmployees(errorParser.parseError(err).message));
    });
};
