import API from "./ApiConfig";

export const notificationsApi = {
  getEmployeeNotifications,
  updateNotification,
  getNotifications,
};

function getEmployeeNotifications(id, sort) {
  return API.get(`/employees/${id}/notifications`, {
    params: {
      _sort: sort,
    },
  });
}

function updateNotification(employeeId, notificationId, data) {
  return API.put(
    `/employees/${employeeId}/notifications/${notificationId}`,
    data
  );
}

function getNotifications() {
  return API.get("/notifications");
}
