import React, { useState, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoginModalContext } from "../../contexts/modals";
import PropTypes from "prop-types";

import UserRating from "./UserRating";
import Button from "../Button";
import StarRating from "../StarRating";

import { addRating } from "../../actions/RatingActions";
import Icon from "../Icon";
import Loader from "react-loader-spinner";

const RatingsSection = ({
  ratings,
  placeId,
  selectedPlaceId,
  isOpen,
  closeModal,
  checkRatingStatus,
  ratingsLoading,
}) => {
  const dispatch = useDispatch();
  const { setShowLoginModal } = useContext(LoginModalContext);
  const user = useSelector((state) => state.auth.user);

  const [comment, setComment] = useState("");
  const [error, setError] = useState("");
  const [rate, setRate] = useState(5);

  const isLogged = useMemo(() => !!user?.id, [user]);

  const handleClick = () => {
    if (comment.length > 0) {
      dispatch(
        addRating({
          author:
            user.provider === "local"
              ? user.firstName + " " + user.lastName
              : user.email,
          opinion: comment,
          rating: rate,
          place: selectedPlaceId,
        })
      );
      checkRatingStatus();
      setError("");
      setComment("");
    } else {
      setError("Aby dodać opinię pole nie może być puste.");
    }
  };

  return (
    <div className={`ratings-section ${isOpen ? " active" : ""}`}>
      <div className="ratings-section__title">
        <h2>Opinie użytkowników</h2>
        <Button onClick={closeModal} className="transparent padding-0">
          <Icon name="close" color="orange" />
        </Button>
      </div>
      {ratingsLoading ? (
        <div className="ratings-section__loading">
          <Loader
            className="loader"
            type="Oval"
            color="#f66829"
            height={50}
            width={50}
          />
        </div>
      ) : (
        <>
          {ratings?.length > 0 ? (
            ratings.map((rating, index) => (
              <UserRating
                key={index}
                rating={rating}
                ratingsLoading={ratingsLoading}
              />
            ))
          ) : (
            <div className="no-opinions">Brak opinii</div>
          )}
        </>
      )}

      {isLogged ? (
        <>
          <h3>Dodaj opinię o przychodni</h3>
          <textarea
            onChange={(e) => setComment(e.target.value)}
            placeholder={error || "Dodaj opinię"}
            className="textarea"
            value={comment}
            rows="5"
          />
          <div className="row justify-content-around align-items-center">
            <StarRating
              onChange={(value) => setRate(value)}
              value={rate}
              count={5}
              active
            />
            <Button
              className="add-rating-btn thicker"
              onClick={() => handleClick()}
            >
              Dodaj opinię
            </Button>
          </div>
        </>
      ) : (
        <div className="ratings-section__login-wrapper">
          <p>Zaloguj się aby móc dodać opinię o placówce.</p>
          <Button
            className="show-login-modal-btn thicker"
            onClick={() => setShowLoginModal(true)}
          >
            Zaloguj się
          </Button>
        </div>
      )}
    </div>
  );
};

RatingsSection.propTypes = {
  placeId: PropTypes.number.isRequired,
  ratings: PropTypes.array,
  isOpen: PropTypes.bool,
  ratingsLoading: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default RatingsSection;
