import API from "./ApiConfig";

export const doctorsApi = {
  getDoctors,
  getAllDoctors,
  addDoctor,
  editDoctor,
  deleteDoctor,
};

function getDoctors(place, start, end, id) {
  return API.get("/employees", {
    params: { places: 1, "user.role": 3, start, end, id },
  });
}

function addDoctor(data) {
  return API.post("/employees", data);
}

function editDoctor(id, data) {
  return API.put(`/employees/${id}`, data);
}

function deleteDoctor(id) {
  return API.delete(`/employees/${id}`);
}

function getAllDoctors() {
  return API.get("/employees");
}
