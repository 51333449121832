import React from "react";
import { string, number, func, oneOfType, bool, object } from "prop-types";

const InputComponent = ({
  type = "text",
  className,
  value,
  name,
  placeholder,
  onChange,
  pattern,
  required = false,
  minLength = 0,
  autoFocus = false,
  label,
  disabled,
  maxLength,
  defaultValue,
  minDate,
  maxDate,
}) => {
  return (
    <label className="input-component">
      {label && <span className="label">{label}</span>}
      <input
        className={["input", className].join(" ")}
        autoFocus={autoFocus}
        type={type}
        name={name}
        minLength={minLength}
        pattern={pattern}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="on"
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        min={minDate}
        max={maxDate}
      />
    </label>
  );
};

export default InputComponent;
InputComponent.propTypes = {
  type: string,
  className: string,
  value: oneOfType([number, string]),
  name: string,
  placeholder: oneOfType([number, string]),
  onChange: func,
  pattern: string,
  required: bool,
  minLength: number,
  error: string,
  autoComplete: bool,
  disabled: bool,
  maxLength: number,
  minDate: oneOfType([string, object]),
  maxDate: oneOfType([string, object]),
};
