import * as actions from "../actions/EmployeeConstants";

const initialState = {
  employeeResponse: {
    loading: false,
    error: "",
    employee: [],
    isSuccess: false,
  },
  placeEmployees: {
    employees: [],
    error: "",
    loading: false,
  },
};

const employeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CLINIC_EMPLOYEE:
      return {
        ...state,
        employeeResponse: {
          loading: true,
          error: "",
          employee: [],
          isSuccess: false,
        },
      };
    case actions.FETCH_CLINIC_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeResponse: {
          loading: false,
          error: "",
          employee: action.payload,
          isSuccess: false,
        },
      };
    case actions.FETCH_CLINIC_EMPLOYEE_FAILURE:
      return {
        ...state,
        employeeResponse: {
          loading: false,
          error: action.payload,
          employee: [],
          isSuccess: false,
        },
      };
    case actions.ADD_EMPLOYEE:
    case actions.EDIT_EMPLOYEE:
    case actions.DELETE_EMPLOYEE:
      return {
        ...state,
        placeEmployees: {
          ...state.placeEmployees,
          loading: true,
          error: "",
          isSuccess: true,
        },
      };

    case actions.ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        placeEmployees: {
          loading: false,
          error: "",
          employees: action.payload,
        },
      };

    case actions.ADD_EMPLOYEE_FAILURE:
    case actions.EDIT_EMPLOYEE_FAILURE:
    case actions.DELETE_EMPLOYEE_FAILURE:
      return {
        ...state,
        placeEmployees: {
          ...state.placeEmployees,
          loading: false,
          error: action.payload,
          employees: [],
          isSuccess: false,
        },
      };

    case actions.EDIT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        placeEmployees: {
          loading: false,
          error: "",
          employees: [
            ...state.placeEmployees.employees?.filter(
              (employee) => employee.id !== action.payload.id
            ),
            action.payload,
          ],
          isSuccess: true,
        },
      };

    case actions.DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        employeeResponse: {
          loading: false,
          error: "",
          employee: [
            ...state.employeeResponse.employee?.filter(
              (employee) => employee.id !== action.payload.id
            ),
            action.payload,
          ],
        },
      };

    case actions.FETCH_PLACE_EMPLOYEES:
      return {
        ...state,
        placeEmployees: {
          ...state.placeEmployees,
          loading: true,
        },
      };

    case actions.FETCH_PLACE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        placeEmployees: {
          loading: false,
          employees: action.payload,
          error: "",
        },
      };

    case actions.FETCH_PLACE_EMPLOYEES_FAILURE:
      return {
        ...state,
        placeEmployees: {
          employees: "",
          error: action.payload,
          loading: false,
        },
      };

    default:
      return state;
  }
};

export default employeeReducer;
