import { regulationsApi } from "../api/RegulationsApi";
import { errorParser } from "../api/ErrorParser";
import * as type from "./RegulationsConstants";

export const fetchRegulations = () => ({
  type: type.FETCH_REGULATIONS_PAGES,
});

export const saveRegulations = (data) => ({
  type: type.FETCH_REGULATIONS_PAGES_SUCCESS,
  payload: data,
});

export const errorRegulations = (errorMessage) => ({
  type: type.FETCH_REGULATIONS_PAGES_FAILURE,
  payload: errorMessage,
});

export const setTermsAccepted = () => ({
  type: type.TERMS_ACCEPTED,
});

export const getRegulations = () => (dispatch) => {
  dispatch(fetchRegulations());
  regulationsApi
    .getPages()
    .then((res) => {
      dispatch(saveRegulations(res.data));
    })
    .catch((err) => {
      dispatch(errorRegulations(errorParser.parseError(err).message));
    });
};
