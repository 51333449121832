import API from "./ApiConfig";

export const employeeApi = {
  getEmployeeById,
  addEmployee,
  editEmployee,
  deleteEmployee,
  getEmployeeByPlaceId,
};

function getEmployeeById(id) {
  return API.get(`employees/`, {
    params: {
      "user.employee.id": id,
    },
  });
}

function addEmployee(data) {
  return API.post("/employees", data);
}

function editEmployee(id, data) {
  return API.put(`/employees/${id}`, data);
}

function deleteEmployee(id) {
  return API.delete(`/employees/${id}`);
}

function getEmployeeByPlaceId(placeId) {
  return API.get(`employees/`, {
    params: {
      "places.id": placeId,
      canHaveVisits: true,
    },
  });
}
