import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import * as placeActions from "../../actions/PlacesActions";

import SearchWidget from "../../components/SearchWidget";
import Clinic from "../../components/Clinic";
import InstancesMap from "../../components/Map";

const InstitutionPage = () => {
  const dispatch = useDispatch();
  const [visitReserved, setVisitReserved] = useState(false);
  const places = useSelector((state) => state.places);
  const [showDetails, setShowDetails] = useState(false);

  const { city, type } = useParams();

  useEffect(() => {
    dispatch(
      placeActions.getPlaces(
        "created_at:DESC",
        999,
        city === "-" ? "" : city,
        type
      )
    );
    dispatch(placeActions.getTypes());
  }, [dispatch, city, type]);

  const loading = places?.placesLoading;

  return (
    <div className="institution-page">
      <div className="container">
        <h1 className="title">Szukaj placówki</h1>
        <div className="content row d-flex align-items-stretch">
          <div className="left col-12">
            <SearchWidget
              defaultCity={city}
              defaultType={type}
              data={places?.typesList || []}
              loading={loading}
            />
            <h3 className="institution-page__places-count">
              Znaleziono
              <span>
                {` ${places?.placesList?.length || 0} ${
                  places?.placesList?.length === 1
                    ? "miejsce"
                    : places?.placesList?.length >= 2 &&
                      places?.placesList?.length <= 4
                    ? "miejsca"
                    : "miejsc"
                }`}
              </span>
            </h3>
            <div className="clinics-wrapper">
              {places?.placesList?.map((place, index) => (
                <Clinic
                  data={place}
                  key={index}
                  setVisitReserved={() => setVisitReserved(!visitReserved)}
                  setShowDetails={(value) => setShowDetails(value)}
                  showDetails={showDetails}
                />
              ))}
            </div>
          </div>
          <div className="right col-12 align-self-stretch">
            <InstancesMap
              data={places?.placesList}
              showDetails={showDetails}
              setShowDetails={(value) => setShowDetails(value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionPage;
