import * as types from "../actions/SpecializationsConstatns";

const initialState = {
  placeSpecializations: [],
  employeeSpecializations: [],
  loading: false,
  errorMessage: "",
};

const specializationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PLACE_SPECIALIZATIONS:
    case types.FETCH_EMPLOYEE_SPECIALIZATIONS:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_PLACE_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        placeSpecializations: action.payload,
      };
    case types.FETCH_PLACE_SPECIALIZATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case types.FETCH_EMPLOYEE_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeSpecializations: action.payload,
      };
    case types.FETCH_EMPLOYEE_SPECIALIZATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default specializationsReducer;
