import axios from "axios";
import store from "../store/ConfigureStore";
import strings from "../values/Strings";
const API_URL = strings.API_URL;

const API = axios.create({
  baseURL: API_URL,
});

API.interceptors.request.use((config) => {
  const token = store.getState().auth.token || localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : null;
    const message = error.response ? error.response.data.message : null;

    if (status === 401 && message === "Invalid token.") {
      localStorage.removeItem("token");
      delete error.config.headers.Authorization;

      return API.request(error.config).catch((error) => Promise.reject(error));
    }

    return Promise.reject(error);
  }
);

export default API;
