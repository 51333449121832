import React, { useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Button from "../Button";
import CookieAlert from "../../components/CookieAlert/index";
import Icon from "../Icon";
import { LoginModalContext, RegisterModalContext } from "../../contexts/modals";
import { bool, func } from "prop-types";
import routes from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { getConfig } from "../../store/Selectors";
import * as configActions from "../../actions/ConfigActions";

const Footer = ({
  loggedIn,
  isDoctor,
  isReceptionist,
  cookieAlert,
  setCookieAlert,
  displayProducts,
  isMobile,
}) => {
  const { showLoginModal, setShowLoginModal } = useContext(LoginModalContext);
  const { showRegisterModal, setShowRegisterModal } = useContext(
    RegisterModalContext
  );
  const dispatch = useDispatch();
  const config = useSelector(getConfig);

  useEffect(() => {
    dispatch(configActions.getConfigOptions());
  }, [dispatch]);

  const {
    articles,
    products,
    places,
    privacyPolicy,
    regulations,
    contact,
  } = routes;

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <NavLink to="/">
            <Icon name="logo_2" color="footer-logo" />
          </NavLink>

          {!isMobile ? (
            <>
              <div className="footer__column">
                <NavLink to={places} className="footer__link">
                  Szukaj placówki
                </NavLink>
                <NavLink to={articles} className="footer__link">
                  Dobrze wiedzieć
                </NavLink>
                {displayProducts && (
                  <NavLink to={products} className="footer__link">
                    Produkty
                  </NavLink>
                )}
              </div>

              <div className="footer__column">
                <NavLink to={contact} className="footer__link">
                  Kontakt
                </NavLink>
                <NavLink to={privacyPolicy} className="footer__link">
                  Polityka prywatności
                </NavLink>
                <NavLink to={regulations} className="footer__link">
                  Regulamin
                </NavLink>
              </div>

              <div className="footer__column">
                {loggedIn ? (
                  <NavLink
                    to={
                      isDoctor || isReceptionist
                        ? "/panel-weterynarza/wizyty"
                        : "/panel-uzytkownika/"
                    }
                    className="footer__link"
                  >
                    <Icon name="user_icon" color="orange" />
                    Panel Użytkownika
                  </NavLink>
                ) : (
                  <>
                    <Button
                      className={`transparent ${
                        showRegisterModal ? "active" : ""
                      }`}
                      onClick={() => setShowRegisterModal(true)}
                    >
                      Rejestracja
                    </Button>
                    <Button
                      className={`transparent ${
                        showLoginModal ? "active" : ""
                      }`}
                      onClick={() => setShowLoginModal(true)}
                    >
                      Logowanie
                    </Button>
                  </>
                )}
              </div>

              <Button
                className="back-to-top-btn"
                onClick={() => window.scroll({ top: 0, behavior: "smooth" })}
              >
                <Icon name="arrow_up" color="arrow_up" />
              </Button>
            </>
          ) : (
            <>
              <div className="footer__mobile-text">
                <span>Kontakt: </span>
                <a
                  className="footer__contact-link"
                  href={`mailto:${config?.data?.contactEmail}`}
                >
                  {config?.data?.contactEmail}
                </a>
              </div>
              <div>© 2021 Pupilevet. All rights reserved!</div>
            </>
          )}
        </div>
      </div>
      {!cookieAlert && <CookieAlert setCookieAlert={setCookieAlert} />}
    </footer>
  );
};

export default Footer;
Footer.propTypes = {
  loggedIn: bool,
  isDoctor: bool,
  isReceptionist: bool,
  cookieAlert: bool,
  setCookieAlert: func,
  displayProducts: bool,
  isMobile: bool,
};
