import React, { useEffect, useState, useContext } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import routes from "../../routes";

import Banner from "../../components/Banner";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import ArticlesSection from "../../components/ArticlesSection/ArticlesSection";
import RegisterSection from "../../components/RegisterSection";
import PlacesSection from "../../components/PlacesSection";
import RequestStatusModal from "../../components/Modals/RequestStatusModal";

import { LoginModalContext } from "../../contexts/modals";

import { loginWithProvider } from "../../actions/UserActions";
import PartnersSection from "../../components/PartnersSection/PartnersSection";
import { bool } from "prop-types";

const HomePage = ({ displayProducts, registeredModal }) => {
  const [showModal, setShowModal] = useState(false);

  const { confirmEmail } = routes;

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);

  const location = useLocation();
  const params = useParams();
  const history = useHistory();

  const { setShowLoginModal, showLoginModal } = useContext(LoginModalContext);

  useEffect(() => {
    if (params.providerName) {
      dispatch(loginWithProvider(params.providerName, location.search));
    }
  }, [history, location.search, params.providerName, dispatch]);

  useEffect(() => {
    if (
      location.pathname === confirmEmail &&
      !auth?.user?.id &&
      !showLoginModal &&
      !registeredModal
    ) {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [auth, location, confirmEmail, showLoginModal, registeredModal]);

  return (
    <div className="container">
      <Banner />
      <PlacesSection />
      {displayProducts && <ProductsSection />}
      <ArticlesSection />
      <PartnersSection />
      {!auth?.user?.id && <RegisterSection />}
      <RequestStatusModal
        isOpen={showModal}
        close={() => setShowModal(false)}
        successComponent={
          <div className="email-confirm">
            <h2 className="email-confirm__title">
              Adres e-mail został potwierdzony!
            </h2>
            <p className="email-confirm__description">
              <button
                className="button link"
                onClick={() => {
                  setShowModal(false);
                  setShowLoginModal(true);
                }}
              >
                Zaloguj się
              </button>
              {` na swoje konto.`}
            </p>
          </div>
        }
      />
    </div>
  );
};

export default HomePage;

HomePage.propTypes = {
  displayProducts: bool,
  registeredModal: bool,
};
