import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button";
import Input from "../Input";
import Icon from "../Icon";
import { bool, array } from "prop-types";
import * as placesActions from "../../actions/PlacesActions";
import { useDispatch } from "react-redux";

const SearchWidget = ({ data, loading, bannerSearch }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [queryData, setQueryData] = useState({ city: "", type: "" });

  function search(city, t) {
    dispatch(placesActions.search(city, t));
  }

  const queryHandler = (val) => {
    if (val.length >= 1) {
      setQueryData({ ...queryData, city: val });
      if (!bannerSearch) {
        search(val, queryData.type);
      }
    } else if (val.length === 0) {
      setQueryData({ ...queryData, city: "" });
      if (!bannerSearch) {
        search(val, queryData.type);
      }
    } else {
      setQueryData({ ...queryData, city: "" });
    }
  };

  const changeTypeCallbackHandler = (val) => {
    setQueryData({ ...queryData, type: val || "" });

    if (!bannerSearch) {
      search(queryData.city, val);
    }
  };

  const onSubmitSearch = () => {
    if (bannerSearch) {
      history.push({
        pathname: `/placowki/${queryData.city || "-"}/${queryData.type}`,
        search: "",
      });
    }
    search(queryData.city, queryData.type);
  };

  return (
    <form className="search-widget">
      <Input
        placeholder="Szukaj..."
        className="input"
        defaultValue={queryData.city}
        onChange={(e) => queryHandler(e.target.value)}
      />
      <span className="search-widget__arrow">
        <Icon name="arrow_down" color="greyish" />
        <select
          value={queryData.type}
          className="select"
          onChange={(e) => changeTypeCallbackHandler(e.target.value)}
        >
          <option value="">Wszystkie</option>;
          {data?.map((type) => {
            return (
              <option key={type.id} value={type.id}>
                {type.name}
              </option>
            );
          })}
        </select>
      </span>
      <Button
        loading={loading}
        className="small thicker search-widget__submit"
        onClick={() => onSubmitSearch()}
      >
        Szukaj
      </Button>
    </form>
  );
};

export default SearchWidget;

SearchWidget.propTypes = {
  loading: bool,
  data: array.isRequired,
  bannerSearch: bool,
};
