import React from "react";

import { Link } from "react-router-dom";

import {
  getUrlToImage,
  formatPrice,
  formatPromotion,
  formatLongText,
} from "../../utils/utils";
import strings from "../../values/Strings";

// import Icon from "../Icon";

import { shape, string } from "prop-types";

const ProductItem = ({ data, col }) => {
  const { id, desc, cover, promotion, price, name } = data;

  const imgSrc = cover?.url && getUrlToImage(cover.url);

  return (
    <div className={`col-${col} product-item__wrapper`}>
      <div className="product-item">
        {/*{url && (*/}
        {/*<a*/}
        {/*className="product product-item__link"*/}
        {/*href={url}*/}
        {/*rel="noopener noreferrer"*/}
        {/*target="__blank"*/}
        {/*>*/}
        {/*<Icon name="redirect"/>*/}
        {/*</a>*/}
        {/*)}*/}
        <Link to={`/produkt/${id}`} className="product">
          {imgSrc && (
            <div className="product__image">
              <img src={imgSrc} alt="lorem" />
            </div>
          )}
          <div className="product__content">
            <div>
              <h4>{name}</h4>
              <p className="product-item-description">
                {formatLongText(desc, 50)}
              </p>
            </div>
            <p className="price">
              {data.promotion > 0 && <span>{formatPromotion(promotion)}</span>}{" "}
              {formatPrice(price)} {strings.currency}
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProductItem;
ProductItem.propTypes = {
  data: shape({}),
  col: string,
};
