import React, { useEffect } from "react";
import { getJWTToken } from "./store/SecureStorage";
import * as userActions from "./actions/UserActions";

import { HashRouter } from "react-router-dom";

import { Provider } from "react-redux";
import store from "./store/ConfigureStore";

import ContextWrapper from "./contexts";

import Main from "./components/Main/Main";
import MainMobile from "./components/MainMobile/MainMobile";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { isMobile } from "react-device-detect";

const App = () => {
  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = await getJWTToken();

      if (!!token) {
        store.dispatch(userActions.updateAuthState(token));
        store.dispatch(userActions.getUserProfile());
      }
    };

    checkLoginStatus();
  }, []);

  return (
    <Provider store={store}>
      <HashRouter>
        <ContextWrapper>{!isMobile ? <Main /> : <MainMobile />}</ContextWrapper>
      </HashRouter>
    </Provider>
  );
};

export default App;
