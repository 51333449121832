import * as type from "./WorkTimesConstants";
import { workTimesApi } from "../api/WorkTimesApi";
import { errorParser } from "../api/ErrorParser";

export const addWorkingTimes = () => ({
  type: type.ADD_WORKING_TIMES,
});

export const addWorkingTimesSuccess = (data) => ({
  type: type.ADD_WORKING_TIMES_SUCCESS,
  payload: data,
});

export const addWorkingTimesFailure = (data) => ({
  type: type.ADD_WORKING_TIMES_FAILURE,
  payload: data,
});

export const addEmployeeWorkingTimes = (data) => (dispatch) => {
  dispatch(addWorkingTimes());
  return workTimesApi
    .addWorkingTimes(data)
    .then((res) => {
      dispatch(addWorkingTimesSuccess(res.data));
    })
    .catch((err) => {
      dispatch(addWorkingTimesFailure(errorParser.parseError(err).message));
    });
};

export const updateWorkingDayTimeStart = () => ({
  type: type.UPDATE_WORKING_DAY_TIMES,
});

export const updateWorkingDayTimeSuccess = (data) => ({
  type: type.UPDATE_WORKING_DAY_TIMES_SUCCESS,
  payload: data,
});

export const updateWorkingDayTimeError = (error) => ({
  type: type.UPDATE_WORKING_DAY_TIMES_FAILURE,
  payload: error,
});

export const updateWorkingDayTime = (timeId, data, employeeId) => (
  dispatch
) => {
  dispatch(updateWorkingDayTimeStart());
  return workTimesApi
    .updateWorkingDayTime(timeId, data)
    .then((res) => {
      dispatch(updateWorkingDayTimeSuccess(res.data));
      dispatch(getEmployeeWorkingTime(employeeId));
      return true;
    })
    .catch((err) => {
      dispatch(updateWorkingDayTimeError(errorParser.parseError(err).message));
      return false;
    });
};

export const deleteWorkingDayTimeStart = () => ({
  type: type.DELETE_WORKING_DAY_TIMES,
});

export const deleteWorkingDayTimeSuccess = (data) => ({
  type: type.DELETE_WORKING_DAY_TIMES_SUCCESS,
  payload: data,
});

export const deleteWorkingDayTimeError = (error) => ({
  type: type.DELETE_WORKING_DAY_TIMES_FAILURE,
  payload: error,
});

export const deleteWorkingDayTime = (timeId, employeeId) => (dispatch) => {
  dispatch(deleteWorkingDayTimeStart());
  return workTimesApi
    .deleteWorkingDayTime(timeId)
    .then((res) => {
      dispatch(deleteWorkingDayTimeSuccess(res.data));
      dispatch(getEmployeeWorkingTime(employeeId));
    })
    .catch((err) => {
      dispatch(deleteWorkingDayTimeError(errorParser.parseError(err).message));
    });
};

export const fetchWorkingDayTimeStart = () => ({
  type: type.FETCH_WORKING_TIMES,
});

export const fetchWorkingDayTimeSuccess = (data) => ({
  type: type.FETCH_WORKING_TIMES_SUCCESS,
  payload: data,
});

export const fetchWorkingDayTimeError = (error) => ({
  type: type.FETCH_WORKING_TIMES_FAILURE,
  payload: error,
});

export const getEmployeeWorkingTime = (employeeId) => (dispatch) => {
  dispatch(fetchWorkingDayTimeStart());
  return workTimesApi
    .getWorkingTimes(employeeId)
    .then((res) => {
      dispatch(fetchWorkingDayTimeSuccess(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(fetchWorkingDayTimeError(errorParser.parseError(err).message));
    });
};
