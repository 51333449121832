import React, { useState, useContext, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import * as userActions from "../../actions/UserActions";

import { validateEmail } from "../../utils/utils";
import { strings } from "../../values/Strings";

import Modal from "react-modal";
import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";

import {
  LoginModalContext,
  RemindPasswordContext,
  RegisterModalContext,
} from "../../contexts/modals";
import { bool } from "prop-types";
import usePreventScroll from "../../hooks/preventScroll";
import SocialMediaButtons from "../SocialMediaButtons";

const LoginModal = ({ isOpen }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { setShowLoginModal } = useContext(LoginModalContext);
  const { setShowRegisterModal } = useContext(RegisterModalContext);
  const { setShowRemindPasswordModal } = useContext(RemindPasswordContext);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState("");

  const onLoginHandler = (e) => {
    e.preventDefault();

    if (state.email.length === 0 || state.password.length === 0) {
      setError(strings.error_fields_empty);
      return;
    }
    if (!validateEmail(state.email)) {
      setError(strings.error_email_validation);
      return;
    }
    dispatch(userActions.login(state.email, state.password));
  };

  const remindPasswordHandler = () => {
    setShowRemindPasswordModal(true);
  };

  const handleClick = () => {
    setShowLoginModal(false);
    setShowRegisterModal(true);
  };

  useEffect(() => {
    auth.token && setShowLoginModal(false);
  }, [dispatch, auth, setShowLoginModal]);

  useEffect(() => {
    auth.errorMessage && setError(strings.error_fields_validation);
  }, [auth]);

  useEffect(() => {
    !isOpen && setError("");
  }, [isOpen]);

  useEffect(() => {
    dispatch(userActions.clearErrorMessage());
    setError("");
  }, [dispatch]);

  usePreventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      className="modal modal--auth login-modal"
      overlayClassName="modal-overlay modal-overlay--top"
      ariaHideApp={false}
      onRequestClose={() => setShowLoginModal(false)}
      closeTimeoutMS={500}
    >
      <div className="modal-Header">
        <h2>Zaloguj się w serwisie Pupile</h2>

        <Button
          onClick={() => setShowLoginModal(false)}
          className="transparent padding-0"
        >
          <Icon name="close" color="white" />
        </Button>
      </div>

      <div className="modal-content">
        <p>Miło Cię ponownie widzieć</p>

        <form className="modal-form" onSubmit={onLoginHandler}>
          <Input
            onChange={(e) => setState({ ...state, email: e.target.value })}
            type="email"
            placeholder="E-mail"
            label="E-mail"
            name="email"
            autoFocus={true}
          />
          <Input
            onChange={(e) => setState({ ...state, password: e.target.value })}
            type="password"
            placeholder="Hasło"
            label="Hasło"
            name="password"
          />
          {error && <span className="modal-form__error">{error}</span>}
          <Button
            type="button"
            className="transparent padding-0"
            onClick={remindPasswordHandler}
          >
            Przypomnij hasło
          </Button>
          <br />
          <Button htmlType="submit" className="thicker" loading={auth.loading}>
            Zaloguj
          </Button>
        </form>

        <span>lub</span>

        <SocialMediaButtons />

        <div className="modal-content__no-accout">
          <span className="modal-content__no-accout__description">
            Nie masz konta?
          </span>
          <Button className="register-btn thicker" onClick={handleClick}>
            Zarejestruj się
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;

LoginModal.propTypes = {
  isOpen: bool,
};
