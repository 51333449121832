import * as types from "../actions/ServicesConstatns";

const initialState = {
  data: [],
  loading: false,
  errorMessage: "",
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SERVICES:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.FETCH_SERVICES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default servicesReducer;
