import { partnersApi } from "../api/PartnersApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./PartnersConstants";

export const fetchPartners = () => ({
  type: types.FETCH_PARTNERS,
});

export const savePartners = (data) => ({
  type: types.FETCH_PARTNERS_SUCCESS,
  payload: data,
});

export const errorPartners = (errorMessage) => ({
  type: types.FETCH_PARTNERS_FAILURE,
  payload: errorMessage,
});

export const getPartners = () => (dispatch) => {
  dispatch(fetchPartners());
  partnersApi
    .getPartnersApi()
    .then(async (res) => {
      dispatch(savePartners(res.data));
    })
    .catch((err) => {
      dispatch(errorPartners(errorParser.parseError(err).message));
    });
};
