import * as type from "./EmployeeConstants";
import { employeeApi } from "../api/EmployeeApi";
import { errorParser } from "../api/ErrorParser";

export const fetchEmployee = () => ({
  type: type.FETCH_CLINIC_EMPLOYEE,
});

export const saveEmployee = (data) => ({
  type: type.FETCH_CLINIC_EMPLOYEE_SUCCESS,
  payload: data,
});

export const errorEmployee = (data) => ({
  type: type.FETCH_CLINIC_EMPLOYEE_FAILURE,
  payload: data,
});

export const createEmployee = () => ({
  type: type.ADD_EMPLOYEE,
});

export const createEmployeeSuccess = (data) => ({
  type: type.ADD_EMPLOYEE_SUCCESS,
  payload: data,
});

export const createEmployeeFailure = (data) => ({
  type: type.ADD_EMPLOYEE_FAILURE,
  payload: data,
});

export const getEmployee = (id) => (dispatch) => {
  dispatch(fetchEmployee());
  employeeApi
    .getEmployeeById(id)
    .then((res) => {
      dispatch(saveEmployee(res.data));
    })
    .catch((err) => {
      dispatch(errorEmployee(errorParser.parseError(err).message));
    });
};

export const addEmployee = (data) => (dispatch) => {
  dispatch(createEmployee());
  employeeApi
    .addEmployee(data)
    .then((res) => {
      dispatch(createEmployeeSuccess(res.data));
    })
    .catch((err) => {
      dispatch(createEmployeeFailure(errorParser.parseError(err).message));
    });
};

export const updateEmployee = () => ({
  type: type.EDIT_EMPLOYEE,
});

export const updateEmployeeSuccess = (data) => ({
  type: type.EDIT_EMPLOYEE_SUCCESS,
  payload: data,
});

export const updateEmployeeFailure = (data) => ({
  type: type.EDIT_EMPLOYEE_FAILURE,
  payload: data,
});

export const editEmployee = (id, data) => (dispatch) => {
  dispatch(updateEmployee());

  return employeeApi
    .editEmployee(id, data)
    .then((res) => {
      dispatch(updateEmployeeSuccess(res.data));
      return true;
    })
    .catch((err) => {
      dispatch(updateEmployeeFailure(errorParser.parseError(err).message));
      return false;
    });
};

export const deleteEmployee = () => ({
  type: type.DELETE_EMPLOYEE,
});

export const deleteEmployeeSuccess = (data) => ({
  type: type.DELETE_EMPLOYEE_SUCCESS,
  payload: data,
});

export const deleteEmployeeFailure = (data) => ({
  type: type.DELETE_EMPLOYEE_FAILURE,
  payload: data,
});

export const removeEmployee = (id) => (dispatch) => {
  dispatch(deleteEmployee());
  employeeApi
    .deleteEmployee(id)
    .then((res) => {
      dispatch(deleteEmployeeSuccess(res.data));
    })
    .catch((err) => {
      dispatch(deleteEmployeeFailure(errorParser.parseError(err).message));
    });
};

export const fetchPlaceEmployees = (data) => ({
  type: type.FETCH_PLACE_EMPLOYEES,
  payload: data,
});

export const savePlaceEmployees = (data) => ({
  type: type.FETCH_PLACE_EMPLOYEES_SUCCESS,
  payload: data,
});

export const errorPlaceEmployees = (errorMessage) => ({
  type: type.FETCH_PLACE_EMPLOYEES_FAILURE,
  payload: errorMessage,
});

export const getEmployeeByPlaceId = (id) => (dispatch) => {
  dispatch(fetchPlaceEmployees());
  employeeApi
    .getEmployeeByPlaceId(id)
    .then((res) => {
      dispatch(savePlaceEmployees(res.data));
    })
    .catch((err) => {
      dispatch(errorPlaceEmployees(errorParser.parseError(err).message));
    });
};
