import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { cancelVisit } from "../../actions/VisitsActions";

import Button from "../Button";
import Icon from "../Icon";
import { Link } from "react-router-dom";

import strings from "../../values/Strings";

import ConfirmModal from "../Modals/ConfirmModal";

import { firstUpperCase, getAvatar } from "../../utils/utils";

import moment from "moment";

import { shape, bool, string, number } from "prop-types";

const VisitItem = ({ data, archival }) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const visits = useSelector((state) => state.visits);

  const { start, pet, desc, id, place, externalVisitData, comment } = data;

  const date =
    start &&
    firstUpperCase(
      `${moment(start).format(strings.full_date_long)},
    ${moment(start).format(strings.hours)}`
    );

  const avatar = getAvatar(pet);

  return (
    <div className={`visit-item ${archival ? "visit-item--archival" : ""}`}>
      <div className="row">
        <div className="col-16">
          <div className="visit-item__content">
            {date && <h4>{date}</h4>}
            <div className="visit-item__content__clinic">
              <p>
                {externalVisitData
                  ? externalVisitData?.placeName
                  : place?.name || "Brak nazwy placówki"}
              </p>
              <div>
                <address>
                  {`ul. ${
                    externalVisitData
                      ? externalVisitData?.placeStreet
                      : place?.address_street || "Brak danych"
                  }`}
                  <br />
                  {`${
                    externalVisitData
                      ? externalVisitData?.placeZip
                      : place?.address_zip || "Brak kodu pocztowego"
                  }
                  ${
                    externalVisitData
                      ? externalVisitData?.placeCity
                      : place?.address_city || "Brak miasta"
                  }`}
                </address>

                {place?.id && (
                  <Link to={`/placowki?place=${place?.id}`}>
                    Pokaż na mapie
                  </Link>
                )}
              </div>
            </div>

            <div className="visit-item__pet">
              <img src={avatar} alt={pet?.name || "pupil"} />
              <div>
                <h3>{pet?.name}</h3>
              </div>
            </div>

            {desc && (
              <div className="visit-item__comment">
                <h3>Opis wizyty:</h3>
                <p>{desc}</p>
              </div>
            )}

            {archival && comment && (
              <div className="visit-item__comment">
                <h3>Opinia lekarza weterynarii: </h3>
                <p>{comment}</p>
              </div>
            )}
          </div>
        </div>

        <ConfirmModal
          isOpen={showModal}
          loading={visits?.loading}
          error={visits?.error}
          hideModal={() => setShowModal(false)}
          onSubmit={() => dispatch(cancelVisit(data.id))}
          question={"Na pewno chcesz odwołać wizytę?"}
        />

        <div className="col-8">
          <div className="visit-item__contact">
            {(place?.contact_phone ||
              (externalVisitData && externalVisitData?.doctorPhone)) && (
              <a
                href={`tel:${
                  externalVisitData
                    ? externalVisitData?.doctorPhone
                    : place?.contact_phone
                }`}
              >
                <Icon name="phone" color="orange" />{" "}
                {externalVisitData
                  ? externalVisitData?.doctorPhone
                  : place?.contact_phone}
              </a>
            )}
            {place?.contact_email && (
              <a href={`mailto:${place?.contact_email}`}>
                <Icon name="envelope" color="orange" />{" "}
                {place?.contact_email
                  ? "Napisz email do kliniki"
                  : "Brak maila"}
              </a>
            )}
            {archival && (
              <Link to={`/placowki?place=${place?.id}&visit=${id}`}>
                <Icon name="calendar" color="orange" />
                Umów kolejną wizytę
              </Link>
            )}
            {!archival && (
              <>
                <Link to={`/placowki?place=${place?.id}&visit=${id}`}>
                  <Icon name="calendar" color="orange" />
                  Przełóż wizytę
                </Link>
                <Button
                  onClick={() => setShowModal(true)}
                  icon="close"
                  iconColor="red"
                  className="transparent"
                >
                  Odwołaj wizytę
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitItem;
VisitItem.propTypes = {
  data: shape({
    created_at: string.isRequired,
    desc: string,
    doctor: shape({}),
    end: string,
    id: number,
    pet: shape({}),
    start: string,
    updated_at: string.isRequired,
  }).isRequired,
  archival: bool,
};
