import React, { useEffect } from "react";
import Slider from "react-slick";
import { settings } from "../../values/SlidersSettings";
import PartnersItem from "../PartnersItem/PartnersItem";
import { useDispatch, useSelector } from "react-redux";
import * as partnersActions from "../../actions/PartnersActions";
import { getPartners } from "../../store/Selectors";

const PartnersSection = () => {
  const dispatch = useDispatch();
  const partners = useSelector(getPartners);

  useEffect(() => {
    dispatch(partnersActions.getPartners());
  }, [dispatch]);

  const sSettings = {
    ...settings,
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: partners.data.length > 4,
  };

  return (
    <div className="section section--partners">
      {partners?.data?.length > 0 && (
        <>
          <header>
            <h1 className="title">Nasi Partnerzy</h1>
          </header>
          <Slider {...sSettings}>
            {partners?.data?.map((partner, index) => (
              <PartnersItem key={index} partner={partner} />
            ))}
          </Slider>
        </>
      )}
    </div>
  );
};

export default PartnersSection;
