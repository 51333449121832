import React from "react";
import BadgeApple from "../../assets/image/badge-apple.svg";
import BadgeGoogle from "../../assets/image/badge-google.svg";
import strings from "../../values/Strings";

const MobileApps = () => (
  <div className="mobile-apps">
    <div className="mobile-apps__image-wrapper">
      <a
        href={strings.APPLE_STORE_URL}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <img src={BadgeApple} alt="Pupile App Store" />
      </a>
    </div>
    <div className="mobile-apps__image-wrapper">
      <a
        href={strings.GOOGLE_PLAY_URL}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        <img src={BadgeGoogle} alt="Pupile Google Play" />
      </a>
    </div>
  </div>
);

export default MobileApps;
