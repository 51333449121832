import React from "react";
import imgOne from "../../assets/image/mobile-landing/mobile-landing-1.png";
import imgTwo from "../../assets/image/mobile-landing/mobile-landing-2.png";
import imgThree from "../../assets/image/mobile-landing/mobile-landing-3.png";
import logoWhite from "../../assets/image/logo_pupile_white.png";
import MobileApps from "../../components/MobileApps/MobileApps";
import strings from "../../values/Strings";

const DownloadAppPage = () => {
  return (
    <>
      <header className="landing-app-header">
        <img
          src={logoWhite}
          alt={strings.downloadAppImagesAlt.logo}
          className="landing-app__image"
        />
      </header>
      <section className="landing-app">
        <div className="landing-app__main">
          <div className="landing-app__content landing-app--center landing-app__content--column">
            <h2 className="landing-app__content__title">
              {strings.downloadAppTitle}
            </h2>
            <div className="landing-app__content__description">
              {strings.downloadAppTitleDescription}
            </div>
            <MobileApps />
          </div>
          <div className="landing-app__banner" />
        </div>
        <div className="landing-app__benefits">
          <h1>{strings.downloadAppBenefitsTitle}</h1>
        </div>
        <div className="landing-app__content">
          <div className="landing-app__content__wrapper">
            <h2 className="landing-app__content__title landing-app__content__title--separator">
              {strings.downloadAppBenefits.benefitOneTitle}
            </h2>
            <div className="landing-app__content__description">
              {strings.downloadAppBenefits.benefitOneDescriptionFirst}
              <br />
              {strings.downloadAppBenefits.benefitOneDescriptionSecond}
              <br />
              {strings.downloadAppBenefits.benefitOneDescriptionThird}
            </div>
          </div>
          <div className="landing-app__content__wrapper">
            <img
              src={imgOne}
              alt={strings.downloadAppBenefits.benefitOneTitle}
              className="landing-app__image"
            />
          </div>
        </div>
        <div className="landing-app__content">
          <div className="landing-app__content__wrapper landing-app__content__wrapper--reverse">
            <h2 className="landing-app__content__title landing-app__content__title--separator">
              {strings.downloadAppBenefits.benefitTwoTitle}
            </h2>
            <div className="landing-app__content__description">
              {strings.downloadAppBenefits.benefitTwoDescription}
            </div>
          </div>
          <div className="landing-app__content__wrapper">
            <img
              src={imgTwo}
              alt={strings.downloadAppBenefits.benefitTwoTitle}
              className="landing-app__image"
            />
          </div>
        </div>
        <div className="landing-app__content">
          <div className="landing-app__content__wrapper">
            <h2 className="landing-app__content__title landing-app__content__title--separator">
              {strings.downloadAppBenefits.benefitThreeTitle}
            </h2>
            <div className="landing-app__content__description">
              {strings.downloadAppBenefits.benefitThreeDescription}
            </div>
          </div>
          <div className="landing-app__content__wrapper">
            <img
              src={imgThree}
              alt={strings.downloadAppBenefits.benefitThreeTitle}
              className="landing-app__image"
            />
          </div>
        </div>
        <div className="landing-app__content landing-app--center landing-app__content--column">
          <h2 className="landing-app__content__title">
            {strings.downloadAppBottomTitle}
          </h2>
          <div className="landing-app__content__description">
            {strings.downloadAppBottomDescription}
          </div>
          <MobileApps />
        </div>
      </section>
    </>
  );
};

export default DownloadAppPage;

DownloadAppPage.propTypes = {};
