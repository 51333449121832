import React from "react";
import SaveButtons from "../SaveButtons";
import { string, bool, func } from "prop-types";

const AdminTitle = ({
  title,
  description,
  withButtons,
  onCancelClick,
  onClick,
  buttonVisible,
}) => {
  return (
    <div className="head">
      <div>
        <h1 className="title">{title}</h1>
        {description && <p>{description}</p>}
      </div>
      {withButtons && (
        <SaveButtons
          visible={buttonVisible}
          onCancelClick={onCancelClick}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default AdminTitle;

AdminTitle.propTypes = {
  title: string.isRequired,
  description: string,
  withButtons: bool,
  buttonVisible: bool,
  onCancelClick: func,
  onClick: func,
};
