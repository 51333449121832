import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../Button";

const HistoryItem = ({ date, description, summary, visitDetails }) => {
  const [showVisitDetails, setShowVisitDetails] = useState(false);

  return (
    <div className="history__item">
      <div className="history__item__top">
        <div className="history__item__top__date">
          <strong>{date}</strong>
          <Button
            onClick={() => setShowVisitDetails(!showVisitDetails)}
            className="transparent padding-0"
          >
            {`${showVisitDetails ? "Ukryj " : "Pokaż "} szczegóły`}
          </Button>
        </div>
        <div className="history__item__top__description">{description}</div>
      </div>

      <div className="history__item__bottom">
        <div className="history__item__bottom__review">
          <strong>Opinia weterynarza</strong>
        </div>
        <div className="history__item__bottom__review__description">
          {summary}
        </div>
      </div>
      {showVisitDetails && (
        <div className="history__item__bottom">
          {visitDetails?.doctor?.place?.name && (
            <div>
              <div className="history__item__bottom__review">
                <strong>Lekarz:</strong>
              </div>
              <div className="history__item____description">
                {visitDetails?.doctor?.title}{" "}
                {visitDetails?.doctor?.place?.name}
              </div>
            </div>
          )}
          <div>
            <div className="history__item__bottom__review">
              <strong>Data wizyty:</strong>
            </div>
            <div className="history__item____description">{date}</div>
          </div>
          {visitDetails?.doctor?.place && (
            <div>
              <div className="history__item__bottom__review">
                <strong>Placówka:</strong>
              </div>
              <div className="history__item__description">{`ul. ${visitDetails.doctor.place.address_street}`}</div>
              <div className="history__item__description">{`${visitDetails.doctor.place.address_zip}, ${visitDetails.doctor.place.address_city}`}</div>
              <div className="history__item__description">{`nr kontaktowy: ${visitDetails.doctor.place.contact_phone}`}</div>
              <div className="history__item__description">{`adres email: ${visitDetails.doctor.place.contact_email}`}</div>
            </div>
          )}
          {visitDetails.title && (
            <div>
              <div className="history__item__bottom__review">
                <strong>Opis wizyty:</strong>
              </div>
              <div className="history__item____description">
                {visitDetails.title}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

HistoryItem.propTypes = {
  date: PropTypes.string,
  description: PropTypes.string,
  summary: PropTypes.string.isRequired,
  visitDetails: PropTypes.object,
};

HistoryItem.defaultProps = {
  summary: "Brak podsumowania",
};

export default HistoryItem;
