import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Icon from "../Icon";
import Button from "../Button";
import ConfirmModal from "../Modals/ConfirmModal";

import { getAvatar } from "../../utils/utils";
import {
  deletePet,
  getPetHistory,
  getPetHistoryStart,
} from "../../actions/PetsActions";

import AddVisitModal from "../../components/Modals/AddVisitModal";

import PropTypes from "prop-types";

const Pet = ({ data, getPet, openModal, userId }) => {
  const dispatch = useDispatch();
  const aRef = useRef(null);

  const pets = useSelector((state) => state.pets);
  const petHistoryLink = pets?.petHistoryLink;

  const [open, setOpen] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showAddVisitModal, setShowAddVisitModal] = useState(false);

  const {
    id,
    name,
    mikrochipId,
    pet_type,
    sex,
    weight,
    neutered,
    outgoing,
    chipDate,
    personality,
    origin,
    dateOfBirth,
  } = data;

  useEffect(() => {
    petHistoryLink && aRef.current.click();
    petHistoryLink && dispatch(getPetHistoryStart());
  }, [petHistoryLink, dispatch]);

  const handleClick = () => {
    getPet(data);
    openModal();
  };

  const handleConfirm = () => {
    dispatch(deletePet(id, userId));
    setShowConfirmModal(false);
    setOpen(false);
  };

  return (
    <>
      <div className="pet-component">
        <div
          className="pet-component__head row no-gutters"
          onClick={() => setOpen(!open)}
        >
          <img className="pet-img" src={getAvatar(data)} alt="pupil" />
          <div className="basic-data">
            <h3>{name}</h3>
            {pet_type?.name ? <p>{pet_type.name}</p> : null}
            <Icon
              name={
                sex === "female" ? "female_icon" : sex === "male" && "male_icon"
              }
              color="orange"
            />
          </div>
          <div className="actions">
            <Button
              className="transparent"
              onClick={() => dispatch(getPetHistory(id))}
            >
              Pobierz historię wizyt pupila
            </Button>
            <Button
              className="transparent"
              onClick={() => setShowAddVisitModal(true)}
            >
              Dodaj wizytę do historii wizyt
            </Button>
            <a
              className="actions__download"
              ref={aRef}
              rel="noopener noreferrer"
              target="_blank"
              href={`${pets.petHistoryLink}`}
              download
            >
              Historia wizyt
            </a>
          </div>
        </div>

        <div className={`row no-gutters flex-nowrap${open ? " open" : ""}`}>
          <div className="details">
            <div className="chip-pesel">
              <h5>Nr mikroczip</h5>
              <span>{mikrochipId}</span>
            </div>
            <div className="chip-date">
              <h5>Data czipowania</h5> <span>{chipDate || "Brak danych"}</span>
            </div>
            <div className="weight">
              <h5>Waga pupila</h5>
              <span>{`${weight / 100} kg`}</span>
            </div>
            <div className="castration">
              <h5>Wykastrowany</h5>
              <span>{neutered ? "Tak" : "Nie"}</span>
            </div>
            <div className="birthday">
              <h5>Rok urodzenia</h5> <span>{dateOfBirth}</span>
            </div>
            <div className="origin">
              <h5>Pochodzenie</h5> <span>{origin}</span>
            </div>
            <div className="exit">
              <h5>Wychodzący</h5> <span>{outgoing ? "Tak" : "Nie"}</span>
            </div>
            <div className="temper">
              <h5>Usposobienie</h5> <span>{personality || "Brak danych"}</span>
            </div>
            <div className="temper">
              <h5>Płeć</h5>{" "}
              <span>
                <Icon
                  name={
                    sex === "female"
                      ? "female_icon"
                      : sex === "male" && "male_icon"
                  }
                  color="orange"
                />
                {sex === "female" ? "Samica" : sex === "male" && "Samiec"}
              </span>
            </div>
          </div>
          <div className="actions row flex-column no-gutters">
            <Button className="transparent" onClick={handleClick}>
              Edytuj dane
            </Button>
            <Button
              className="transparent greyish"
              onClick={() => setShowConfirmModal(true)}
            >
              Usuń dane
            </Button>
          </div>
        </div>
      </div>
      <ConfirmModal
        question={"Na pewno chcesz usunąć pupila?"}
        hideModal={() => setShowConfirmModal(false)}
        onSubmit={handleConfirm}
        isOpen={showConfirmModal}
      />
      <AddVisitModal
        hide={() => setShowAddVisitModal(false)}
        petId={id}
        isOpen={showAddVisitModal}
      />
    </>
  );
};

Pet.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mikrochipId: PropTypes.string.isRequired,
    sex: PropTypes.string.isRequired,
    weight: PropTypes.number.isRequired,
    neutered: PropTypes.bool.isRequired,
    outgoing: PropTypes.bool.isRequired,
    origin: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    avatar: PropTypes.object,
  }),
  getPet: PropTypes.func,
  openModal: PropTypes.func,
};

export default Pet;
