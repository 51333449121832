import * as type from "./NotificationsConstants";
import { notificationsApi } from "../api/NotificationsApi";
import { errorParser } from "../api/ErrorParser";

export const fetchEmployeeNotifications = () => ({
  type: type.FETCH_EMPLOYEE_NOTIFICATIONS,
});

export const fetchEmployeeNotificationsSuccess = (data) => ({
  type: type.FETCH_EMPLOYEE_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const fetchEmployeeNotificationsFailure = (data) => ({
  type: type.FETCH_EMPLOYEE_NOTIFICATIONS_FAILURE,
  payload: data,
});

export const getEmployeeNotifications = (employeeId, sort) => (dispatch) => {
  dispatch(fetchEmployeeNotifications());
  notificationsApi
    .getEmployeeNotifications(employeeId, sort)
    .then((res) => {
      dispatch(fetchEmployeeNotificationsSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchEmployeeNotificationsFailure(errorParser.parseError(err).message)
      );
    });
};

export const updateEmployeeNotification = () => ({
  type: type.UPDATE_EMPLOYEE_NOTIFICATION,
});

export const updateEmployeeNotificationSuccess = (data) => ({
  type: type.UPDATE_EMPLOYEE_NOTIFICATION_SUCCESS,
  payload: data,
});

export const updateEmployeeNotificationFailure = (data) => ({
  type: type.UPDATE_EMPLOYEE_NOTIFICATION_FAILURE,
  payload: data,
});

export const updateNotification = (employeeId, notificationId, data) => (
  dispatch
) => {
  dispatch(updateEmployeeNotification());
  notificationsApi
    .updateNotification(employeeId, notificationId, data)
    .then((res) => {
      dispatch(updateEmployeeNotificationSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        updateEmployeeNotificationFailure(errorParser.parseError(err).message)
      );
    });
};
