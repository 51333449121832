import API from "./ApiConfig";

export const specializationsApi = {
  getPlaceSpecializations,
  getEmployeeSpecializations,
};

function getPlaceSpecializations() {
  return API.get("/place-specializations");
}

function getEmployeeSpecializations() {
  return API.get("/employee-specializations");
}
