import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import { shape, func, bool } from "prop-types";

import { getUrlToImage, getMapLnk } from "../../utils/utils";

import Modal from "react-modal";
import RatingsSection from "../RatingsSection";
import BookVisitForm from "./BookVisitForm";
import StarRating from "../StarRating";
import Gallery from "../Gallery";
import Button from "../Button";
import Icon from "../Icon";

import { addRatingSuccess, reset } from "../../actions/RatingActions";
import { getOnePlaceById } from "../../actions/PlacesActions";

import moment from "moment";
import usePreventScroll from "../../hooks/preventScroll";

const BookVisit = ({
  data,
  onHideForm,
  isOpen,
  selectedPlaceId,
  visitModalConfirm,
  bookVisitModal,
}) => {
  const dispatch = useDispatch();

  const [showRatings, setShowRatings] = useState(false);
  const ratingsList = useSelector((state) => state.ratings);
  const selectedPlace = useSelector((state) => state.places.onePlace);
  const [ratingAdded, setRatingAdded] = useState(false);

  useEffect(() => {
    dispatch(addRatingSuccess(data.ratings));
    return () => dispatch(reset());
  }, [dispatch, data.ratings]);

  useEffect(() => {
    if (selectedPlaceId) {
      dispatch(getOnePlaceById(selectedPlaceId));
    }
  }, [dispatch, selectedPlaceId, ratingAdded]);

  const {
    name,
    cover,
    address_street,
    address_city,
    address_zip,
    desc,
    logo,
    latitude,
    longitude,
    spec,
    id,
  } = data;

  const {
    fridayFrom,
    fridayTo,
    mondayFrom,
    mondayTo,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    thursdayFrom,
    thursdayTo,
    tuesdayFrom,
    tuesdayTo,
    wednesdayFrom,
    wednesdayTo,
  } = data.openHours;

  const handleCalculateRating = (place) => {
    let rating =
      selectedPlace?.ratings?.length > 0
        ? place?.ratings
            .map((rating) => rating.rating)
            .reduce((prev, next) => prev + next) / place.ratings.length
        : 0;

    return place ? Math.round(rating) : 0;
  };

  usePreventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      className="container book-visit"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
      onRequestClose={onHideForm}
      closeTimeoutMS={500}
    >
      <div className="row">
        <div className="col-14 book-visit__left">
          <div>
            <section className="book-visit__content">
              <div className="book-visit__content__address">
                <h2>{name}</h2>
                <div className="rating-wrapper row no-gutters">
                  <StarRating
                    onChange={() => setShowRatings(!showRatings)}
                    count={5}
                    value={handleCalculateRating(selectedPlace)}
                  />
                  <Button
                    onClick={() => setShowRatings(!showRatings)}
                    className="transparent book-visit__show-ratings"
                  >
                    {`${showRatings ? "Ukryj" : "Pokaż"} opinie`}
                  </Button>
                </div>
                <h5>
                  Specjalizacj{spec?.length > 1 ? "e" : "a"}:{" "}
                  {spec.length > 0
                    ? spec.map((item, i) => (
                        <span key={i}>
                          {item.name}
                          {i !== spec.length - 1 && ", "}
                        </span>
                      ))
                    : "Brak danych"}
                </h5>
                <address>
                  ul. {address_street}
                  <br />
                  {address_zip} {address_city} <br />
                  <a
                    className="link-with-icon"
                    rel="noopener noreferrer"
                    href={getMapLnk(latitude, longitude)}
                    target="_blank"
                  >
                    <span>Nawiguj</span> <Icon name="navigate" />
                  </a>
                </address>
              </div>

              {logo?.url && (
                <picture>
                  <img
                    src={getUrlToImage(logo.url)}
                    alt={logo?.alternativeText || "logo kliniki"}
                  />
                </picture>
              )}
            </section>

            <article className="book-visit__details">
              <h3>Godziny otwarcia:</h3>
              <table className="open-hours-table">
                <thead>
                  <tr>
                    {moment.weekdaysShort(true).map((day, index) => (
                      <th key={index}>{day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <span>{mondayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{mondayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{tuesdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{tuesdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{wednesdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{wednesdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{thursdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{thursdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{fridayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{fridayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{saturdayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{saturdayTo}</span>
                      </div>
                    </td>
                    <td>
                      <div>
                        <span>{sundayFrom}</span>
                        <Icon color="orange" name="arrow_down" />
                        <span>{sundayTo}</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              {desc && (
                <>
                  <h3>Opis placówki:</h3>
                  <p>{desc}</p>
                </>
              )}
            </article>
          </div>

          <section className="book-visit__gallery">
            {cover?.length > 0 && (
              <Gallery data={cover?.map((el) => getUrlToImage(el.url))} />
            )}
          </section>
        </div>

        <div className="col-10 book-visit__right">
          <aside>
            <BookVisitForm
              visitModalConfirm={visitModalConfirm}
              bookVisitModal={bookVisitModal}
            />
            <RatingsSection
              ratings={selectedPlace?.ratings}
              ratingsLoading={ratingsList.loading}
              placeId={id}
              selectedPlaceId={selectedPlaceId}
              isOpen={showRatings}
              closeModal={() => setShowRatings(false)}
              checkRatingStatus={() => setRatingAdded(!ratingAdded)}
            />
          </aside>
        </div>
      </div>
    </Modal>
  );
};

export default BookVisit;

BookVisit.propTypes = {
  data: shape({}),
  onHideForm: func,
  isOpen: bool,
};
