const strings = {
  //Config
  API_URL:
    process.env.REACT_APP_API_URL || "https://pupile-api.stage.etd24.pl/",
  FB_URL:
    process.env.REACT_APP_FB_URL ||
    "https://pupile-api.stage.etd24.pl/connect/facebook",
  GOOGLE_URL:
    process.env.REACT_APP_GOOGLE_URL ||
    "https://pupile-api.stage.etd24.pl/connect/google",
  GOOGLE_PLAY_URL:
    "https://play.google.com/store/apps/details?id=com.escola.pupile",
  APPLE_STORE_URL: "https://apps.apple.com/pl/app/pupile/id1554846682?l=pl",
  currency: "zł",

  //Date format
  full_date: "DD-MM-YYYY",
  full_date_dots__hours: "DD.MM.YYYY HH:mm",
  full_date_2: "YYYY-MM-DD",
  full_date_2_and_hours: "YYYY-MM-DD HH:mm",
  full_date_and_hour: "DD/MM/yyyyTHH:mm",
  full_date_long: "dddd, D MMM yyyy",
  hour__day_name__full_date: "HH:mm dddd DD.MM.YYYY",
  hours: "HH:mm",
  hours_minutes_seconds: "HH:mm:ss",

  //Notifications
  notification_internal_communication_title: "Wewnętrzna komunikacja",
  notification_visits_title: "Nowe wizyty",
  notification_canceled_visits_title: "Anulowane wizyty",
  pet_owner: "Właściciel:",
  pet_name: "Imię zwierzęcia:",
  contact_phone: "Tel kontaktowy: ",
  notification_list_empty: "Brak powiadomień",
  canceled_visits_empty: "Brak anulowanych wizyt",
  no_referrals: "Brak skierowań",

  //Tab name/title
  tab_start: "Start",
  tab_search: "Placówki",
  tab_booking: "Wizyty",
  tab_more: "Więcej",

  //TutorialScreen
  skip_label: "Dalej",
  tutorial_title_1: "Witaj w aplikacji Pupile",
  tutorial_title_2: "Tytuł 2",
  tutorial_title_3: "Tytuł 3",
  tutorial_desc_1:
    "Zadbaj o zdrowie swojego zwierzaka.\n Dzięki Aplikacji w prosty spośób umówisz się \n na wizytę u wybranego spejalisty.",
  tutorial_desc_2: "Opis 2",
  tutorial_desc_3: "Opis 3",

  //WelcomeScreen
  already_have_account: "Mam już konto",
  create_account: "Nowy użytkownik",
  skip_login_label: "Pomiń narazie",

  //RegisterScreen
  registration_title: "Zarejestruj sie w serwisie Pupile",
  registration_desc:
    "Otrzymaj darmowy dostęp do sprawdzonej bazy klinik weterynaryjnych, punktów pielęgnacji oraz miejsc przyjaznych pupilom.",
  name_lastname_label: "Imię i Nazwisko",
  e_mail_label: "E-mail",
  password_label: "Hasło",
  repeat_password_label: "Powtórz hasło",
  aknowledge_regulations_label:
    "Przeczytałem i akceptuje Regulamin serwisu i Polityke prywatności",
  register_button_label: "Zarejestruj",

  //LoginScreen
  login_title: "Zaloguj się w serwisie Pupile",
  login_desc: "Miło Cię ponownie widzieć",
  login_label: "Zaloguj",
  forgot_password_label: "Przypomnij mi hasło",
  dont_have_account_label: "Nie posiadasz konta?",

  //ChangeUserDataScreen
  current_email_label: "Aktualny adres",
  change_email_label: "Chcesz zmienić e-mail przypisany do Twojego konta?",
  change_pass_label: "Chcesz zmienić hasło przypisane do Twojego konta?",
  change_pass_info:
    "Kliknij Wyślij aby na twój adres e-mail została wysłana wiadomość do resetowania hasła",
  change_pass_alert_ms:
    "Na twój adres email została wysłana widomość do resetowania hasła",
  current_password_placeholder: "Bieżące Hasło",
  new_password_placeholder: "Nowe hasło",
  new_password_repeat_placeholder: "Powtórz nowe hasło",
  new_email_placeholder: "Nowy adres e-mail",
  new_email_repeat_placeholder: "Powtórz nowy adres e-mail",
  success_update_user_msg: "Gratulacje!! Zmiana się powiodła.",
  delete_account_warning: "Czy napewno chcesz usunąć konto z serwisu Pupile?",

  //Pets List Screen
  pets_list_title: "Moi pupile",

  //Add Pet Screen
  add_pet_title: "Dodaj dane pupila",
  pet_name_placeholder: "Imię zwierzaka",
  pet_chip_placeholder: "Numer mikrochip",
  pet_type_placeholder: "Gatunek",
  pet_species_placeholder: "Rasa",
  pet_origin_placeholder: "Pochodzenie",
  pet_sex_label: "Płeć",
  pet_sex_female: "Samica",
  pet_sex_male: "Samiec",
  pet_year_birth_label: "Rok urodzenia",
  pet_weight_label: "Waga pupila",
  pet_weight_placeholder: "kg",
  pet_neutrises_label: "Wykastrowany",
  pet_outgoing_label: "Wychodzący",
  pet_cover_label: "Zdjęcie Pupila",
  added_pet_title: "Dodano pupila!",
  pet_add_success: "Pupil został dodany pomyślnie.",
  pet_add_failure: "Nie udało się dodać pupila, spróbuj ponownie później.",

  //PetDetails
  pet_details_title: "Dane pupila",
  pet_name_label: "Imię",

  //Edit pet
  pet_edit_title: "Edytuj dane pupila",
  edit_pet_success_title: "Zmieniono dane pupila",

  //PlaceDetails
  day_0_label: "Poniedziałek",
  day_1_label: "Wtorek",
  day_2_label: "Środa",
  day_3_label: "Czwartek",
  day_4_label: "Piątek",
  day_5_label: "Sobota",
  day_6_label: "Niedziela",
  place_closed_label: "Zamknięte",
  show_route_label: "Pokaż trasę",
  open_hours_label: "Godzin otwarcia",
  scope_of_services_label: "Zakres świadczeń",
  reserve_appointment_label: "Rezerwuj wizytę",
  distance_suffix: "km",

  //SearchPlaces
  search_by_map_label: "Mapa",
  search_by_query_label: "Wyszukaj",
  search_placeholder: "Szukaj...",
  search_label: "Szukaj",
  search_count_label: "Znaleziono ",

  //Articles
  sorting_label: "Sortuj wg",
  sorting_newest: "Najnowsze",

  //updateVisit
  reserve_visit_title: "Rezerwuj wizytę",
  pick_day_label: "Wybierz dzień",
  pick_doctor_label: "Wybierz Specjalistę",
  pick_hour_label: "Dostępne godziny wizyt",
  pick_pet_label: "Który pupil będzie pacjentem",
  visit_desc_label: "Czego dotyczy wizyta",
  visit_desc_placeholder: "(Opcjonalnie napisz kilka słów)",
  reserve_visit_label: "Rezerwuje",
  feature_not_logged_error:
    "Ta opcja jest dostępna dla zalogowanych użytkowników",
  no_pet_title: "Brak Pupila",
  no_pet_msg:
    "Nie dodałeś jeszcze swojego Pupila, przejdź do zakładki Moi Pupile i uzupełnij dane.",
  add_pet_label: "Dodaj Pupila",

  //VisitsList
  visits_oncoming_label: "Nadchodzące",
  visits_archive_label: "Archiwalne",

  //VisitConfirmation:
  visit_confirmation_title: "Wizyta zarezerwowana pomyślnie",
  visit_confirmation_mail:
    "Na Twój adres e-mail wysłaliśmy potwierdzenie rezerwacji.",
  visit_confirmation_info:
    "Podgląd szczegółów znajdziesz także w zakładce Wizyty.",

  //VisitDetails
  hour_prefix: "godz. ",

  //BottomModal
  call_clinic_label: "Zadzwoń do kliniki",
  mail_clinic_label: "Napisz email do kliniki",
  cancel_clinic_label: "Odwołaj wizytę",
  change_clinic_label: "Przełóż wizytę",

  //Errors
  error_field_empty: "Pole nie może być puste",
  error_fields_empty: "Pola nie mogą być puste",
  error_email_validation: "Zły format wpisanego E-maila",
  error_fields_validation: "Niepoprawny login lub hasło.",
  error_agreement: "Muisz zaakceptować regulamin",
  error_pass_not_match: "Hasła się różnią",
  error_pass_validations: "Hasło powinno mieć 6 znaków, dużą literę i cyfrę",
  error_no_records: "Brak wynikow",
  error_fields_same_value: "Pola powinny być takie same",
  error_permission_camera:
    "Aplikacja potrzebuje Twojego pozwolenia na korzystanie z kamery",
  error_permission_photoAlbum:
    "Aplikacja potrzebuje Twojego pozwolenia na korzystanie z albumu",
  error_pet_set_name: "Pupil musi mieć imię",
  error_pet_set_sex: "Pupil musi mieć określoną płeć",
  error_password: "Błędne hasło",
  error_no_email: "Podany adres e-mail nie znajduje się w bazie.",

  Auth_form_error_email_taken: "Podany adres E-mail znajduje się już w bazie.",
  Auth_form_error_email_provide: "Proszę wprowadź email",
  Auth_form_error_username_taken: "Nazwa użytkownika jest zajęta.",
  Auth_form_error_password_provide: "Proszę wprowadź hasło.",
  Auth_form_error_invalid: "Hasło jest niepoprawne.",
  Auth_form_error_confirmed: "Twój mail nie został zatwierdzony.",
  Auth_form_error_blocked:
    "Twoje konto zostało zablokowane przez administratora.",
  Auth_form_error_password_local:
    "This user never set a local password, please login with the provider used during account creation.",
  Auth_form_error_code_provide: "Nieprawidłowy kod.",
  Auth_form_error_password_matching: "Hasła do sieie nie pasują.",
  Auth_form_error_email_format: "Proszę wprowadź email w odpowiednim formacie.",
  Auth_form_error_user_not_exist: "Mail nie istnieje.",
  Auth_advanced_allow_register: "Funkcja rejestracji jest obecnie wyłączona.",
  Auth_form_error_password_format:
    "W Twoim haśle symbol '$' nie może występować więcej niż 3 razy.",
  Auth_form_error_role_notFound: "Odnalezienie domyślnej roli jest niemożliwe.",

  //Titles
  my_pet_label: "Moi pupile",
  products_label: "Produkty",
  my_profile_label: "Moje konto",
  e_mail_adress_label: "Adres email",
  notifications_label: "Powiadomienia",
  location_label: "Lokalizacja",
  logout_label: "Wyloguj się",
  remove_user_profile_label: "Usuń konto",
  warning_label: "Uwaga!",

  //Section Titles
  section_title_good_to_know: "Warto wiedzieć",
  section_title_places: "Przyjazne miejsca pupilom",
  section_title_products: "Polecamy dla pupila",
  section_title_login: "Logowanie",
  section_title_registration: "Rejestracja",
  section_title_user_panel: "Panel Użytkownika",
  where_you_are_label: "Jesteś w ",
  set_location: "Ustaw lokalizacje",

  //Places
  prefix_street: "ul.",
  filter_last_added: "Ost. Dodane",

  //Products
  filter_promotion: "Promocja",

  //Alerts
  alert_location_title: "Dostęp do lokalizacji telefonu",
  alert_loaction_msg:
    "Do poprawnego działania aplikacji jest wymagany dostęp do lokalizacji telefonu",
  ok: "OK",
  cancel: "Anuluj",
  remember: "Zapamiętaj",
  save: "Zapisz",
  send: "Wyślij",
  edit: "Edytuj",
  remove: "Usuń",
  yes: "Tak",
  no: "Nie",
  back: "Powrót",
  close: "Zamknij",
  alert_error: "Coś poszło nie tak",
  loading: "Poczekaj chwilę",
  alert_title: "Uwaga",
  dialog_set_location_title: "Ustaw własną lokacje",
  dialog_set_location_hint: "Wpisz nazwę miasta",
  dialog_set_location_confirmation: "Czy chodziło o: ",
  dialog_set_location_error: "Nie rozpoznano nazwy miasta",

  //WelcomePage
  welcome_title: "Zadbaj o zdrowie pupila",
  welcome_subtitle:
    "Znajdź klinikę weterynaryjną, fryzjera dla zwierzaka lub miejsce przyjazne pupilom",

  //Scheduler Header
  panel_heading: "Panel",
  panel_receptionist: "recepcjonisty",
  panel_doctor: "pracownika",
  panel_visits: "Wizyty",
  panel_plan: "Planowanie",
  panel_employess: "Pracownicy",

  //VetPanelVisits
  visits_heading: "Wizyty",
  visits_description: "Harmonogram zarezerwowanych terminów",
  choose_vet: "Wybierz pracownika",
  vet: "Weterynarz",
  pet: "Zwierzę",
  pet_two: "Pupil",
  no_vet_results: "Brak weterynarzy",
  choose_data: "Wybierz okres",
  choose_screen: "Wybierz widok",
  employee: "Pracownik",

  //VetPanelPlan
  plan_heading: "Planowanie",
  plan_description: "Harmonogram dostępności pracownika",
  search_vet_visits: "Wyszukaj wizyty pracownika",

  //settingsPages
  settings_heading: "Ustawienia panelu recepcjonisty",
  settings_description: "Dane placówki recepcjonisty do zmian",
  settings_user_heading: "Ustawienia konta recepcjonisty",
  settings_user_description: "Dane użytkownika do zmian",
  place_name: "Nazwa placówki",
  place_street: "Ulica",
  place_zip_code: "Kod Pocztowy",
  place_city: "Miasto",
  place_description: "Opis placówki",
  place_phone: "Nr telefonu",
  place_email: "Adres email",
  notifications: "Powiadomienia",
  push_notifications: "push",
  vitis_notifications: "o wizytach",
  prevent_notifications: "o zapobieganiu",
  doctors_notifications: "o lekarzach",
  inside_notifications: "wewnętrzne",
  message_notifications: "o wiadomościach",
  address_label: "Dane adresowe",
  place_label: "Dane placówki",
  place_image: "Zdjęcie placówki",
  working_hours_label: "Godziny pracy",
  select_label: "Wybierz",
  select_place_specialization: "Wybierz specjalizację placówki",
  place_services_label: "Usługi i specjalizacje kliniki",
  no_specialization: "Brak takiej specjalizacji",
  save_data_success: "Dane zapisane poprawnie!",
  services_label: "Usługi",
  available_equipment: "Dostępna aparatura",
  switch_on: "Włącz",
  switch_off: "Wyłącz",

  //Time
  today: "Dzisiaj",
  today_two: "Dziś",
  tomorrow: "Jutro",
  in_two_days: "Pojutsze",
  all_day: "Cały dzień",
  day: "Dzień",
  week: "Tydzień",
  month: "Miesiąc",
  daily: "Codziennie",
  weekly: "Co tydzień",
  monthly: "Co miesiąc",
  yearly: "Co roku",
  from_time: "od",
  to_time: "do",

  //AppointmentForm
  appointment_title: "Opis wizyty",
  repeat_label: "Powtarzanie",
  more_info_label: "Szczegóły",
  repeat_every_label: "Powtarzaj co",
  days_label: "Dzień/Dni",
  end_repeat_label: "Zakończ powtarzanie",
  never: "Nidgy",
  appointment_on_label: "Po",
  appointment_occurrences_label: "Powtórzeniu/Powtórzeniach",
  appointment_after_label: "Data",

  //Employees
  title: "Tytuł",
  employees_title: "Pracownicy",
  add_employee: "Dodaj pracownika",
  add_employee_two: "Dodaj nowego pracownika",
  edit_employee: "Zaktualizuj dane pracownika",
  firstName: "Imię",
  lastName: "Nazwisko",
  licence_label: "Nr licencji weterynarza",
  phone_number: "Telefon",
  phone_number_two: "Numer telefonu",
  email: "E-mail",
  email_two: "Adres e-mail",
  vet_specialization_label: "Wybierz specjalizację weterynarza",
  vet_specialization: "Specjalizacja weterynarza",
  place_name_label: "Placówka",
  update_button_label: "Zaaktualizuj",
  add_button_label: "Dodaj",
  confirm_delete_vet: "Na pewno chcesz usunąć lekarza?",

  //visit reservation
  visit_reserved_success: "Wizyta zarezerwowana pomyślnie!",
  visit_reserved_failure: "Wystąpił błąd podczas rezerwacji wizyty!",
  visit_added_success: "Wizyta dodana pomyślnie!",
  visit_changed_success: "Wizyta zmieniona pomyślnie!",
  visit_deleted_success: "Wizyta usunięta pomyślnie!",
  visit_added_failure: "Wystąpił błąd podczas tworzenia wizyty!",
  visit_changed_failure: "Wystąpił błąd podczas zmiany wizyty!",
  visit_deleted_failure: "Wystąpił błąd podczas usuwania wizyty!",

  //employee opening hours settings
  employee_opening_hours_date_title: "Wybierz datę:",
  employee_opening_hours_time_title: "Ustal przedział czasowy: ",
  opening_hours_info:
    "   Wybierz zakres daty oraz ustal godziny pracy. Po zapisaniu zmian godziny dla zadanego okresu dat wygenerują się automatycznie.",
  open_place_label: "Godziny otwarcia placówki:",
  close_place_label: "Godziny zamknięcia placówki:",
  wh_item_working_time: "Godziny pracy:",

  //cookies
  cookies_text:
    "Nasza strona internetowa używa plików cookies (tzw. ciasteczka) w celach statystycznych, reklamowych oraz funkcjonalnych. Dzięki nim możemy indywidualnie dostosować stronę do twoich potrzeb. Każdy może zaakceptować pliki cookies albo ma możliwość wyłączenia ich w przeglądarce, dzięki czemu nie będą zbierane żadne informacje.",
  cookies_policy_link_text: "Dowiedz się więcej jak je wyłączyć.",

  //app landing
  downloadAppTitle: "Pobierz aplikację Pupile",
  downloadAppTitleDescription: "Umawiaj wizyty w szybki i wygodny sposób",
  downloadAppBenefitsTitle: "Jakie są korzyści posiadania aplikacji?",
  downloadAppBenefits: {
    benefitOneTitle: "Wiedza o zdrowiu zwięrząt",
    benefitOneDescriptionFirst:
      "Najlepsi lekarze dzielą się wiedzą o pracy lekarza weterynarii. W artykułach o zdrowiu zwierząt znajdziesz użyteczne informacje, które dopełnią Twoją wiedzę o zdrowiu pupila. Dowiedz się jak unikać niebezpieczeństw i jak szybko reagować w nietypowych przypadkach.",
    benefitOneDescriptionSecond:
      "Wszystkie artykuły są pisane przez doświadczonych w swojej branży specjalistów. Stale powiększamy listę partnerskich placówek, aby dostarczyć Ci niezbędne usługi w najbliższej okolicy.",
    benefitOneDescriptionThird:
      "Posiadanie zwierzęcia domowego to styl życia, który nie wiąże się tylko z obowiązkami. To także ogromna radość i przyjemność relacji ze zwierzęciem.",
    benefitTwoTitle: "Historia leczenia",
    benefitTwoDescription:
      "Trzymaj informacje na temat historii leczenia w jednym miejscu, w zasięgu ręki. O każdej porze masz dostęp do karty zdrowia Twojego pupila. Wiesz, jakie badania zostały przeprowadzone i na co należy zwrócić uwagę, aby zwierzę cieszyło się zdrowiem. Dodawaj informacje o samopoczuciu pupila, zapisuj uwagi o jego zdrowiu. Dowiaduj się jeszcze więcej dzięki artykułom, pisanym przez specjalistów.",
    benefitThreeTitle: "Szybka rezerwacja wizyt",
    benefitThreeDescription:
      "Użyj prostego systemu rezerwacji, aby znaleźć przychodnie, gabinety i kliniki weterynaryjne. Zarezerwuj wizytę u behawiorystów, groomerów lub w hotelach dla zwierząt. Wyszukaj miejsca pet friendly, takie jak sklepy, biura czy restauracje.",
  },
  downloadAppBottomTitle: "Pobierz aplikację już teraz!",
  downloadAppBottomDescription: "Umów wizytę dla swojego pupila już teraz",
  downloadAppImagesAlt: {
    logo: "Pupilevet.pl",
  },

  //HTTP ERRORS
  error_400: "Błędny E-mail lub Hasło",
  error_401: "Nieautoryzowany dostęp",
  error_403: "Zabroniony dostęp",
  error_404: "Nie znaleziono 404",
  error_405: "Nie dozwolona metoda",
  error_408: "Koniec czasu oczekiwania na żądanie",
  error_500: "Wewnętrzny błąd serwera",
  error_503:
    "Usługa niedostępna – serwer nie jest w stanie w danej chwili zrealizować zapytania",
};

export { strings };
export default strings;
