import { ratingsApi } from "../api/RatingsApi";
import { errorParser } from "../api/ErrorParser";
import * as types from "./RatingsConstants";

export const addRatingStart = () => ({
  type: types.ADD_RATING,
});

export const addRatingSuccess = (data) => ({
  type: types.ADD_RATING_SUCCESS,
  payload: data,
});

export const errorRatingFail = (msg) => ({
  type: types.ADD_RATING_FAILURE,
  payload: msg,
});

export const reset = () => ({
  type: types.RESET,
});

export const addRating = (data) => (dispatch) => {
  dispatch(addRatingStart());
  ratingsApi
    .addRating(data)
    .then((res) => {
      dispatch(addRatingSuccess(res.data));
    })
    .catch((err) => {
      dispatch(errorRatingFail(errorParser.parseError(err).message));
    });
};
