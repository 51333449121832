import * as actions from "../actions/DoctorsConstants";

const initialState = {
  doctors: {
    loading: false,
    error: "",
    data: [],
  },
  allDoctors: {
    loading: false,
    error: "",
    doctors: [],
  },
};

const doctorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_DOCTORS:
      return {
        ...state,
        doctors: {
          loading: true,
          error: "",
          data: [],
        },
      };

    case actions.FETCH_DOCTORS_SUCCESS:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          loading: false,
          data: action.payload,
        },
      };
    case actions.FETCH_DOCTORS_FAILURE:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          loading: false,
          error: action.payload,
        },
      };

    case actions.ADD_DOCTOR:
    case actions.EDIT_DOCTOR:
    case actions.DELETE_DOCTOR:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          loading: true,
          error: "",
        },
      };

    case actions.ADD_DOCTOR_SUCCESS:
      return {
        ...state,
        doctors: {
          loading: false,
          error: "",
          data: [...state.doctors.data, action.payload],
        },
      };

    case actions.ADD_DOCTOR_FAILURE:
    case actions.EDIT_DOCTOR_FAILURE:
    case actions.DELETE_DOCTOR_FAILURE:
      return {
        ...state,
        doctors: {
          ...state.doctors,
          loading: false,
          error: action.payload,
        },
      };

    case actions.DELETE_DOCTOR_SUCCESS:
      return {
        ...state,
        doctors: {
          loading: false,
          error: "",
          data: state.doctors.data?.filter(
            (doctor) => doctor.id !== action.payload.id
          ),
        },
      };

    case actions.EDIT_DOCTOR_SUCCESS:
      return {
        ...state,
        doctors: {
          loading: false,
          error: "",
          data: [
            ...state.doctors.data?.filter(
              (doctor) => doctor.id !== action.payload.id
            ),
            action.payload,
          ],
        },
      };

    default:
      return state;
  }
};

export default doctorsReducer;
