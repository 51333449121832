import React, { useContext, useState, useEffect } from "react";
import NumberFormat from "react-number-format";

import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../actions/UserActions";

import { validateEmail, validatePassword } from "../../utils/utils";
import { strings } from "../../values/Strings";
import { bool } from "prop-types";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";

import { RegisterModalContext } from "../../contexts/modals";
import usePreventScroll from "../../hooks/preventScroll";
import SocialMediaButtons from "../SocialMediaButtons";

const RegisterModal = ({ isOpen }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const { setShowRegisterModal } = useContext(RegisterModalContext);

  const defaultState = {
    firstName: "",
    lastName: "",
    password: "",
    repeat_password: "",
    phone: "",
    email: "",
    agreement: false,
  };

  const [state, setState] = useState(defaultState);
  const [error, setError] = useState("");

  const formValidate = () => {
    let isError = false;
    setError("");

    if (
      state.firstName.length === 0 ||
      state.lastName.length === 0 ||
      state.password.length === 0 ||
      state.phone.length === 0 ||
      state.email.length === 0
    ) {
      setError(strings.error_fields_empty);
      isError = true;
    }

    if (!validatePassword(state.password)) {
      setError(strings.error_pass_validations);
      isError = true;
    }

    if (!validateEmail(state.email)) {
      setError(strings.error_email_validation);
      isError = true;
    }

    if (!state.agreement) {
      setError(strings.error_agreement);
      isError = true;
    }

    if (state.password !== state.repeat_password) {
      setError(strings.error_pass_not_match);
      isError = true;
    }

    return !isError;
  };

  const onRegisterHandler = (e) => {
    e.preventDefault();

    if (formValidate()) {
      localStorage.removeItem("token");
      dispatch(
        userActions.register(
          state.firstName,
          state.lastName,
          state.email,
          state.phone,
          state.password
        )
      );
    }
  };

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  useEffect(() => {
    auth.errorMessage && setError(strings[auth.errorMessage.id]);
  }, [auth]);

  useEffect(() => {
    auth.registered && setShowRegisterModal(false);
  }, [auth, setShowRegisterModal]);

  usePreventScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      className="modal modal--auth register-modal"
      overlayClassName="modal-overlay modal-overlay--top modal-overlay--on-top"
      ariaHideApp={false}
      onRequestClose={() => setShowRegisterModal(false)}
      closeTimeoutMS={500}
    >
      <div className="modal-Header">
        <h2>Zarejestruj się w serwisie Pupile</h2>
        <Button
          onClick={() => setShowRegisterModal(false)}
          className="transparent padding-0"
        >
          <Icon name="close" color="white" />
        </Button>
      </div>

      <div className="modal-content">
        <p>
          Otrzymaj darmowy dostęp do sprawdzonej bazy klinik weterynaryjnych,
          punktów pielęgnacji oraz miejsc przyjaznych pupilom.
        </p>

        <form className="modal-form" onSubmit={onRegisterHandler}>
          <div className="row">
            <div className="col-24">
              <Input
                onChange={(e) => updateState("firstName", e.target.value)}
                value={state.firstName}
                name="firstName"
                placeholder="Imię"
                label="Imię"
              />
            </div>
            <div className="col-24">
              <Input
                onChange={(e) => updateState("lastName", e.target.value)}
                value={state.lastName}
                name="lastName"
                placeholder="Nazwisko"
                label="Nazwisko"
              />
            </div>

            <div className="col-24">
              <Input
                onChange={(e) => updateState("email", e.target.value)}
                value={state.email}
                type="email"
                name="email"
                placeholder="E-mail"
                label="E-mail"
              />
            </div>
            <div className="col-24">
              <span className="label">Numer telefonu</span>
              <NumberFormat
                placeholder="Numer telefonu"
                format="### ### ###"
                mask="_"
                value={state.phone}
                type="tel"
                onValueChange={(number) => updateState("phone", number.value)}
              />
            </div>
            <div className="col-24">
              <Input
                onChange={(e) => updateState("password", e.target.value)}
                value={state.password}
                name="password"
                type="password"
                placeholder="Hasło"
                label="Hasło"
              />
            </div>
            <div className="col-24">
              <Input
                onChange={(e) => updateState("repeat_password", e.target.value)}
                value={state.repeat_password}
                name="repeat_password"
                type="password"
                placeholder="Powtórz hasło"
                label="Powtórz hasło"
              />
            </div>
            <div className="col-24">
              <div className="checkbox-wrapper">
                <input
                  onChange={() => updateState("agreement", !state.agreement)}
                  checked={state.agreement}
                  id="checkbox"
                  className="checkbox"
                  type="checkbox"
                />
                <label htmlFor="checkbox" className="checkbox-label">
                  Przeczytałem i akceptuję{" "}
                  <Link to="/regulamin" target="_blank">
                    Regulamin serwisu
                  </Link>{" "}
                  i{" "}
                  <Link to="/polityka-prywatnosci" target="_blank">
                    Politykę prywatności
                  </Link>
                  .
                </label>
              </div>
            </div>
            <div className="col-24">
              {error && <span className="modal-form__error">{error}</span>}
            </div>
            <div className="col-24">
              <Button loading={auth.loading} htmlType="submit">
                Zarejestruj
              </Button>
            </div>
          </div>
        </form>
        <span>lub</span>
        <SocialMediaButtons />
      </div>
    </Modal>
  );
};

export default RegisterModal;

RegisterModal.propTypes = {
  isOpen: bool,
};
