import React, { useContext } from "react";
import Icon from "../Icon";
import { NavLink } from "react-router-dom";
import Button from "../Button";
import { Link } from "react-router-dom";
import { LoginModalContext, RegisterModalContext } from "../../contexts/modals";
import { bool } from "prop-types";
import HeaderLayout from "../../layouts/header";

const Header = ({ loggedIn, displayProducts }) => {
  const { setShowLoginModal } = useContext(LoginModalContext);
  const { setShowRegisterModal } = useContext(RegisterModalContext);

  return (
    <HeaderLayout className="header">
      <div className="col-8 header--logo">
        <Link to="/">
          <Icon name="logo_2" />
        </Link>
      </div>
      <nav className="col-16 header--content">
        <NavLink to="/placowki">Szukaj placówki</NavLink>
        <NavLink to="/artykuly">Dobrze wiedzieć</NavLink>
        {displayProducts && <NavLink to="/produkty">Produkty</NavLink>}
        <NavLink to="/kontakt">Kontakt</NavLink>

        {loggedIn ? (
          <NavLink to="/panel-uzytkownika">
            <Icon name="user_icon" color="orange" />
            Panel Użytkownika
          </NavLink>
        ) : (
          <>
            <Button
              className="button--bordered"
              onClick={() => setShowLoginModal(true)}
            >
              Logowanie
            </Button>
            <Button onClick={() => setShowRegisterModal(true)}>
              Rejestracja
            </Button>
          </>
        )}
      </nav>
    </HeaderLayout>
  );
};

export default Header;

Header.propTypes = {
  loggedIn: bool,
  displayProducts: bool,
};
