import * as actions from "../actions/PartnersConstants";

const initialState = {
  data: [],
  loading: false,
  errorMessage: "",
};

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PARTNERS:
      return {
        ...state,
        loading: true,
      };
    case actions.FETCH_PARTNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case actions.FETCH_PARTNERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default partnersReducer;
