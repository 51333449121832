import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions/ProductsActions";
import Loader from "react-loader-spinner";
import Button from "../../components/Button";

import { formatPrice, formatPromotion, getUrlToImage } from "../../utils/utils";

const ProductPage = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);

  const history = useHistory();
  const { id } = useParams();

  const data = products?.byId[id];

  useEffect(() => {
    if (id && !data?.id && !data?.loading) {
      dispatch(actions.getProductById(id));
    }
  }, [data, products, id, dispatch]);

  if (data?.loading) {
    return (
      <div className="loader-wrapper">
        <Loader type="Oval" color="#f66829" height={100} width={100} />
      </div>
    );
  }

  return (
    <div className="simple-page">
      <div className="container">
        <div className="row">
          <div className="col-24">
            <Button
              icon="arrow_down"
              iconColor="orange-light"
              className="transparent"
              onClick={() => history.goBack()}
            >
              Wróć
            </Button>
          </div>
          <div className="col-12">
            <div className="simple-page__left">
              <h2>{data?.name}</h2>
              <h4 className="simple-page__left__price">
                {data?.price && formatPrice(data?.price)} zł{" "}
                {data?.promotion && (
                  <span>{formatPromotion(data.promotion)}</span>
                )}
              </h4>
              {data?.desc && (
                <article>
                  <header>Szczegóły produktu</header>
                  <p>{data.desc}</p>
                  {data?.url && (
                    <Button
                      onClick={() => window.open(data.url, "_blank")}
                      className="button thicker"
                    >
                      Przejdź do produktu
                    </Button>
                  )}
                </article>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="simple-page__right">
              <picture>
                <img
                  src={getUrlToImage(data?.cover?.url)}
                  alt={data?.name || "produkt"}
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;
