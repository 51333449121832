import API from "./ApiConfig";
import moment from "moment";

export const visitsApi = {
  getVisits,
  getVisit,
  cancelVisit,
  reserveVisit,
  getDoctorVisits,
  updateVisitComment,
  getPetVisits,
  updateVisit,
  getVisitsTimeSlots,
  getReceptionistVisits,
};

function getVisits() {
  return API.get("visits/me");
}

function getReceptionistVisits(placeId) {
  return API.get(`places/${placeId}/visits`);
}

function getVisit(id) {
  return API.get(`visits/${id}`);
}

function getPetVisits(pet) {
  return API.get("visits", { params: { pet } });
}

function reserveVisit(data) {
  return API.post("/visits", data);
}

function cancelVisit(id) {
  return API.delete(`visits/${id}`);
}

function getDoctorVisits(userId) {
  return API.get(`/employees/${userId}/visits`);
}

function updateVisitComment(visitID, comment) {
  return API.put(`/visits/${visitID}`, { comment });
}

function updateVisit(id, data) {
  return API.put(`/visits/${id}`, data);
}

function getVisitsTimeSlots(
  doctorId,
  placeId,
  date,
  minutes = 15,
  service = 1
) {
  const isToday = !moment(date).isAfter(moment(), "days");
  const startRaw = isToday ? moment(date) : moment(date).startOf("day");
  const remainder = minutes - (startRaw.minutes() % minutes);

  const from = startRaw
    .startOf("minute")
    .add(remainder, "minutes")
    .add(1, "hours")
    .toISOString();

  const to = moment(from)
    .startOf("day")
    .add(1, "day")
    .add(1, "hours")
    .toISOString();

  return API.get(`employees/${doctorId}/timeslots/${placeId}`, {
    params: { from, to, minutes, service },
  });
}
