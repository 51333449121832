import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { Link } from "react-router-dom";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import { shape, bool, string, number, func } from "prop-types";
import {
  getEmployeeByPlaceId,
  removeEmployee,
} from "../../actions/EmployeeActions";
import strings from "../../values/Strings";
import { getEmployeeStatus } from "../../store/Selectors";

const EmployeeBox = ({ data, onEdit, placeName }) => {
  const dispatch = useDispatch();
  const employeeStatus = useSelector(getEmployeeStatus);
  const { title, user, licence } = data;

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const fullName = useMemo(() => {
    return `${title ? `${title} ` : ""}${user?.firstName || "-"} ${
      user?.lastName || "-"
    }`;
  }, [user, title]);

  const handleDeleteEmployee = () => {
    dispatch(removeEmployee(data.id));
    setTimeout(() => {
      dispatch(getEmployeeByPlaceId(data?.places[0]?.id));
    }, 300);
    setShowConfirmModal(false);
  };

  return (
    <div className="employee-box">
      <ConfirmModal
        loading={employeeStatus?.loading}
        hideModal={() => setShowConfirmModal(false)}
        onSubmit={() => handleDeleteEmployee()}
        question={strings.confirm_delete_vet}
        isOpen={showConfirmModal}
      />
      <div className="row">
        <div className="col-16">
          <div className="employee-box__content">
            <div className="employee-box__head">
              <h4>{fullName}</h4>
            </div>
            <ul>
              <li>
                <Icon name="licence" color="orange" />
                <p>{licence || "-"}</p>
              </li>
              <li>
                <Icon name="phone" color="orange" />
                <p>{user?.phone || "-"}</p>
              </li>
              <li>
                <Icon name="envelope" color="orange" />
                <p>{user?.email || "-"}</p>
              </li>
              <li>
                <Icon name="person" color="orange" />
                <p>
                  {user?.firstName || "-"} {user?.lastName}
                </p>
              </li>
              <li>
                <Icon name="marker" color="orange" />
                <p>{placeName || "-"}</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-8">
          <div className="employee-box__actions">
            <Button onClick={() => onEdit(data)} className="transparent">
              <Icon name="edit" color="orange" />
              {strings.edit}
            </Button>
            <Button
              onClick={() => setShowConfirmModal(true)}
              className="transparent"
            >
              <Icon name="delete" color="orange" />
              {strings.remove}
            </Button>
            <hr />
            <Link
              className="button transparent"
              to={`/panel-weterynarza/wizyty/${data?.id}`}
            >
              <Icon name="calendar" color="orange" /> {strings.panel_visits}
            </Link>

            <Link
              className="button transparent"
              to={`/panel-weterynarza/planowanie/${data?.id}`}
            >
              <Icon name="clipboard" color="orange" /> {strings.panel_plan}
            </Link>

            <Button onClick={() => onEdit(data, true)} className="transparent">
              <Icon name="clock" color="orange" />
              {strings.working_hours_label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeBox;
EmployeeBox.propTypes = {
  data: shape({
    created_at: string.isRequired,
    displayPhone: string,
    hidden: bool,
    id: number.isRequired,
    place: shape({}),
    title: string,
    updated_at: string.isRequired,
    user: shape({}),
    workingHours: shape([]),
  }).isRequired,
  onEdit: func,
  placeName: string,
};
