import React from "react";
import { shape } from "prop-types";
import { getUrlToImage } from "../../utils/utils";

const PartnersItem = ({ partner }) => {
  const img = partner?.logo?.url ? partner.logo.url : null;

  return (
    <a
      href={partner?.url}
      className={`partner ${!partner?.url ? "partner--disabled" : ""}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="partner__image">
        <img className="image" src={getUrlToImage(img)} alt="" />
      </div>
      {partner?.name && <div className="partner__name">{partner.name}</div>}
    </a>
  );
};

export default PartnersItem;

PartnersItem.propTypes = {
  partner: shape({}),
};
